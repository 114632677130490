import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { API_CONFIG } from '../api.config';
import { CommonService } from '../shared/services/common.service';
import { UtilService } from '../shared/services/util.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(private service: AdalService, private UtilService: UtilService, private commonService: CommonService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.search('/sprints/upload/project') === -1 && req.url.indexOf('project-forecast') === -1) {
      this.commonService.showLoader();
    }
    let dupReq: any;
    if (this.service.userInfo.token) {
      dupReq = req.clone({
        url: `${API_CONFIG.baseUrl}${req.url}`,
        headers: req.headers.set('Authorization', 'Bearer ' + this.service.userInfo.token),
      });
    } else {
      dupReq = req.clone({
        url: `${API_CONFIG.baseUrl}${req.url}`,
      });
    }
    // send the newly created request
    return next.handle(dupReq).pipe(
      map((response) => {
        if (response instanceof HttpResponse) {
          this.commonService.hideLoader();
        }
        return response;
      }),
      catchError(this.UtilService.handleError) as any
    );
  }
}
