import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaderResponse, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { UserAccessService } from './user-access.service';

@Injectable()
export class UtilService {

  constructor(private userAccessService: UserAccessService) {
  }

  public extractData(res) {
    const body = res;
    return body || {};
  }

  public handleError(error: any) {
    let errMsg: string;
    if (error.status === 500) {
      // if (error.error.message.length < 60) {
      //   errMsg = error.error ? error.error : error.toString();
      // } else {
      //   const shortMessage = error.error.message.substring(0, 100);
      //   error = {
      //     message: shortMessage,
      //   };
      //   errMsg = error;
      // }

      errMsg = error.error ? error.error : error.toString();
    } else if (
      error.status === 400 ||
      error.status === 409 ||
      error.status === 403 ||
      error.status === 406 ||
      error.status === 401 ||
      error.status === 404
    ) {
      errMsg = error.error ? error.error : error.toString();
    } else {
      error = {
        message: 'Server Down',
      };
      errMsg = error;
    }

    return throwError(errMsg);
  }

  // Timestamp Utilities
  public getTimeStampForDate(val) {
    let ts: any = '';
    if (val) {
      try {
        ts = new Date(val).getTime();
      } catch (Ex) {
        console.log('Date conversion failed!');
      }
    }
    return ts;
  }

  public getDateForTimeStamp(val) {
    let dt: any = '';
    if (val) {
      try {
        dt = new Date(val);
        dt = [this.padDate(dt.getMonth() + 1), this.padDate(dt.getDate()), dt.getFullYear()].join('/');
      } catch (Ex) {
        console.log('Date conversion failed!');
      }
    }
    return dt;
  }

  public padDate(n) {
    return n < 10 ? '0' + n : n;
  }

  chunkArray(array: any[], chunkSize: number): any[] {
    const chunks: any[] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  getUserMetaDataForProjects() {
    //const url = API_CONFIG.usersMetadata.replace('UNIQUE_NAME', `${emailId}`);
    return this.userAccessService.getUserMetaDataAfterProjectUpdate();
  }

}
