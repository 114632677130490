import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgileMaturityGuard } from './auth-guards/agile-maturity.guard';
import { AppGuard } from './auth-guards/app.guard';
import { BvaReportGuard } from './auth-guards/bva-report.guard';
import { GovernanceProcessGuard } from './auth-guards/governance-process.guard';
import { IterationGuard } from './auth-guards/iteration.guard';
import { ItsmGuard } from './auth-guards/itsm.guard';
import { ProjectGuard } from './auth-guards/project.guard';
import { SettingsGuard } from './auth-guards/settings.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('../app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'additeration',
        loadChildren: () => import('../app/modules/additeration/additeration.module').then((m) => m.AddIterationModule),
        canActivate: [IterationGuard],
      },
      {
        path: 'edititeration',
        loadChildren: () => import('../app/modules/additeration/additeration.module').then((m) => m.AddIterationModule),
        canActivate: [IterationGuard],
      },
      {
        path: 'addproject',
        loadChildren: () => import('../app/modules/addproject/addproject.module').then((m) => m.AddProjectModule),
        canActivate: [ProjectGuard],
      },
      {
        path: 'editproject',
        loadChildren: () => import('../app/modules/addproject/addproject.module').then((m) => m.AddProjectModule),
        canActivate: [ProjectGuard],
      },
      {
        path: 'addincident',
        loadChildren: () => import('../app/modules/addincident/addincident.module').then((m) => m.AddincidentModule),
        canActivate: [ItsmGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('../app/modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [SettingsGuard],
      },
      {
        path: 'governanceProcess',
        loadChildren: () => import('../app/modules/process-governance/process-governance.module').then((m) => m.ProcessGovernanceModule),
        canActivate: [GovernanceProcessGuard],
      },
      {
        path: 'agileMaturity',
        loadChildren: () => import('../app/modules/agile-maturity/agile-maturity.module').then((m) => m.AgileMaturityModule),
        canActivate: [AgileMaturityGuard],
      },
      { path: '**', redirectTo: '/home', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RouteModule {}
