import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG } from '../../../../api.config';
import { BvaProjectForecast } from '../modal/bvaProjectForecast';
import { Url } from 'url';

@Injectable()
export class BvaDataService {
  constructor(public http: HttpClient) { }
  addExtension(data) {
    const url = API_CONFIG.addExtension;
    return this.http.post(url, data)
  }

  getProjectInfo(id) {
    const url = API_CONFIG.projectData.replace('PROJECT_ID', id);
    return this.http.get(url);
  }
  getAccountList(emailId) {
    const url = API_CONFIG.bvaUseMetaData.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.get(url);
  }
  shareBvA(data) {
    const url = API_CONFIG.shareBvA;
    return this.http.post(url, data);
  }

  getPortfolioReport(emailId) {

    const url = API_CONFIG.portfolioReport.replace('UNIQUE_NAME', `${emailId}`);

    // return this.http.post(url, data);
    return this.http.get(url);
  }
  getPortfolioDashbrdReport(emailId) {
    const url = API_CONFIG.portfolioDashbrdReport.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.get(url);
  }
  getProjectReport(projectId) {
    const url = API_CONFIG.projectBvaReport.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getTrendsData(projectId) {
    const url = API_CONFIG.getTrendsData.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getActualBvAData(projectId) {
    const url = API_CONFIG.getActualBvAData.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  uploadData(file, createdBy) {
    const url = API_CONFIG.bvaUpload;
    const formdata: FormData = new FormData();
    if (file !== null) {
      formdata.append('timesheetData', file);
    } else {
      formdata.append('p1p2Data', new File([], ''));
    }
    formdata.append('createdBy', createdBy);

    return this.http.post(url, formdata);
  }
  uploadProjectPlan(projectId: any, projectPlanFile, createdBy, createdDate, modifiedBy, modifiedDate, isInitialUnplannedEffortsIgnore) {
    const url = API_CONFIG.bvaProjectPlanUpload.replace('PROJECT_ID', `${projectId}`);
    const formdata: FormData = new FormData();

    if (projectPlanFile !== null) {
      formdata.append('timesheetData', projectPlanFile);
    } else {
      formdata.append('timesheetData', new File([], ''));
    }
    formdata.append('createdBy', createdBy);
    formdata.append('createdDate', createdDate);
    formdata.append('modifiedBy', modifiedBy);
    formdata.append('modifiedDate', modifiedDate);
    formdata.append('isInitialUnplannedEffortsIgnore', isInitialUnplannedEffortsIgnore);
    return this.http.post(url, formdata);
  }
  manageBvANote(data, budgetedData) {
    const url = API_CONFIG.manageBvANote
      .replace('INTTACTID', `${budgetedData.intactId}`)
      .replace('BUDGETEDEFFORTS', `${budgetedData.budgetedHours}`)
      .replace('ACTUALEFFORTS', `${budgetedData.actualHours}`);
    data.projectName = budgetedData.projectName;
    return this.http.post(url, data);
  }
  getBvANotes(projectId) {
    const url = API_CONFIG.getBvANotes.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getDefultColumnList(emailId) {
    const url = API_CONFIG.getDefultColumnList.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.get(url);
  }
  savePrefferedColumns(data, emailId) {
    const url = API_CONFIG.savePrfferedColumnList.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.post(url, data);
  }

  saveBvaProjectForecast(forecastData: BvaProjectForecast) {
    return this.http.post(API_CONFIG.bvaForecast, forecastData);
  }

  generateForecastPayload(budgetedActualBvAData, pjtId, intactId, editedEndDate?, employeeId?, employeeLocation?): BvaProjectForecast {
    const projectForecast: BvaProjectForecast = {
      projectId: pjtId,
      intactId: intactId,
      employeesForcastHours: [],
      updatedBy: localStorage.getItem('userMetaDataRoleEmailId'),
      updatedDate: new Date()
    };

    projectForecast.employeesForcastHours = budgetedActualBvAData.map((employee) => {
      return {
        employeeId: employee.employeeId,
        employeeName: employee.employeeName,
        projectRole: employee.projectRole,
        location: employee.location,
        utilization: employee.utilization,
        total: employee.total,
        estimateHours: null,
        actualHours: null,
        forecastHours: employee.actualHours
          .filter((hours) => hours.isFutureWeek)
          .map((hours) => {
            return {
              sprintName: hours.sprintName,
              phase: hours.phase,
              startDate: hours.startDate,
              endDate: hours.endDate,
              hours: hours.hours,
              hoursDisplay: hours.hoursDisplay,
              color: hours.color,
              isFutureWeek: hours.isFutureWeek,
              isEdited: (employee.employeeName == employeeId) && (hours.endDate == editedEndDate) && (employee.location == employeeLocation) ? true : false
            };
          }),
      };
    });
    return projectForecast;
  }

  validateMidOfWeekSprint(sprintEndDate: any) {
    sprintEndDate = new Date(sprintEndDate);
    let day = sprintEndDate.getDay();
    return day > 1;
  }

  validateLessThanOrEqualCurrentWeek(sprintEndDate: any) {
    sprintEndDate = new Date(sprintEndDate).getTime();
    let now: any = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();
    return sprintEndDate <= now;
  }

  calculateBudgetHrsWeekWise(budgetedActualBvAData) {
    let budgtdEmpData = [];
    budgetedActualBvAData?.budgetedEmployeeDatas?.forEach((employee) => {
      const budgetedHours = [], budgetedEmp = [];
      if (!employee.actualHours) {
        employee?.estimateHours?.forEach((estimateHours, i) => {
          let tempData = [];
          tempData['sprintName'] = estimateHours.sprintName;
          tempData['isFutureWeek'] = estimateHours.isFutureWeek;
          tempData['hours'] = Number(estimateHours.hours);
          tempData['hoursDisplay'] = String(estimateHours.hours);
          tempData['startDate'] = estimateHours.startDate;
          tempData['endDate'] = estimateHours.endDate;

          budgetedHours.push(tempData);
        });
      }
      else {
        employee?.actualHours?.forEach((actualHours, i) => {
          const budgetedSprint = employee.estimateHours.find((sprint) => sprint.sprintName === actualHours.sprintName);
          const futureWeekCount = employee.actualHours.filter((_week) => _week.sprintName === actualHours.sprintName).length;
          const availableForecastHours = budgetedSprint?.hours && futureWeekCount ? budgetedSprint.hours / futureWeekCount : 0;
          let tempData = [];
          tempData['sprintName'] = actualHours.sprintName;
          tempData['isFutureWeek'] = actualHours.isFutureWeek;
          tempData['hours'] = Number(availableForecastHours);
          tempData['hoursDisplay'] = String(availableForecastHours);
          tempData['startDate'] = actualHours.startDate;
          tempData['endDate'] = actualHours.endDate;

          budgetedHours.push(tempData);
        });
      }

      budgetedEmp['employeeId'] = employee.employeeId;
      budgetedEmp['employeeName'] = employee.employeeName;
      budgetedEmp['rateCard'] = employee.rateCard;
      budgetedEmp['budgetedHours'] = budgetedHours;
      budgtdEmpData.push(budgetedEmp);
    });
    return budgtdEmpData;
  }

  calculateBudgetedHoursPerWeek(budgetedActualBvAData) {
    let budgtdEmpData = [];
    let currentWeekData = [];
    let isMidOfWeek = false;
    //currentSprint = budgetedActualBvAData?.currentSprint

    isMidOfWeek = budgetedActualBvAData?.bvaActualEffortByWeeks?.some((effortsByWeek) => {
      return this.validateMidOfWeekSprint(effortsByWeek.weekBegin);
    });

    if (isMidOfWeek) {
      budgetedActualBvAData?.bvaActualEffortByWeeks?.forEach((effortsByWeek) => {
        if (this.validateLessThanOrEqualCurrentWeek(effortsByWeek.weekEnd) && effortsByWeek.weekHoursActual > 0) {
          currentWeekData['sprintName'] = effortsByWeek.sprintName;
          currentWeekData['weekBegin'] = effortsByWeek.weekBegin;
          currentWeekData['weekEnd'] = effortsByWeek.weekEnd;
        }
      });
    }
    else {
      budgetedActualBvAData?.bvaActualEffortByWeeks?.forEach((effortsByWeek) => {
        if (this.validateLessThanOrEqualCurrentWeek(effortsByWeek.weekEnd)) {
          currentWeekData['sprintName'] = effortsByWeek.sprintName;
          currentWeekData['weekBegin'] = effortsByWeek.weekBegin;
          currentWeekData['weekEnd'] = effortsByWeek.weekEnd;
        }
      });
    }

    budgtdEmpData = this.calculateBudgetHrsWeekWise(budgetedActualBvAData);
    // let budgtdHrsToDate = budgtdEmpData?.reduce((acc, week) => acc + week.budgetedHours || 0, 0),
    return this.calculateBudgetHrsSummationValue(budgetedActualBvAData, currentWeekData, budgtdEmpData);
  }

  calculateBudgetHrsSummationValue(budgetedActualBvAData, currentWeekData, budgtdEmpData) {
    let budgetedhoursToDate = [], budgtdHrsToDateVal, budgetedHoursAfterToDate;
    if (currentWeekData && Object.keys(currentWeekData).length != 0) {
      const budgetedhours = budgtdEmpData
        ?.map((employee) => employee.budgetedHours);
      let index = 0;
      budgetedhours[0]?.map((week, i) => {
        if (week.sprintName == currentWeekData['sprintName']
          && week.startDate == currentWeekData['weekBegin']
          && week.endDate == currentWeekData['weekEnd']) {
          index = i;
        }
      });

      budgetedhoursToDate = budgetedhours?.map((week, i) => {
        return week?.slice(0, index + 1);
      }).flat();
    }

    const budgetedhoursAfterDate = budgtdEmpData
      ?.map((employee) => employee.budgetedHours)
      .flat()
      .filter((budgetedHours) => budgetedHours.isFutureWeek);

    let budHrsToDtTotal = budgetedhoursToDate?.reduce((acc, week) => acc + week.hours || 0, 0);
    budgtdHrsToDateVal = Number(budHrsToDtTotal % 1 != 0 ? budHrsToDtTotal.toFixed(2) : budHrsToDtTotal);

    let afterToDtHours = budgetedhoursAfterDate?.reduce((acc, week) => acc + week.hours || 0, 0);
    budgetedHoursAfterToDate = Number(afterToDtHours % 1 != 0 ? afterToDtHours.toFixed(2) : afterToDtHours);

    budgetedActualBvAData.budgetedHoursToDate = budgtdHrsToDateVal;
    budgetedActualBvAData.budgetedHoursAfterToDate = budgetedHoursAfterToDate;
    return budgetedActualBvAData;
  }

  isProjectInBudgetRisk(project) {
    return project.unplannedResources > 0 || Number(project.unplannedEfforts) > 0 || (project.projectedGM && ((Number(project.targetGM) - Number(project.projectedGM)) >= 5))
  }

  getLastTuesdayofWeek() {
    let dateToday = new Date();
    let lastTuesday = new Date(dateToday.setDate((dateToday.getDate() - dateToday.getDay()) + 2));
    return lastTuesday.toLocaleDateString('en-US');
  }

  isProjectInScheduleRisk(project) {
    if (project.estimatedEndDate) { // end Date is compared with last week tuesday as on every tuesday timesheets get uploaded.
      return new Date(project.estimatedEndDate) < new Date(this.getLastTuesdayofWeek())
    }
  }
}