import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG } from '../../../api.config';
import { UserAccessService } from '../../../shared/services/user-access.service';

@Injectable()
export class SettingsService {
  constructor(public http: HttpClient, private userAccessService: UserAccessService) { }
  addAccount(acctreqst) {
    const url = API_CONFIG.addAccount;
    return this.http.post(url, acctreqst);
  }
  getNotifications() {
    const url = API_CONFIG.getNotifications;
    return this.http.get(url);
  }
  updateNotifications(list) {
    const url = API_CONFIG.updateNotification;
    return this.http.post(url, list);
  }
  deleteAccount(acctreqst) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    };
    const url = API_CONFIG.deleteAccount;
    return this.http.put(url, acctreqst);
  }
  updateAccount(acctreqst) {
    const url = API_CONFIG.updateAccount;
    return this.http.put(url, acctreqst);
  }
  getAccount() {
    const url = API_CONFIG.getAccount;
    return this.http.get(url);
  }
  updateProgram(prgRqst) {
    const url = API_CONFIG.updateProgram;
    return this.http.put(url, prgRqst);
  }
  getProgram() {
    const url = API_CONFIG.getProgram;
    return this.http.get(url);
  }
  deleteProgram(prgRqst) {
    const url = API_CONFIG.deleteProgram;
    return this.http.put(url, prgRqst);
  }
  addProgram(prgRqst) {
    const url = API_CONFIG.addProgram;
    return this.http.post(url, prgRqst);
  }
  deleteProject(prjId) {
    const prjData = { projectId: prjId, action: 'delete' };
    const url = API_CONFIG.deleteProject;
    return this.http.put(url, prjData);
  }
  addCapability(prgRqst) {
    const url = API_CONFIG.addProgram;
    return this.http.post(url, prgRqst);
  }
  updateCpability(prgRqst) {
    const url = API_CONFIG.updateProgram;
    return this.http.put(url, prgRqst);
  }
  addRole(acctreqst) {
    const url = API_CONFIG.addRole;
    return this.http.post(url, acctreqst);
  }
  getRoles() {
    const url = API_CONFIG.getRoles;
    return this.http.get(url);
  }
  updateRoles(prgRqst) {
    const url = API_CONFIG.updateRoles;
    return this.http.post(url, prgRqst);
  }
  deleteRole(prgRqst, roleId) {
    const url = API_CONFIG.deleteRole.replace('ROLE_ID', `${roleId}`);
    return this.http.post(url, prgRqst);
  }
  addUser(prgRqst) {
    const url = API_CONFIG.addUser;
    return this.http.post(url, prgRqst);
  }
  getUser() {
    const url = API_CONFIG.getUser;
    return this.http.get(url);
  }
  getAccess(rqstinfo) {
    const url = API_CONFIG.getAccess.replace('ROLE_ID', `${rqstinfo}`);
    return this.http.get(url, rqstinfo);
  }
  editAccess(rqstinfo, emailId) {
    let url = API_CONFIG.editAccess.replace('ROLE_ID', `${rqstinfo}`);
    url = url.replace('EMAIL_ID', `${emailId}`);
    return this.http.get(url, rqstinfo);
  }
  updateUser(rqstinfo) {
    const url = API_CONFIG.updateUser;
    return this.http.post(url, rqstinfo);
  }
  updateSwitchUserAccess(rqstinfo) {
    const url = API_CONFIG.switchUser;
    return this.http.post(url, rqstinfo);
  }
  updateAccess(rqstinfo) {
    const url = API_CONFIG.updateUser;
    return this.http.post(url, rqstinfo);
  }
  getUserMetaData() {
    return this.userAccessService.getUserMetaData();
  }
  getLatestReport() {
    const url = API_CONFIG.getLatestReport;
    return this.http.get(url);
  }
  getDexInfo() {
    const url = API_CONFIG.getDexInfo;
    return this.http.get(url);
  }
  deleteDexMetadata(data, fid, cid) {
    const url = API_CONFIG.deleteDexMetadata.replace('FOCUS_ID', fid).replace('CONTROL_ID', cid);
    return this.http.post(url, data);
  }
  updateDexMetadata(data) {
    const url = API_CONFIG.updateDexMetadata;
    return this.http.post(url, data);
  }
  getProjects(condition) {
    const url = API_CONFIG.getProjectList.replace('CONDITION', condition);
    return this.http.get(url);
  }
  getProjecData(projectId: any) {
    const url = API_CONFIG.projectData.replace('PROJECT_ID', projectId);
    return this.http.get(url);
  }
  projectUpdate(data) {
    return this.http.put(`${API_CONFIG.addProject}${'/'}`, data);
  }
  changeProjectName(projectId, name, isNotify) {
    const url = API_CONFIG.updateProjectName.replace('PROJECT_ID', projectId).replace('PROJECT_NAME', name).replace('IS_NOTIFY', isNotify);
    return this.http.post(url, null);
  }

  projectStatusChangeToClose(data) {
    const url = API_CONFIG.projectStatusChange;
    return this.http.post(url, data)
  }

  UserchangeStatus(data) {
    const url = API_CONFIG.userStatusChange;
    return this.http.post(url, data)
  }

  getAccessFilterData(data) {
    const url = API_CONFIG.getAccessFilterData;
    return this.http.post(url, data)
  }

  getRegionCountriesList() {
    const url = API_CONFIG.getRegionCountryList;
    return this.http.get(url)
  }
}
