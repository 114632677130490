import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserMetaData } from '../shared/models/userMetaData.model';
import { UserAccessService } from '../shared/services/user-access.service';

@Injectable({
  providedIn: 'root',
})
export class ItsmGuard implements CanActivate {
  constructor(private userAccessService: UserAccessService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      this.userAccessService.getUserMetaData().subscribe(
        (userMetaData: UserMetaData) => {
          resolve(userMetaData.loadITSM);
          userMetaData.loadITSM ? null : this.router.navigateByUrl('/home');
        },
        (err) => {}
      );
    });
  }
}
