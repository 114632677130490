import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { iif, Observable, of, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_CONFIG } from '../../api.config';
import { UserMetaData } from '../models/userMetaData.model';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  private userMetaData: UserMetaData;
  constructor(private http: HttpClient, private adalService: AdalService, private zone: NgZone) { }

  signUpUser(data): Observable<any> {
    return this.http.post(API_CONFIG.signup, data);
  }

  getLastLoginDate(data): Observable<any> {
    return this.http.post(API_CONFIG.updateLastLoginDate, data)
  }

  getUserMetaDataAfterProjectUpdate(): Observable<UserMetaData> {
    const url = API_CONFIG.usersMetadata.replace('UNIQUE_NAME', `${this.adalService.userInfo.profile.upn}`);
    return this.http.get(url).pipe(
      tap((userMetaData: UserMetaData) => {
        this.userMetaData = userMetaData;
        Object.freeze(this.userMetaData);
      })
    );
  }

  getUserMetaData(): Observable<UserMetaData> {
    const url = API_CONFIG.usersMetadata.replace('UNIQUE_NAME', `${this.adalService.userInfo.profile.upn}`);
    return iif(
      () => !!this.userMetaData,
      of(this.userMetaData),
      this.http.get(url).pipe(
        tap((userMetaData: UserMetaData) => {
          this.userMetaData = userMetaData;
          //let upn = this.adalService.userInfo.profile.upn;
          //localStorage.setItem('userEmailId',JSON.stringify(upn));
          Object.freeze(this.userMetaData);
        })
      )
    );
  }

  logOut(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    };
    const url = API_CONFIG.logOut.replace('UNIQUE_NAME', `${data.emailId}`);
    return this.http.post(url, data, httpOptions);
  }

  setTokenRenewalTimer = () => {
    const adalInstance: any = window._adalInstance;
    const now = Math.round(new Date().getTime() / 1000.0);
    const exp = sessionStorage['adal.expiration.key' + window._adalInstance.config.loginResource];
    var timerDelay = exp - now - 300 > 0 ? exp - now - 300 : 1;
    timerDelay = timerDelay != 1 ? timerDelay * 12 : 1;
    // exp - now - 300 > 0 ? exp - now - 300 : 1;
    if (!adalInstance?._renewToken) {
      return;
    }
    console.info('Expiry in', timerDelay / 60, 'min');
    if (exp) {
      const _this = this;
      this.zone.runOutsideAngular(function () {
        timer(timerDelay * 1000)
          .toPromise()
          .then(() => {
            console.info('refreshing token');
            adalInstance.clearCacheForResource(adalInstance.config.loginResource);
            adalInstance._renewToken(adalInstance.config.loginResource, (err, token) => {
              if (err) {
                return;
              }
              _this.setTokenRenewalTimer();
            });
          });
      });
    } else {
      window.location.reload();
    }
  };

  toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  setCustomisedName(email: string) {
    if ((email != null || email != '') && email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
      let name: string = email.split('@')[0];
      name = name.replace('.', ' ');
      name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      const familyName = name.split(' ').splice(-1);
      const givenName = name.substring(0, name.indexOf(String(familyName)));
      this.adalService.userInfo.profile.name = givenName != null ? givenName + String(familyName).charAt(0) : String(familyName);
    }
  }

}
