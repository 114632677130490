<div class="help-container">
  <div class="titlesection">
    <span class="proj-title">
      <span>Help</span>
    </span>
  </div>

  <div class="content">
    <div class="navbar">
      <!-- <ul class="nav navbar-nav">
        <li *ngFor="let header of navigationHeaders" [ngClass]="{active: header.isActive}">
          <a (click)="onHeaderClick(header)">{{header.title}}</a>
        </li>
      </ul> -->
      <form class="navbar-form navbar-right">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="searchTerm"
            (input)="search(searchTerm, $event)"
            placeholder="Search"
            name="search"
            autocomplete="off"
          />
          <div class="input-group-btn">
            <button class="btn btn-default" type="submit" (click)="search(searchTerm)">
              <i class="glyphicon glyphicon-search"></i>
            </button>
          </div>
        </div>
        <div class="search-list" *ngIf="showSearchList">
          <ul>
            <li *ngFor="let item of searchList | filter: searchTerm" (click)="search(item)">{{ item }}</li>
          </ul>
        </div>
      </form>
    </div>
    <!-- Dashboard View start -->
    <div class="row dashboard-content" *ngIf="navigationHeaders[0].isActive">
      <div class="col col-sm-3">
        <div class="side-navigation">
          <ul *ngFor="let section of dashboardSections">
            <li (click)="onDashboardViewChange(section)" [ngClass]="{ active: section.isActive }">
              {{ section.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col col-sm-9">
        <div class="main-content">
          <ng-container *ngIf="!showSearchResult">
            <div class="section" *ngFor="let widget of widgets">
              <div>
                {{ widget.title }}
              </div>
              <div [innerHTML]="widget.content"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="showSearchResult">
            <div class="search-info section">
              <span
                >Showing {{ searchResult.length }} results for "<span id="search-text">{{ searchResultTerm }}</span
                >"</span
              >
              <button class="btn" (click)="exitSearch()">
                <img src="../../../assets/images/cross-blue.png" alt="cross Logo" />
                Exit Search
              </button>
            </div>
            <div class="section" *ngFor="let widget of searchResult">
              <div>
                {{ widget.title }}
              </div>
              <div [innerHTML]="widget.content"></div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- Dashboard View End -->
    </div>
  </div>
</div>
