import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResetState } from '../models/reset.interface';
@Injectable()
export class ResetService {
  private resetSubject = new Subject<ResetState>();
  resetState = this.resetSubject.asObservable();
  constructor() {}
  resetCustomDetailGrid() {
    this.resetSubject.next({ resetCustomDetailGrid: true } as ResetState);
  }
}
