import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../services/common.service';
@Component({
  selector: 'alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
})
export class AlertPopupComponent implements OnInit {
  @Input() alertType;
  @Input() alertTitle;
  @Input() alertAddInfo;
  @Input() alertSubTitle;
  @Input() alertErrorContent;
  @Input() alertFlag;
  @Input() userRoleName;
  @Input() isSuperAdmin;
  @Input() refreshWsr;
  @Input() changeTryagain;
  @Input() changeCancelBtn;
  @Input() closeIconStatus;
  @Output() closeSuccess = new EventEmitter();
  @Output() closeError = new EventEmitter();
  @Output() cancelUpload = new EventEmitter();
  @Output() continueUpload = new EventEmitter();
  @Output() cancelDelete = new EventEmitter();
  @Output() continueDelete = new EventEmitter();
  @Output() continueReplace = new EventEmitter();
  @Output() cancelReplace = new EventEmitter();
  @Output() noDataOkBtnFun = new EventEmitter();
  @Output() continuemapBtn = new EventEmitter();
  @Output() previewBtn = new EventEmitter();
  @Output() cancelEditProject = new EventEmitter();
  @Output() cancelExtend = new EventEmitter();
  @Output() continueExtend = new EventEmitter()

  existingProjectCode: boolean = false;
  existingProjectCodeMsg;

  @ViewChild('alertContainer', { static: true }) alertContainer: ElementRef;
  alert = {
    type: 'info',
    title: 'Are you sure?',
    subTitle: 'You are about to replace the existing file.',
    errorContent: '404 (Not Found) 404 (Not Found) 404 (Not Found) 404 (Not Found)',
  };
  constructor(private commonService: CommonService) { }
  ngOnInit() {
  }

  ngOnChanges() {
    // if () {
    //   // $("html, body").animate({ scrollTop: 0 }, "slow");

    // }
    if (this.alertErrorContent == "Project Code is in use.Contact Ctlyst_DC_HelpDesk@concentrix.com to integrate the projects") {
      this.existingProjectCode = true;
      this.existingProjectCodeMsg = 'Project Code is in use.<br>Contact <a href=\"mailto:Ctlyst_DC_HelpDesk@concentrix.com\"> Ctlyst_DC_HelpDesk@concentrix.com </a> to integrate the projects';
      this.commonService.hideLoader();
    }
    else {
      this.existingProjectCode = false;
      this.existingProjectCodeMsg = '';
    }
  }
  closeSuccessPopup() {
    // this.alertContainer.nativeElement.style.display='none';
    this.alertFlag = false;
    this.closeSuccess.emit();
  }
  closeErrorPopup() {
    // this.alertContainer.nativeElement.style.display='none';
    this.alertFlag = false;
    this.closeError.emit();
    this.commonService.hideLoader();
  }
  cancelUploadButton() {
    this.alertFlag = false;
    this.cancelUpload.emit();
    // this.alertContainer.nativeElement.style.display='none';
  }
  continueUploadButton() {
    this.alertFlag = false;
    this.continueUpload.emit();
    // this.alertContainer.nativeElement.style.display='none';
  }
  cancelDeleteButton() {
    this.alertFlag = false;
    this.cancelDelete.emit();
    // this.alertContainer.nativeElement.style.display='none';
  }
  cancelExtensionButton() {
    this.alertFlag = false;
    this.cancelExtend.emit()
  }
  continueExtensionButton() {
    this.alertFlag = false;
    this.continueExtend.emit()
  }

  continueDeleteButton() {
    this.alertFlag = false;
    this.continueDelete.emit();
    // this.alertContainer.nativeElement.style.display='none';
  }
  continueReplaceButton() {
    this.alertFlag = false;
    this.continueReplace.emit();
  }
  cancelReplaceButton() {
    this.alertFlag = false;
    this.cancelReplace.emit();
  }
  noDataOkBtn() {
    this.alertFlag = false;
    this.noDataOkBtnFun.emit();
    this.commonService.hideLoader();
  }
  continueMappingBtn() {
    this.alertFlag = true;
    this.continuemapBtn.emit();
  }
  preview() {
    this.alertFlag = false;
    this.previewBtn.emit();
  }
  closePopupWSR() {
    this.alertFlag = false;
    this.cancelEditProject.emit();
  }
}
