import { Component, OnInit } from '@angular/core';
import { HelpPageService } from '../../services/help-page.service';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
})
export class HelpPageComponent implements OnInit {
  type = 'agile';

  navigationHeaders = [
    {
      title: 'Dashboard',
      isActive: true,
    },
    {
      title: 'Add Project',
      isActive: false,
    },
    {
      title: 'Edit Project',
      isActive: false,
    },
    {
      title: 'Add Iteration Details',
      isActive: false,
    },
    {
      title: 'Edit Iteration Details',
      isActive: false,
    },
  ];

  dashboardSections: Array<any> = [];
  widgets: Array<any> = [];

  // search
  searchTerm: string;
  searchList: Array<any> = [];
  showSearchList = false;
  searchResultTerm: string;
  searchResult: Array<any>;
  showSearchResult = false;

  constructor(public helpPageService: HelpPageService) {}

  ngOnInit() {
    this.getHelpData();
  }
  getHelpData() {
    this.dashboardSections = [];
    if (this.type == 'agile') {
      this.helpPageService.getHelpData().subscribe((data: any) => {
        let helpDataForAgile;
        for (let i = 0; i < data.helpType.length; i++) {
          if (data.helpType[i]['type'] == 'agile') {
            helpDataForAgile = data.helpType[i]['sessions'];
          }
        }

        helpDataForAgile.forEach((e) => {
          this.dashboardSections.push({
            title: e.section,
            isActive: e.section === 'Uber View',
            widgets: e.widgets,
          });
          e.widgets.forEach((widget) => {
            this.searchList.push(widget.title);
          });
          if (e.section === 'Uber View') {
            this.widgets = e.widgets;
          }
        });
      });
    } else if (this.type == 'support') {
      this.helpPageService.getHelpData().subscribe((data: any) => {
        let helpDataForSupport;

        for (let i = 0; i < data.helpType.length; i++) {
          if (data.helpType[i]['type'] == 'support') {
            helpDataForSupport = data.helpType[i]['sessions'];
          }
        }
        helpDataForSupport.forEach((e) => {
          this.dashboardSections.push({
            title: e.section,
            isActive: e.section === 'Uber View',
            widgets: e.widgets,
          });
          e.widgets.forEach((widget) => {
            this.searchList.push(widget.title);
          });
          if (e.section === 'Uber View') {
            this.widgets = e.widgets;
          }
        });
      });
    }
  }
  getAgileData() {
    this.type = 'agile';
    this.navigationHeaders = [
      {
        title: 'Dashboard',
        isActive: true,
      },
      {
        title: 'Add Project',
        isActive: false,
      },
      {
        title: 'Edit Project',
        isActive: false,
      },
      {
        title: 'Add Iteration Details',
        isActive: false,
      },
      {
        title: 'Edit Iteration Details',
        isActive: false,
      },
    ];
    this.getHelpData();
  }
  getSupportData() {
    this.type = 'support';
    this.navigationHeaders = [
      {
        title: 'Dashboard',
        isActive: true,
      },
      {
        title: 'Add Project',
        isActive: false,
      },
      {
        title: 'Edit Project',
        isActive: false,
      },
      {
        title: 'Load ITSM Data',
        isActive: false,
      },
    ];
    this.getHelpData();
  }
  onHeaderClick(header) {
    this.exitSearch();
    // this.navigationHeaders = this.navigationHeaders.map(e => {
    //   if (e.title === header.title) {
    //     e.isActive = !e.isActive;
    //   } else {
    //     e.isActive = false;
    //   }
    //   return e;
    // });
  }

  onDashboardViewChange(view) {
    this.exitSearch();
    this.dashboardSections = this.dashboardSections.map((e) => {
      if (e.title === view.title) {
        e.isActive = !e.isActive;
        this.widgets = e.widgets;
      } else {
        e.isActive = false;
      }
      return e;
    });
  }

  search(searchTerm: string, event?) {
    this.searchTerm = searchTerm;
    if (!this.searchTerm) {
      this.showSearchList = false;
      return;
    }
    if (event && event.keycode !== 13) {
      this.showSearchList = true;
    } else {
      // Either enter is pressed or buttons for search is clicked.
      // Hence hide the list and show search result
      this.showSearchList = false;
      this.showSearchResult = true;
      this.searchResultTerm = searchTerm;
      this.searchResult = [];
      this.dashboardSections.forEach((f) => {
        f.widgets.forEach((f) => {
          if (f.title.toLowerCase().includes(searchTerm.toLowerCase())) {
            this.searchResult.push(f);
          }
        });
      });
    }
  }

  exitSearch() {
    this.searchTerm = '';
    this.showSearchResult = false;
    this.showSearchList = false;
  }
}
