import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertPopUp } from '../components/alert-popup/alert-popup.model';
import { SnackBarOptions } from '../components/snack-bar/snack-bar.component';
import { LoaderState } from '../models/loader.interface';
@Injectable()
export class CommonService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  private snackBarSubject = new Subject<SnackBarOptions>();
  snackBarState = this.snackBarSubject.asObservable();
  private alertPopUpSubject = new Subject<AlertPopUp>();
  alertPopUpState: Observable<AlertPopUp> = this.alertPopUpSubject.asObservable();
  counter : number = 1;

  constructor() {}

  showLoader(): void {
    this.loaderSubject.next({ show: true } as LoaderState);
  }

  hideLoader(): void {
    this.loaderSubject.next({ show: false } as LoaderState);
  }

  showSnackBar(message: string, timoutInMilliSeconds?: number): void {
    this.snackBarSubject.next({ message, timoutInMilliSeconds });
  }

  showAlertPopup(config: AlertPopUp): void {
    this.alertPopUpSubject.next(config);
  }

  getRandomNumber(){
    let date: number = new Date().getTime();
    let randomID: number = Math.floor(date / 9999);
    randomID = randomID + this.counter;
    this.counter++;
    return randomID;
  }
  
}
