<!-- <marquee width="100%" direction="left" scrollamount="5" class="marque" onMouseOver="this.stop()"
  onMouseOut="this.start()">
  You are redirected to new Digital Cockpit URL <a
    style="color: blue;">https://digitalcockpit.concentrix-catalyst.com/home</a>
  please save
  this URL. Current URL <a style="color: blue;">https://digitalcockpit.prokarma.com/</a> will be deactivated on
  11-January-2024
</marquee> -->
<div class="col-md-12 footer" *ngIf="isFooterDisable">
  <div class="footer-Left-Panel">
    <span><a href="https://cnxmail.sharepoint.com/sites/PMO41" target="_blank">Catalyst PMO</a></span>
    <span><a href="https://www.concentrix.com/privacy-policy/" target="_blank">Privacy Policy</a></span>
    &copy; Concentrix Corporation. All Rights Reserved.
  </div>
</div>



<!-- <div class="footer">
    <div class="footer-Left-Panel">
        <span>Digital Cockpit</span>
        <span>Help</span>
        <span>Privacy</span>
        <span>All Rights Reserved</span>
        &copy;&nbsp;Copyright 2018
    </div>
    <div class="footer-Right-Panel">
        <img class="footer-image" align="middle" src="assets/images/pk_logo_white.svg" alt="prokarma-logo">
    </div>
</div> -->