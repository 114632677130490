import { ReportingDiscipline } from './../enum/reporting-discipline.enum';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { API_CONFIG } from '../../../../api.config';
import { UserAccessService } from '../../../../shared/services/user-access.service';
import { forkJoin, of } from 'rxjs';
import { CommonMethod } from '../../../../shared/_helper/commonMethod.helper';
import _ from 'lodash-es';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GdrDataServiceService {
  filterData = {};
  constructor(public http: HttpClient, private userAccessService: UserAccessService,
    public commonMethod: CommonMethod) { }

  getGdrDropdownFormat(selectedItems) {
    if (selectedItems == null) return null;

    let ddData = {
      accounts: [], budgetStatus: [], businessModel: [], clientSatStatus: [], domainArea: [],
      overallStatus: [], projectStatus: [], region: [], country: [],
      reportingDiscipline: [], scheduledStatus: [], pmOrDm: [], cpOrAm: [], customerCategoryStatus: [], businessUnit: []
    };
    for (const x in selectedItems) {
      let data = [];
      selectedItems[x].forEach((e) => {
        if (e.name) {
          data.push(e.name);
        }
      });
      ddData[x] = data;
    }

    let isProjectExternalCustomerArray: boolean[];

    if (Array.isArray(ddData.customerCategoryStatus) && ddData.customerCategoryStatus.every(item => typeof item === 'string')) {
      if (ddData.customerCategoryStatus.includes('External') && ddData.customerCategoryStatus.includes('Internal')) {
        isProjectExternalCustomerArray = [true, false];
      } else if (ddData.customerCategoryStatus.includes('External')) {
        isProjectExternalCustomerArray = [true];
      } else {
        isProjectExternalCustomerArray = [false];
      }
    } else {
      // Handle the case where customerCategoryStatus is not an array of strings
      isProjectExternalCustomerArray = [];
    }

    let filterObj = {
      accounts: ddData.accounts,
      budgetStatus: ddData.budgetStatus,
      businessModel: ddData.businessModel,
      clientSatStatus: ddData.clientSatStatus,
      domainArea: ddData.domainArea,
      overallStatus: ddData.overallStatus,
      region: ddData.region,
      country: ddData.country,
      reportingDiscipline: ddData.reportingDiscipline,
      scheduledStatus: ddData.scheduledStatus,
      pmOrDm: ddData.pmOrDm,
      cpOrAm: ddData.cpOrAm,
      businessUnit: ddData.businessUnit,
      isProjectExternalCustomer: isProjectExternalCustomerArray
    };
    return filterObj;
  }

  getStatusDropdownClassColor(controlName) {
    if (controlName == "Red") {
      return 'form-control dropDownList bdrLeftRed';
    }
    else if (controlName == "Amber") {
      return 'form-control dropDownList bdrLeftAmber';
    }
    else if (controlName == "Green") {
      return 'form-control dropDownList bdrLeftGreen';
    }
    else {
      return 'form-control dropDownList';
    }
  }

  getGdrMetaData() {
    return this.http.get(API_CONFIG.gdrMetaData);
  }

  modifyDate(date: any) {
    const selectedDate = new Date(date);
    const timeOffsetInMS: number = selectedDate.getTimezoneOffset() * 60000;
    selectedDate.setTime(selectedDate.getTime() - timeOffsetInMS);
    const finalModifiedDate = selectedDate;
    return finalModifiedDate;
  }

  getTimeStampForDate(val) {
    let ts: any = '';
    if (val) {
      try {
        ts = new Date(val).setHours(0, 0, 0, 0)
        ts = ts.getTime();
      } catch (Ex) {
      }
    }
    return ts;
  }

  getYMDDateForTimeStamp(val) {
    let dt: any = '';
    if (val) {
      try {
        dt = new Date(val);
        dt = [dt.getFullYear(), this.padDate(dt.getMonth() + 1), this.padDate(dt.getDate())].join('-');
      } catch (Ex) {
      }
    }
    return dt;
  }

  getISODateForTimeStamp(val) {
    let dt: any = '';
    if (val) {
      try {
        dt = new Date(val).toISOString();
        //dt = new Date(dt).setHours(0, 0, 0, 0);
      } catch (Ex) {
      }
    }
    return dt;
  }

  getDateForTimeStamp(val) {
    let dt: any = '';
    if (val) {
      try {
        dt = new Date(val);
        dt = [this.padDate(dt.getMonth() + 1), this.padDate(dt.getDate()), dt.getFullYear()].join('/');
      } catch (Ex) {
      }
    }
    return dt;
  }

  padDate(n) {
    return n < 10 ? '0' + n : n;
  }

  validateEmail(userEmail) {
    if (userEmail == '' || userEmail == null) {
      return false
    }
    let emailList = [];
    // Pattern to detect special characters (except valid ones)
    const specialCharactersPattern = /[!#$%^&*]/;
    // Check if there are multiple consecutive commas
    const multipleCommasPattern = /,,+/;
    // Check for trailing comma or if multiple commas exist
    // Pattern to detect tabs or multiple spaces
    const tabsOrMultipleSpacesPattern = /[\t\r\n]|\s{2,}/;

    if (!userEmail || userEmail.trim().endsWith(',') || multipleCommasPattern.test(userEmail)) {
      return true; // Invalid case
    }

    if (userEmail) {
      emailList = userEmail.split(/[,]+/);
    }
    if (emailList && emailList.length) {
      for (let i = 0; i < emailList.length; i++) {
        const email = emailList[i];
        // Check for spaces within the email (invalid case)
        if (email.includes(' ')) {
          return true;
        }

        // Check for tabs or multiple spaces (invalid case)
        if (tabsOrMultipleSpacesPattern.test(email)) {
          return true;
        }
        if (specialCharactersPattern.test(emailList[i])) {
          return true;
        }
      }
    }
    return false;
  }

  validatePatternEmail(userEmail) {
    let emailList = [];
    const ctxPattern = /([a-zA-Z0-9._-]+@(concentrix)+\.(com)$)/gi;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+([a-zA-Z]{2,})))$/;
    // Pattern to detect special characters (except valid ones)
    const specialCharactersPattern = /[!#$%^&*]/;
    if (userEmail) {
      emailList = userEmail.split(/[,\s]+/);
    }
    if (emailList && emailList.length) {
      for (let i = 0; i < emailList.length; i++) {
        const email = emailList[i].trim();
        // Skip empty strings from the split result
        if (email === "") {
          continue; // Skip this iteration if the email is empty
        }
        if (!email.match(emailPattern) || !email.match(ctxPattern) || specialCharactersPattern.test(email)) {
          return true;
        }
      }
    }
    return false;
  }

  getEmailInCorrectFormat(l2, l3) {
    let emailList = [], l2EmailList = [], l3EmailList = [], result;
    l2 = l2 ? l2.replace(';', ',') : null;
    l3 = l3 ? l3.replace(';', ',') : null;
    l2EmailList = l2 ? l2.split(/[,]+/) : [];
    l3EmailList = l3 ? l3.split(/[,]+/) : [];
    l2EmailList = this.removeRedundantTextAfterEmail(l2EmailList);
    l3EmailList = this.removeRedundantTextAfterEmail(l3EmailList);
    if (l2EmailList) {
      emailList = l2EmailList?.concat(l3EmailList);
    }
    result = emailList;
    return result;
  }

  isInValidateEmailFromDb(l2, l3) {
    let emailList = [], l2EmailList = [], l3EmailList = [];
    const ctxPattern = /([a-zA-Z0-9._-]+@(concentrix)+\.(com))/gi;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+([a-zA-Z]{2,})))$/;
    l2 = l2 ? l2.replace(';', ',') : null;
    l3 = l3 ? l3.replace(';', ',') : null;
    l2EmailList = l2 ? l2.split(/[,]+/) : [];
    l3EmailList = l3 ? l3.split(/[,]+/) : [];
    l2EmailList = this.removeRedundantTextAfterEmail(l2EmailList);
    l3EmailList = this.removeRedundantTextAfterEmail(l3EmailList);
    if (l2EmailList) {
      emailList = l2EmailList?.concat(l3EmailList);
    }

    if (emailList && emailList.length) {
      for (let i = 0; i < emailList.length; i++) {
        if (!emailList[i].match(emailPattern) || !emailList[i].match(ctxPattern)) {
          return true;
        }
      }
    }
    return false;
  }

  removeRedundantTextAfterEmail(emailList) {
    emailList?.forEach((e, i) => {
      if (e.includes('@concentrix.com')) {
        let sdf = e.split("@concentrix.com")[0];
        emailList[i] = sdf.concat("@concentrix.com");
      }
    });
    emailList = emailList?.filter(e => e != '');
    return emailList;
  }

  removeDuplicateEmail(emailList) {
    // let emailList = [], l2EmailList = [], l3EmailList = [];

    // l2EmailList = l2 ? l2.split(/[,;\s]+/) : [];
    // l3EmailList = l3 ? l3.split(/[,;\s]+/) : [];
    // emailList = l2EmailList.concat(l3EmailList);

    let uniqueChars = emailList.filter((element, index) => {
      return emailList.indexOf(element) === index;
    });
    return uniqueChars.toString();
  }


  getUserMetaDataForGDRProjects() {
    //const url = API_CONFIG.usersMetadata.replace('UNIQUE_NAME', `${emailId}`);
    return this.userAccessService.getUserMetaDataAfterProjectUpdate();
  }


  getUserMetaData() {
    return this.userAccessService.getUserMetaData();
  }
  getDefultColumnList(emailId) {
    const url = API_CONFIG.getGdrDefultColumnList.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.get(url);
  }
  savePrefferedColumns(data, emailId) {
    const url = API_CONFIG.saveGdrPrfferedColumnList.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.post(url, data);
  }
  // autoDuplicate() {
  //   const data = {};
  //   const url = API_CONFIG.autoDuplicate;
  //   //data.emailId = emailId;
  //   return this.http.post(url, []);
  // }
  getWSRecordList(data) {
    const url = API_CONFIG.wsRecordList;
    //data.emailId = emailId;
    return this.http.post(url, data);
  }

  getStatusWeek() {
    var curr = new Date;
    var currWeekStartDay = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var startDt;
    if (curr.getDay() == 0 || curr.getDay() == 1) {
      startDt = currWeekStartDay - 8;
    }
    else {
      startDt = currWeekStartDay - 1;
    }
    let week = new Date(curr.setDate(startDt));
    week = this.getDateForTimeStamp(week);
    return week;
  }

  convertGDRDropdataToFilterData() {
    let restoreData = JSON.parse(localStorage.getItem('gdrDropDown'));
    let resultData = restoreData ? restoreData : [];
    resultData = resultData?.find((e) => e.tabName == "GDR")?.filterData;

    if (resultData) {
      let ddData = {
        accounts: [], budgetStatus: [], businessModel: [], clientSatStatus: [], domainArea: [],
        overallStatus: [], projectStatus: [], region: [], country: [],
        reportingDiscipline: [], scheduledStatus: [], pmOrDm: [], cpOrAm: [], customerCategoryStatus: [], businessUnit: []
      };
      for (const x in resultData) {
        let data = [];
        resultData[x].forEach((e) => {
          if (e.name) {
            data.push(e.name);
          }
        });
        ddData[x] = data;
      }

      let isProjectExternalCustomerArray: boolean[];

      if (Array.isArray(ddData.customerCategoryStatus) && ddData.customerCategoryStatus.every(item => typeof item === 'string')) {
        if (ddData.customerCategoryStatus.includes('External') && ddData.customerCategoryStatus.includes('Internal')) {
          isProjectExternalCustomerArray = [true, false];
        } else if (ddData.customerCategoryStatus.includes('External')) {
          isProjectExternalCustomerArray = [true];
        } else {
          isProjectExternalCustomerArray = [false];
        }
      } else {
        // Handle the case where customerCategoryStatus is not an array of strings
        isProjectExternalCustomerArray = [];
      }
      let filterObj = {
        accounts: ddData.accounts,
        budgetStatus: ddData.budgetStatus,
        businessModel: ddData.businessModel,
        clientSatStatus: ddData.clientSatStatus,
        domainArea: ddData.domainArea,
        overallStatus: ddData.overallStatus,
        region: ddData.region,
        country: ddData.country,
        reportingDiscipline: ddData.reportingDiscipline,
        scheduledStatus: ddData.scheduledStatus,
        pmOrDm: ddData.pmOrDm,
        cpOrAm: ddData.cpOrAm,
        businessUnit: ddData.businessUnit,
        isProjectExternalCustomer: isProjectExternalCustomerArray
      };
      this.filterData = filterObj;
    }
  }


  getSelectiveGdrInfo(data, widgetData) {
    let name = '', type = '';
    this.filterData = null;
    this.convertGDRDropdataToFilterData();
    data['filterData'] = this.filterData;
    let stackedBarRegion = ['PROJECTS AT BUDGET RISK BY REGION', 'PROJECTS AT SCHEDULE RISK BY REGION', 'PROJECTS AT CLIENT SAT. RISK BY REGION'],
      stackedBarVertical = ['PROJECTS AT BUDGET RISK BY VERTICAL', 'PROJECTS AT SCHEDULE RISK BY VERTICAL', 'PROJECTS AT CLIENT SAT. RISK BY VERTICAL'],
      stackedBarCustomer = ['PROJECTS AT BUDGET RISK BY CUSTOMER', 'PROJECTS AT SCHEDULE RISK BY CUSTOMER', 'PROJECTS AT CLIENT SAT. RISK BY CUSTOMER'],
      stackedBarOverallVertical = ['OVERALL STATUS BY VERTICAL'],
      stackedBarOverallCustomer = ['OVERALL STATUS BY CUSTOMER'],
      stackedBarOverallRegion = ['OVERALL STATUS BY REGION'],
      stackedBarRag = ['RAG STATUS'],
      lineChart = ['BUDGET STATUS - GLOBAL', 'SCHEDULE STATUS - GLOBAL', 'CLIENT SAT. STATUS - GLOBAL'];

    if (widgetData.widgetName == 'OVERALL STATUS SUMMARY') {
      name = 'projectAtRisk';
      if (widgetData.tabName == 'WSR - RED') {
        data['projectAtRisk'] = "Red";
      }
      else if (widgetData.tabName == 'WSR - AMBER') {
        data['projectAtRisk'] = "Amber";
      }
      else if (widgetData.tabName == 'WSR - GREEN') {
        data['projectAtRisk'] = "Green";
      }
      else if (widgetData.tabName == 'WSR - RED 5+weeks') {
        data['projectsRedInFiveWeeks'] = true;
      }
      else if (widgetData.tabName.includes("WSR - Projects turned")) {
        data['projectTurnedLastWeek'] = widgetData.color;
      }
    }
    else if (widgetData.widgetName == 'PROJECT CONTRACT CATEGORIES') {
      name = 'projectContractCategory';
      data['projectContractCategory'] = widgetData.fieldName;
    }
    else if (widgetData.widgetName == 'PROJECT COUNT BY CURRENCY') {
      name = 'projectCountByCurrency';
      data['projectCountByCurrency'] = this.commonMethod.convertCurrencyCodeToCurrency(widgetData.fieldName);
    }
    else if (widgetData.widgetName == 'REVENUE AT RISK') {
      name = 'revenueAtRisk';
      data['status'] = widgetData.color;
      data['revenueAtRisk'] = this.commonMethod.convertCurrencyCodeToCurrency(widgetData.fieldName);
    }
    else if (widgetData.widgetName == 'PROJECT AGE IN RISK') {
      name = 'projectAgeInRisk';
      data['status'] = widgetData.color;
      data['projectAgeInRisk'] = widgetData.fieldName;
    }
    else if (stackedBarRegion.includes(widgetData.widgetName) || stackedBarVertical.includes(widgetData.widgetName)
      || stackedBarCustomer.includes(widgetData.widgetName) || stackedBarRag.includes(widgetData.widgetName) || lineChart.includes(widgetData.widgetName)
      || stackedBarOverallVertical.includes(widgetData.widgetName) || stackedBarOverallCustomer.includes(widgetData.widgetName) || stackedBarOverallRegion.includes(widgetData.widgetName)) {
      if (stackedBarRag.includes(widgetData.widgetName)) {
        type = this.getStatusOfRAGWidget(widgetData.tabName);
      }
      else if (stackedBarOverallVertical.includes(widgetData.widgetName)) {
        type = this.getStatusOfOverallWidget(widgetData.tabName)
      }
      else if (stackedBarOverallCustomer.includes(widgetData.widgetName)) {
        type = this.getStatusOfOverallWidget(widgetData.tabName)
      }
      else if (stackedBarOverallRegion.includes(widgetData.widgetName)) {
        type = this.getStatusOfOverallWidget(widgetData.tabName)
      }
      else {
        type = this.getStatusOfWidget(widgetData.tabName);
      }
      data[type] = widgetData.color;
      if (stackedBarRegion.includes(widgetData.widgetName)) {
        name = 'projectsByRegion';
        data['projectsByRegion'] = widgetData.fieldName;
      }
      else if (stackedBarVertical.includes(widgetData.widgetName)) {
        name = 'projectsByVertical';
        data['projectsByVertical'] = widgetData.fieldName;
      }
      else if (stackedBarCustomer.includes(widgetData.widgetName)) {
        name = 'projectsByCustomer';
        data['projectsByCustomer'] = widgetData.fieldName;
      }
      else if (stackedBarOverallVertical.includes(widgetData.widgetName)) {
        name = 'projectsByVertical';
        data['projectsByVertical'] = widgetData.fieldName;
      }
      else if (stackedBarOverallCustomer.includes(widgetData.widgetName)) {
        name = 'projectsByCustomer';
        data['projectsByCustomer'] = widgetData.fieldName;
      }
      else if (stackedBarOverallRegion.includes(widgetData.widgetName)) {
        name = 'projectsByRegion';
        data['projectsByRegion'] = widgetData.fieldName;
      }

      else if (stackedBarRag.includes(widgetData.widgetName)) {
        name = 'ragStatus';
      }
      else if (lineChart.includes(widgetData.widgetName)) {
        name = 'globalStatus';
        if (widgetData.color == 'Red / Amber') {
          data[type] = '';
        }
        data['week'] = widgetData.fieldName;
      }
    }
    const url = API_CONFIG.gdReportSelectiveWidget.replace('WIDGET_NAME', name);
    return this.http.post(url, data);
  }

  getStatusOfRAGWidget(data) {
    let type = data.split(' - ');
    type = type[1];
    if (type == 'Budget Status') {
      return 'budgetRisk';
    }
    else if (type == 'Schedule Status') {
      return 'scheduleRisk';
    }
    else if (type == 'CSAT Status') {
      return 'clientSatRisk';
    }
    else {
      return '';
    }
  }

  getStatusOfOverallWidget(data) {
    let type = data.split(' - ');
    type = type[0];
    if (type == 'OverAll Status') {
      return 'overAllStatus';
    }
  }

  getStatusOfWidget(data) {
    let type = data.split(' - ');
    type = type[0];
    if (type == 'Budget Risk') {
      return 'budgetRisk';
    }
    else if (type == 'Schedule Risk') {
      return 'scheduleRisk';
    }
    else if (type == 'CSAT Risk') {
      return 'clientSatRisk';
    }
    else {
      return '';
    }
  }
  getGDReportData(data) {
    const url = API_CONFIG.gdReportList;
    //data.emailId = emailId;
    return this.http.post(url, data);
  }

  duplicateWSRecord() {
    const prjData = { action: 'duplicate' };
    const url = API_CONFIG.wsRecordList;
    return this.http.put(url, prjData);
  }
  deleteWSRecord(recordId) {
    const prjData = { id: recordId, action: 'delete' };
    const url = API_CONFIG.wsRecordList;
    return this.http.put(url, prjData);
  }
  getProgram() {
    const url = API_CONFIG.getProgram;
    return this.http.get(url);
  }
  getProjectById(id) {
    const url = API_CONFIG.getProjectssByID.replace('programId', `${id}`);
    return this.http.get(url);
  }
  checkCurrentAssesment(id, year, month) {
    const url = API_CONFIG.checkCurrentAssesment.replace('PROJECT_ID', `${id}`).replace('YEAR', `${year}`).replace('MONTH', `${month}`);
    return this.http.get(url);
  }
  createWSRecord(project: any) {
    const url = API_CONFIG.createWSRecord;
    //data.emailId = emailId;
    return this.http.post(url, project);

    //return this.http.post(`${API_CONFIG.addProject}${'/'}`, project);
  }

  //create WSR risk record
  createWsrRisk(riskData) {
    const url = API_CONFIG.createWsrRisk

    return this.http.post(url, riskData)
  }

  //update WSR risk
  updateWsrRisk(riskData) {
    const url = API_CONFIG.createWsrRisk

    return this.http.put(url, riskData)
  }
  //getting the WSR risk list
  getWsrRiskList(projectData) {
    const url = API_CONFIG.getWsrRiskList
    return this.http.post(url, projectData)
  }
  updateWSRData(project: any) {
    const url = API_CONFIG.updateWSRData;
    //data.emailId = emailId;
    return this.http.put(url, project);
  }

  //getting previous week WSR record
  getPreviousWeekWsrRecord(projectData, projectId) {
    const projectUrl = API_CONFIG.projectData.replace('PROJECT_ID', `${projectId}`);
    const WsrUrl = API_CONFIG.getPreviousWeekWSRAndProject
    let pjt = this.http.get(projectUrl)
    let gdr = this.http.post(WsrUrl, projectData)
    return forkJoin([pjt, gdr]);
  }

  getProjectData(projectId: string) {
    const url = API_CONFIG.projectData.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }

  getProjectAndWSRData(projectId: string) {
    const projectUrl = API_CONFIG.projectData.replace('PROJECT_ID', `${projectId}`);
    const GDRurl = API_CONFIG.wsRecordLatestData.replace('PROJECT_ID', `${projectId}`);
    let pjt = this.http.get(projectUrl);
    let gdr = this.http.get(GDRurl);
    return forkJoin([pjt, gdr]);
  }

  getWSRSpecificRecord(wsrId) {
    const url = API_CONFIG.wsrSpecificRecord.replace('ID', `${wsrId}`);
    return this.http.get(url);
  }

  addGDRProject(project: any) {
    return this.http.post(`${API_CONFIG.addProject}${'/'}`, project);
  }
  updateGDRProject(project: any) {
    return this.http.put(`${API_CONFIG.addProject}${'/'}`, project);
  }

  shareBvA(data) {
    const url = API_CONFIG.shareBvA;
    return this.http.post(url, data);
  }

  getWSRByDateRange(projectId, statusWeek, weekStartDate) {
    return this.http.post(`${API_CONFIG.getWsrByDateRange}`, {
      projectId,
      weekStartDate,
      statusWeek
    }, {
      withCredentials: false,
      observe: 'response'
    })
      .pipe(
        catchError(() => of([])),
        map(
          (response: HttpResponse<any>) => response.body
        ))
  }

  getReportingDiscipline(WSRRecords, statusWeek) {
    let totalWSRCount = -1;
    let wsrByUser = 0;
    if (WSRRecords?.length === 0) { // considering fresh record as no records found
      return ReportingDiscipline._100;
    } else {
      totalWSRCount = this.getTotalWSRCount(WSRRecords.length, statusWeek, WSRRecords)
    }
    if (totalWSRCount > 0) {
      if (totalWSRCount === WSRRecords.length) { // no current record found so included all records as there are no deleted in between
        wsrByUser = _.filter(WSRRecords, item => item.createdBy?.toLowerCase() !== "system").length;
      } else {//there are deleted record and chance of having data to consider is only of previous week, because if in between is zero then count would reset.
        wsrByUser = _.filter(WSRRecords, item => item.createdBy?.toLowerCase() !== "system" && item.statusWeek === this.getPreviousStatusWeekDate(statusWeek, 1)).length
      }
    }
    let reportDiscipline;
    if (totalWSRCount === 0) { // for adding on current week giving excellent
      reportDiscipline = ReportingDiscipline._100;
    } else if (totalWSRCount === -1) { // If current week records always exist
      let currentWeekRecord = _.filter(WSRRecords, { 'statusWeek': statusWeek })
      reportDiscipline = currentWeekRecord[0]?.reportingDiscipline;
    } else {
      // added plus 1 considering of current week
      let wsrByUserPercentage = Math.round(((wsrByUser + 1) / (totalWSRCount + 1)) * 100);
      reportDiscipline = ReportingDiscipline['_' + wsrByUserPercentage]
    }
    return reportDiscipline;
  }

  getTotalWSRCount(WSRRecordsLength, statusWeek, WSRRecords) {
    if (_.filter(WSRRecords, { "statusWeek": statusWeek }).length) {
      return -1; // as its only update, not create so will add same existing data
    }
    if (WSRRecords.length === 3) { // All past 3 weeks records available
      if (!_.filter(WSRRecords, { 'statusWeek': statusWeek }).length) {
        return 3;
      }
    }

    if (WSRRecords.length === 2) {
      if (!_.filter(WSRRecords, { 'statusWeek': this.getPreviousStatusWeekDate(statusWeek, 1) }).length) {
        return 0; // as if previous week date is not there then should reset count
      } else if (!_.filter(WSRRecords, { 'statusWeek': this.getPreviousStatusWeekDate(statusWeek, 2) }).length) {
        return 1; // if 2nd week not there, then count only for last week
      } else return 2;
    }

    if (WSRRecords.length === 1) {
      if (_.filter(WSRRecords, { 'statusWeek': this.getPreviousStatusWeekDate(statusWeek, 1) }).length) {
        return 1; // only if last week date is available then only count to 1
      } else return 0;
    }

  }

  getPreviousStatusWeekDate(currentStatusWeek, previousWeeksCount) {
    var now = new Date(currentStatusWeek);
    now.setDate(now.getDate() - (previousWeeksCount * 7));
    var newDate = (("0" + (now.getMonth() + 1)).slice(-2)) + '/' + now.getDate() + '/' + now.getFullYear();
    return newDate;
  }

  getPmsOrDms() {
    const url = API_CONFIG.gdrPmsOrDms;
    return this.http.post(url, null);
  }

  getCpsOrAms() {
    const url = API_CONFIG.gdrCpsOrAms;
    return this.http.post(url, null);
  }

  getLastSaturdayofWeek() {
    let dateToday = new Date();
    let lastTuesday = new Date(dateToday.setDate((dateToday.getDate() - dateToday.getDay()) - 1));
    return lastTuesday.toLocaleDateString('en-US');
  }

}
