import { ColorCodes } from '../../../shared/models/colorcodes.model';

export class HealthMetricsModel {
  totalNoOfSprints: number;
  //acceptedVarience: AcceptedVarience;
  acceptedStoryCount: AcceptedStoryCount;
  //deploymentVarience: DeploymentVarience;
  deploymentStoryCount:DeploymentStoryCount;
  //defects: number;
  prodDefects:number;
  defectsPerFeature: number;
  productivity: number;
  agileMaturity: AgileMaturity;
  agileMaturityAssessment: any;
  helpContent: Array<any>;
  ncMetrics: any;
  helpData: Array<any>;
  dexScore: any;
  avgVelocityPersonPerSprint;
  avgVelocityTeamPerDay;
  lastAuditDate;
  increseInproductivity;

  constructor(
    totalNoOfSprints: number,
    //acceptedVarience: AcceptedVarience,
    acceptedStoryCount: AcceptedStoryCount,
   // deploymentVarience: DeploymentVarience,
    deploymentStoryCount:DeploymentStoryCount,
   // defects: number,
    prodDefects:number,
    defectsPerFeature: number,
    productivity: number,
    agileMaturity: AgileMaturity,
    agileMaturityAssessment: Array<any>,
    helpContent: Array<any>,
    ncMetrics,
    helpData,
    dexScore,
    avgVelocityPersonPerSprint,
    avgVelocityTeamPerDay,
    lastAuditDate,
    increseInproductivity
  ) {
    this.lastAuditDate = lastAuditDate;
    this.avgVelocityPersonPerSprint = avgVelocityPersonPerSprint;
    this.avgVelocityTeamPerDay = avgVelocityTeamPerDay;
    this.totalNoOfSprints = totalNoOfSprints;
    //this.acceptedVarience = acceptedVarience;
    this.acceptedStoryCount = acceptedStoryCount;
   // this.deploymentVarience = deploymentVarience;
    this.deploymentStoryCount = deploymentStoryCount;
   // this.defects = defects;
    this.prodDefects = prodDefects;
    this.defectsPerFeature = defectsPerFeature;
    this.productivity = productivity;
    this.agileMaturity = agileMaturity;
    this.agileMaturityAssessment = agileMaturityAssessment;
    this.helpContent = helpContent;
    this.ncMetrics = ncMetrics;
    this.helpData = helpData;
    this.dexScore = dexScore;
    this.increseInproductivity = increseInproductivity;
    /*  helpContents.forEach(e => {
             this.helpContents.push({ widget: e.widget, message: e.message });

         }); */
  }
}

class Varience {
  target: number;
  fulfilled: number;
  percentage: number;
  colorCode: string;
  overallHealthMetric: string;
  constructor(target: number, fulfilled: number) {
    this.target = target;
    this.fulfilled = fulfilled;
    const percentage = (fulfilled / target) * 100;
    this.percentage = isNaN(percentage) ? 0 : percentage;
    if (this.percentage >= 90) {
      this.overallHealthMetric = 'Good';
    } else if (this.percentage >= 70 && this.percentage < 90) {
      this.overallHealthMetric = 'Average';
    } else if (this.percentage < 70) {
      this.overallHealthMetric = 'Poor';
    }
    this.colorCode = getColorCode(this.percentage);
  }
}

//export class AcceptedVarience extends Varience {
//  constructor(target: number, fulfilled: number) {
//    super(target, fulfilled);
//  }
//}

export class AcceptedStoryCount extends Varience {
  constructor(target: number, fulfilled: number) {
    super(target, fulfilled);
  }
}

/*export class DeploymentVarience extends Varience {
  constructor(target: number, fulfilled: number) {
    super(target, fulfilled);
  }
}*/

export class DeploymentStoryCount extends Varience {
  constructor(target: number, fulfilled: number) {
    super(target, fulfilled);
  }
}

export class AgileMaturity extends Varience {
  constructor(target: number, fulfilled: number) {
    super(target, fulfilled);
    this.colorCode = getColorCodeForAgileMaturity(this.percentage, true);
  }
}

// Helper method
const getColorCode = (percentage: number, isDiff?: boolean) => {
  if (isDiff) {
    return percentage === 100 ? ColorCodes.GREEN : percentage === 0 ? ColorCodes.RED : ColorCodes.YELLOW;
  }
  return percentage >= 90 ? ColorCodes.GREEN : percentage < 90 && percentage >= 70 ? ColorCodes.YELLOW : ColorCodes.RED;
};

const getColorCodeForAgileMaturity = (percentage: number, isDiff?: boolean) => {
  if (isDiff) {
    return percentage === 100 ? ColorCodes.GREEN : ColorCodes.YELLOW;
  }
  return percentage == 100 ? ColorCodes.GREEN : ColorCodes.YELLOW;
};
