<div #alertContainer *ngIf="alertFlag" style="background-color: white;">
  <div class="transparentbg"></div>
  <div class="alertContent" *ngIf="alertType !== 'gridConfirm'">
    <div class="colorBackgroundContainer" [ngClass]="alertType">
      <div class="imageContainer">
        <div class="successImage" *ngIf="alertType === 'success'">
          <img src="/assets/images/success-alert.svg" />
        </div>
        <div class="errorImage" *ngIf="alertType === 'error'">
          <img src="/assets/images/error-alert.svg" />
        </div>
        <div class="errorImage" *ngIf="alertType ===  'cancel'" style="background-color: white;">
          <img src="/assets/images/error-alert.svg" />
        </div>
        <div class="infoImage" *ngIf="alertType === 'confirm'"></div>
      </div>
      <div class="titleContainer" *ngIf="alertType !== 'gridConfirm'" style="background-color: white;">{{ alertTitle }}
      </div>
      <div class="subTitleContainerConfirm" *ngIf="alertType !== 'gridConfirm' && alertType === 'confirm'">{{
        alertSubTitle }}</div>
      <div class="subTitleContainerSuccess" *ngIf="alertType !== 'gridConfirm' && alertType === 'success'">{{
        alertSubTitle }}</div>
      <div class="subTitleContainerError" *ngIf="alertType !== 'gridConfirm' && alertType === 'cancel'"
        style="background-color: white;">{{
        alertSubTitle
        }}</div>
      <div class="subTitleContainerError" *ngIf="alertType !== 'gridConfirm' && alertType === 'error'">{{
        alertSubTitle
        }}</div>
      <div class="textContainer" *ngIf="alertType === 'error'">
        <article>
          <h4 class="h3Text">Error Details</h4>
          <p *ngIf="!existingProjectCode" class="errorContent">{{ alertErrorContent }}</p>
          <p *ngIf="existingProjectCode" class="errorContentBold" [innerHTML]="existingProjectCodeMsg"></p>
        </article>
      </div>
      <div class="textContainer" *ngIf="alertType ===  'cancel'" style="background-color: white;">
        <article>
          <h4 class="h3Text">Error Details</h4>
          <p *ngIf="!existingProjectCode" class="errorContent" style="margin: 0px 0px 0px !important">{{
            alertErrorContent }}</p>
          <p *ngIf="existingProjectCode" class="errorContentBold" [innerHTML]="existingProjectCodeMsg"></p>
        </article>
      </div>
      <div class="" style="background-color: white;">
        <button class="okButton" (click)="closeSuccessPopup()" *ngIf="alertType === 'success'">OK</button>
        <button class="tryAgainButton" (click)="closeErrorPopup()" *ngIf="alertType === 'error'">{{changeTryagain ?
          'Close' : 'Try Again'}}</button>
        <button class="tryAgainButton" (click)="closePopupWSR()" *ngIf="alertType === 'cancel'"
          [disabled]="refreshWsr">{{changeCancelBtn ? 'Close' : 'Try Again'}}</button>
        <button class="cancelButton" (click)="cancelUploadButton()" *ngIf="alertType === 'confirm'">No, Cancel!</button>
        <button class="replaceButton" (click)="continueUploadButton()" *ngIf="alertType === 'confirm'">Yes, Replace
          it!</button>
      </div>
    </div>
  </div>
  <div class="alertContent" *ngIf="alertType === 'gridConfirm'">
    <div class="colorBackgroundContainer error">
      <div class="imageContainer">
        <div class="infoImage"></div>
      </div>
      <div class="titleContainer">{{ alertTitle }}</div>
      <div class="addInfoContainerError" *ngIf="alertAddInfo" [innerHTML]="alertAddInfo"></div>
      <div class="subTitleContainerError">{{ alertSubTitle }}</div>
      <div class="">
        <button class="cancelButtonGrid" (click)="cancelDeleteButton()">No, Cancel!</button>
        <button class="deleteButton" (click)="continueDeleteButton()">Yes, Delete it!</button>
      </div>
    </div>
  </div>
  <div class="alertContent" *ngIf="alertType === 'bvaConfirm'">
    <div class="colorBackgroundContainer error">
      <div class="imageContainer">
        <div class="infoImage"></div>
      </div>
      <div class="titleContainer">{{ alertTitle }}</div>
      <div class="addInfoContainerError" *ngIf="alertAddInfo" [innerHTML]="alertAddInfo"></div>
      <div class="subTitleContainerError">{{ alertSubTitle }}</div>
      <div class="">
        <button class="extendBvaButton" (click)="continueExtensionButton()">Yes, Extend it!</button>
        <button class="cancelExtendButton" (click)="cancelExtensionButton()">No, Cancel!</button>

      </div>
    </div>
  </div>

  <div class="alertContent" *ngIf="alertType === 'gridReplace'">
    <div class="colorBackgroundContainer error">
      <div class="imageContainer">
        <div class="infoImage"></div>
      </div>
      <div class="deleteTitle">{{ alertTitle }}</div>
      <div class="deleteSubTitle">{{ alertSubTitle }}</div>
    </div>
    <div class="">
      <button class="cancelButtonGrid" (click)="cancelReplaceButton()">No, Cancel!</button>
      <button class="deleteButton" (click)="continueReplaceButton()">Yes,Replace it!</button>
    </div>
  </div>
  <div class="alertContent" *ngIf="alertType === 'noData'">
    <div class="colorBackgroundContainer confirm">
      <div class="imageContainer">
        <div class="noDataImage"></div>
      </div>
      <div class="deleteTitle">{{ alertTitle }}</div>
      <div class="deleteSubTitle">{{ alertSubTitle }}</div>

      <div class="">
        <div class="textContainer-nodata" *ngIf="alertType === 'noData'">
          <article>
            <p class="">{{ alertErrorContent }}</p>
          </article>
        </div>
        <button class="okButtonGrid" (click)="noDataOkBtn()">OK</button>
      </div>
    </div>
  </div>

  <div class="alertContent" *ngIf="alertType === 'mappingConfirm'">
    <div class="colorBackgroundContainer confirm">
      <div class="imageContainer">
        <div class="infoImage"></div>
      </div>
      <div class="deleteTitle">{{ alertTitle }}</div>
      <div class="deleteSubTitle">{{ alertSubTitle }}</div>
      <div class="">
        <div class="textContainer-nodata" *ngIf="alertType === 'mappingConfirm'">
          <article>
            <p class="">{{ alertErrorContent }}</p>
          </article>
        </div>
        <div class="">
          <button class="previewButtonGrid" (click)="preview()">Preview</button>
          <button class="continueMApping" (click)="continueMappingBtn()">Yes</button>
        </div>
      </div>
    </div>
  </div>

  <div class="alertContent noRoleAccessError"
    *ngIf="alertType === 'noRoleAccessError' || alertType === 'dashboardAccessError'">
    <div class="colorNoAccessBackgroundContainer error">
      <div class="close-btn" *ngIf="closeIconStatus">
        <span>
          <img (click)="closeErrorPopup()" src="/assets/images/close-button.svg">
        </span>
      </div>
      <div class="titleContainer" style="padding-top: 30px;">{{ alertTitle }}
        <span class="noRoleAccessErrorImage" *ngIf="alertType === 'noRoleAccessError'">
          <img src="/assets/images/norole-access-error.svg" />
        </span>
        <span class="dashboardAccessErrorImage" *ngIf="alertType === 'dashboardAccessError'">
          <img src="/assets/images/dashboard-access-error.svg" />
        </span>
      </div>
      <div class="subTitleContainerError" style="color: #000; padding-top: 35px;" [innerHTML]="alertSubTitle"></div>
      <div class="textContainerNoRoleAccessError" *ngIf="alertType === 'noRoleAccessError' || 'dashboardAccessError'">
        <div class="errorContent" style="padding-bottom: 12px;" [innerHTML]="alertErrorContent"></div>
      </div>
    </div>
  </div>

</div>