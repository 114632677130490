import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG } from '../../../api.config';

@Injectable()
export class DpoDashboardServce {
  constructor(public http: HttpClient) {}
  dpoDataExist(projectId: any, month: string, year, p1p2Data, p3p4Data, nonProdData, createdBy, createdDate) {
    const url = API_CONFIG.dpoDataExist;
    const formdata: FormData = new FormData();
    formdata.append('projectId', projectId);
    formdata.append('month', month);
    formdata.append('year', year);
    if (p1p2Data !== null) {
      formdata.append('p1p2Data', p1p2Data);
    } else {
      formdata.append('p1p2Data', new File([], ''));
    }
    if (p3p4Data !== null) {
      formdata.append('p3p4Data', p3p4Data);
    } else {
      formdata.append('p3p4Data', new File([], ''));
    }
    if (nonProdData !== null) {
      formdata.append('nonProdData', nonProdData);
    } else {
      formdata.append('nonProdData', new File([], ''));
    }
    formdata.append('createdBy', createdBy);
    formdata.append('createdDate', createdDate);

    return this.http.post(url, formdata);
  }
  uploadData(projectId: any, month: string, year, p1p2Data, p3p4Data, nonProdData, createdBy, createdDate) {
    const url = API_CONFIG.dpoDataUpload;
    const formdata: FormData = new FormData();
    formdata.append('projectId', projectId);
    formdata.append('month', month);
    formdata.append('year', year);
    if (p1p2Data !== null) {
      formdata.append('p1p2Data', p1p2Data);
    } else {
      formdata.append('p1p2Data', new File([], ''));
    }
    if (p3p4Data !== null) {
      formdata.append('p3p4Data', p3p4Data);
    } else {
      formdata.append('p3p4Data', new File([], ''));
    }
    if (nonProdData !== null) {
      formdata.append('nonProdData', nonProdData);
    } else {
      formdata.append('nonProdData', new File([], ''));
    }
    formdata.append('createdBy', createdBy);
    formdata.append('createdDate', createdDate);

    return this.http.post(url, formdata);
  }
  getUberview(uploadYear, uploadMonth, projectId) {
    const url = API_CONFIG.dpoUberDetails
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.get(url);
  }
  getAdditionalInfo(uploadYear, uploadMonth, projectId) {
    const url = API_CONFIG.getAdditionalInfo
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.get(url);
  }
  addAdditionalInfo(uploadYear, uploadMonth, projectId, data) {
    const url = API_CONFIG.addAdditionalInfo
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.put(url, data);
  }
  updateAdditionalInfo(uploadYear, uploadMonth, projectId, id, data) {
    const url = API_CONFIG.addAdditionalInfo
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.put(url, data);
  }
  getProdTrends(uploadYear, uploadMonth, projectId) {
    const url = API_CONFIG.dpoProdMetricTrend
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.get(url);
  }
  getNonProdTrends(uploadYear, uploadMonth, projectId) {
    const url = API_CONFIG.dpoNonProdMetricTrend
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.get(url);
  }
  getDateFilterList(id) {
    const url = API_CONFIG.dpoDataFilterList.replace('PROJECT_ID', `${id}`);
    return this.http.get(url);
  }
  getProdComments(uploadYear, uploadMonth, projectId) {
    const url = API_CONFIG.dpoProdMetricTrendsComments
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    return this.http.get(url);
  }
  addProdComment(projectId: number, uploadYear, uploadMonth, gridName: string, comment: string) {
    const url = API_CONFIG.dpoProdSaveComment
      .replace('PROJECT_ID', `${projectId}`)
      .replace('UPLOAD_YEAR', `${uploadYear}`)
      .replace('UPLOAD_MONTH', `${uploadMonth}`);
    const params = {
      widgetName: gridName,
      comment,
    };
    return this.http.put(url, params);
  }
  downloadFile(year, monthFrom, monthTo, projectId, type, downloadToYear) {
    const url = API_CONFIG.dpoDownloadFile
      .replace('FROM_YEAR', `${year}`)
      .replace('TO_YEAR', `${downloadToYear}`)
      .replace('MONTH_FROM', `${monthFrom}`)
      .replace('MONTH_TO', `${monthTo}`)
      .replace('PROJECT_ID', `${projectId}`)
      .replace('TYPE', `${type}`);
    return this.http.get(url, { responseType: 'blob' });
  }
  updateDpoTeamName(data) {
    const url = API_CONFIG.updateDevOpsNameList;
    return this.http.post(url, data);
  }
  getDevopsList() {
    const url = API_CONFIG.getDevopsNameslist;
    return this.http.get(url);
  }
}
