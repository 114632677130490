import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonMethod {
  currencysfs: any

  constructor() { }


  convertCurrencyToCurrCode(value, currencysfa?) {
    // let sfa = currencysfa
    let sfa = {
      USD: 'USD - US Dollar', SGD: 'SGD - Singapore Dollar', MXN: 'MXN - Mexican Peso',
      KRW: 'KRW - South Korean Won', JPY: 'JPY - Japanese Yen',
      INR: 'INR - Indian Rupee', GBP: 'GBP - UK Pound', EUR: 'EUR - Euro',
      CAD: 'CAD - Canadian Dollar', AUD: 'AUD - Australian Dollar', AED: 'AED - UAE Dirham',
      EGP: 'EGP - Egyptian Pound', MUR: 'MUR - Mauritian Rupee', NZD: 'NZD - New Zealand Dollar', CNY: 'CNY - Chinese Yuan',
      ARS: 'ARS - Argentina Peso'
    };
    //let tempData = cloneDeep(this.rows[rowIndex]);
    if (value) {
      for (const key in sfa) {
        if (sfa[key] == value) {
          return key;
        }
      }
    }
  }


  getTimeStampForDate(val) {
    let ts: any = '';
    if (val) {
      try {
        ts = new Date(val).setHours(0, 0, 0, 0)
        ts = ts.getTime();
      } catch (Ex) {
      }
    }
    return ts;
  }

  convertCurrencyCodeToCurrency(value) {
    let sfa = {
      USD: 'USD - US Dollar', SGD: 'SGD - Singapore Dollar', MXN: 'MXN - Mexican Peso',
      KRW: 'KRW - South Korean Won', JPY: 'JPY - Japanese Yen',
      INR: 'INR - Indian Rupee', GBP: 'GBP - UK Pound', EUR: 'EUR - Euro',
      CAD: 'CAD - Canadian Dollar', AUD: 'AUD - Australian Dollar', AED: 'AED - UAE Dirham',
      EGP: 'EGP - Egyptian Pound', MUR: 'MUR - Mauritian Rupee', NZD: 'NZD - New Zealand Dollar', CNY: 'CNY - Chinese Yuan',
      ARS: 'ARS - Argentina Peso'
    };
    //let tempData = cloneDeep(this.rows[rowIndex]);

    if (value) {
      for (const key in sfa) {
        if (key == value) {
          return sfa[key];
        }
      }
    }
  }

  public convertNumberToCurrencyFormat(value) {
    let result = null;
    if (value === undefined || value === "" || value === null || value === '-') {
      return '';
    }

    if (typeof value === 'string' || typeof value === 'number') {
      if (typeof value === 'number') {
        value = value.toString();
      }
      if (value) {
        let convVal = value.replace(/,/g, '');
        convVal = convVal.replace('.', '');
        if (!convVal.match(/\d/g)) {
          return value;
        }
      }
      let num = value.replace(/,/g, '');
      let numArr = num.split('.');
      value = parseInt(numArr[0]);
      result = new Intl.NumberFormat('en-US').format(value);
      if (num.includes('.') && (numArr[1] == '' || numArr[1] == "")) {
        result = result.concat('.');
      }
      else if (numArr[1]) {
        result = result.concat('.').concat(numArr[1]);
      }
      return result;
    }
    return value;
  }

  public dateToMonthNameConversion(value) {
    if (value === undefined || value === null || value === '-') {
      return null;
    }
    if (typeof value === 'string') {
      return this.getDateForTimeStamp(value);
    }
    return value;
  }

  public getDateForTimeStamp(val) {
    let dt: any = '';
    if (val) {
      try {
        dt = new Date(val);
        dt = [this.padDate(dt.getDate()), this.getMonthName(dt, dt.getMonth()), dt.getFullYear()].join('-');
      } catch (Ex) {
      }
    }
    return dt;
  }

  public padDate(n) {
    return n < 10 ? '0' + n : n;
  }

  public getMonthName(dt, monthNumber) {
    const month = parseInt(monthNumber);
    const date = new Date(dt);
    date.setMonth(month);

    return date.toLocaleString('en-US', { month: 'short' });
  }

}
