import { Injectable } from '@angular/core';

@Injectable()
export class PdfPptHelper {
  public metricsToReport = {
    summaryDetails: true,
    velocity: true,
    productivity: true,
    defectTrends: true,
    unitTestCoverage: true,
    staticCodeAnalysis: true,
    defectsClassification: true,
    cycleTime: true,
  };

  public accordionOpenCollapseStatus() {
    const accordiongroup = document.getElementsByTagName('accordion-group');
    let isUberViewExpanded = false;
    let isMetricTrendsExpanded = false;
    let isBenefitsExpanded = false;
    let isAssessmentExpanded = false;
    let isEnhancementTrendsExpanded = false;
    let isFocusAreasExpanded = false;
    let isFocusAreaQuestionsExpanded = false;
    let isAssessmentSummarySectionExpanded = false;
    let dpoUberviewExpanded = false;
    let prodDpoMetricTrendsExpanded = false;
    let nonProdDpoMetricTrendExpanded = false;
    let dpoAdditionalIndoExpanded = false;
    if (accordiongroup && accordiongroup.length) {
      for (let i = 0; i < accordiongroup.length; i++) {
        if (accordiongroup[i].innerHTML.includes('UBERVIEW')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            isUberViewExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('UBERVIEW')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            dpoUberviewExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('METRIC TRENDS')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            isMetricTrendsExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('PRODUCTION METRIC TRENDS')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            prodDpoMetricTrendsExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('NON-PRODUCTION METRIC TRENDS')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            nonProdDpoMetricTrendExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('ADDITIONAL INFORMATION')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            dpoAdditionalIndoExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('PROOF OF VALUES')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            isBenefitsExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('ASSESSMENTS')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            isAssessmentExpanded = true;
          }
        }
        if (accordiongroup[i].innerHTML.includes('ENHANCEMENT TRENDS')) {
          if (accordiongroup[i].className.includes('panel-open')) {
            isEnhancementTrendsExpanded = true;
          }
        }
        if (accordiongroup[i].id === 'focusArea-section') {
          if (accordiongroup[i].className === 'panel panel-open') {
            isFocusAreasExpanded = true;
          }
        }
        if (accordiongroup[i].id === 'selected-focusArea-questions-section') {
          if (accordiongroup[i].className === 'panel panel-open') {
            isFocusAreaQuestionsExpanded = true;
          }
        }
        if (accordiongroup[i].id === 'summary-section') {
          if (accordiongroup[i].className === 'panel panel-open') {
            isAssessmentSummarySectionExpanded = true;
          }
        }
      }
    }
    return {
      isUberViewExpanded,
      isMetricTrendsExpanded,
      isBenefitsExpanded,
      isAssessmentExpanded,
      isEnhancementTrendsExpanded,
      isFocusAreasExpanded,
      isFocusAreaQuestionsExpanded,
      isAssessmentSummarySectionExpanded,
      dpoUberviewExpanded,
      prodDpoMetricTrendsExpanded,
      nonProdDpoMetricTrendExpanded,
      dpoAdditionalIndoExpanded,
    };
  }
  public dashBoardHeader(doc, x, y, headerText) {
    doc.setTextColor(64, 146, 199);
    doc.setFontSize(10);
    doc.text(x, y, headerText);
    return doc;
  }
  public headerPDF(doc, projectDetails, width, application, assessmentName, assessmentOverAllScore, dcLogo) {
    // left side of header
    const dashboardLevel = localStorage.getItem('dashboardLevel');
    doc.setFillColor(0, 51, 102);
    doc.rect(0, 0, width, 10, 'F');

    // if (application === 'Audit') {
    //     doc.setFontSize(12);
    //     doc.text(3, 7, `${localStorage.getItem('auditMonth')}-${localStorage.getItem('auditYear')}`);
    // } else
    if (application === 'Assessment') {
      doc.setFontSize(14);
      doc.setTextColor(255, 255, 255);
      doc.text(3, 7, assessmentName);
    } else if (application === 'BvA' || application === 'Audit') {
      doc.rect(0, -4, width, 12, 'F');
      doc.link(0, -4, 35, 12, { url: 'https://digitalcockpit.concentrix-catalyst.com/' });
      // doc.link(0, 7, 35, 12, { url: 'https://digitalcockpit.prokarma.com/' });
      doc.addImage(dcLogo, 'JPEG', 3, 0, 28, 12);
    } else {
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(14);
      doc.textWithLink('DIGITAL COCKPIT', 3, 7, { url: 'https://digitalcockpit.concentrix-catalyst.com/' });
    }

    // middle part of header
    doc.setTextColor(255, 255, 255);
    if (application === 'Assessment') {
      doc.setFontSize(12);
    } else if (application == 'Applicability') {
      doc.setFontSize(10);
    } else if (dashboardLevel == 'account') {
      doc.setFontSize(14);
    } else {
      doc.setFontSize(10);
    }
    if (projectDetails && projectDetails.innerText && dashboardLevel != 'account') {
      doc.text(width / 2 - 28, 6, projectDetails.innerText);
    } else if (dashboardLevel == 'account' && application != 'Audit' && application != 'Applicability') {
      doc.text(width / 2 - 22, 6, projectDetails);
    } else if (application === 'Applicability' || application === 'Audit' || application === 'Assessment' || dashboardLevel == 'program') {
      doc.setFontSize(11);
      if (projectDetails.length < 40) {
        doc.text(width / 2 - 35, 6, projectDetails);
      } else {
        var splitTitle = doc.splitTextToSize(projectDetails, 100);
        doc.text(width / 2 - 35, 4, splitTitle);
      }
    } else if (application === 'BvA') {
      doc.setFontSize(14);
      doc.text(width / 2 - 20, 6, projectDetails);
    } else {
      doc.text(width / 2 - 28, 6, projectDetails);
    }

    // right side of header
    doc.setFontSize(10);
    if (application === 'Performance Report') {
      doc.text(width / 1.5, 6, `${application} as on: ${new Date().toLocaleDateString()}`);
    }
    if (application === 'DPO' || application == 'BvA') {
      doc.text(width - 48, 6, `Report Date: ${new Date().toLocaleDateString()}`);
    } else if (application === 'Audit') {
      doc.setFontSize(12);
      doc.text(width - 35, 6, `${localStorage.getItem('auditMonth')}-${localStorage.getItem('auditYear')}`);
    } else if (application === 'Applicability') {
      doc.text(width - 48, 6, `${application} as on: ${new Date().toLocaleDateString()}`);
    } else if (application === 'Assessment') {
      doc.setFontSize(12);
      doc.text(width - 38, 6, `Overall Score: ${assessmentOverAllScore}`);
    }
    return doc;
  }
  public footerPDF(doc, pkLogo, width, height, emailId) {
    doc.setFillColor(0, 51, 102);
    doc.setTextColor(255, 255, 255);
    // doc.rect(0, 0, width, 10, 'F');
    doc.rect(0, height - 10, width, 12, 'F');
    doc.setFontSize(10);
    doc.text(2, height - 4, 'email : ' + emailId);
    doc.link(width - 32, height - 11, 35, 12, { url: 'https://catalyst.concentrix.com/' });
    doc.addImage(pkLogo, 'JPEG', width - 20, height - 11, 13, 11);
    return doc;
  }
  public setPageNo(doc, width, height) {
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(10);
    doc.text(2, height - 4, 'Page ' + doc.page); // print number bottom left
    doc.page++;
    return doc;
  }
  public pptHeader(slide) {
    const chartOpt = {
      x: 0,
      y: 0,
      w: '100%',
      h: 0.5,
      bold: true,
      fill: '1C315F',
      color: 'ffffff',
      line: { pt: '2', color: 'A9A9A9' },
    };
    const dashboardAccount = localStorage.getItem('dashboardAccount');
    const dashboardProgram = localStorage.getItem('dashboardProgram');
    const dashboardProject = localStorage.getItem('dashboardProject');
    const projectDetails = `${dashboardAccount} / ${dashboardProgram} / ${dashboardProject}`;
    slide.addText('', chartOpt);
    slide.addText('DIGITAL COCKPIT', {
      x: 0,
      y: 0.1,
      w: '20%',
      height: 0.3,
      color: 'ffffff',
      fontSize: 12,
      /* hyperlink: { url: 'https://digitalcockpit.prokarma.com/' }*/
    });
    slide.addText(projectDetails, { x: '32%', y: 0.1, color: 'ffffff', w: '40%', height: 0.1, fontSize: 12 });
    slide.addText(`Dashboard as on: ${new Date().toLocaleDateString()}`, {
      x: '77%',
      y: 0.1,
      color: 'ffffff',
      w: '25%',
      height: 0.3,
      fontSize: 12,
    });
    return slide;
  }
  public dexReportpptHeader(slide) {
    const chartOpt = {
      x: 0,
      y: 0,
      w: '100%',
      h: 0.5,
      bold: true,
      fill: '1C315F',
      color: 'ffffff',
      line: { pt: '2', color: 'A9A9A9' },
    };

    const projectDetails = `DEX Audit Report`;
    slide.addText('', chartOpt);
    slide.addText('DIGITAL COCKPIT', {
      x: 0,
      y: 0.1,
      w: '20%',
      height: 0.3,
      color: 'ffffff',
      fontSize: 18,
      /* hyperlink: { url: 'https://digitalcockpit.prokarma.com/' }*/
    });
    slide.addText(projectDetails, { x: '39%', y: 0.1, color: 'ffffff', w: '40%', height: 0.1, fontSize: 16 });
    slide.addText(`Report as on: ${new Date().toLocaleDateString()}`, {
      x: '87%',
      y: 0.1,
      color: 'ffffff',
      w: '25%',
      height: 0.3,
      fontSize: 12,
    });
    return slide;
  }
  public pptFooter(slide, pkLogo) {
    const chartOpt = {
      x: '0%',
      y: '95%',
      w: '100%',
      h: 0.5,
      fill: '1C315F',
      line: { pt: '2', color: 'A9A9A9' },
    };
    slide.addText('', chartOpt);
    slide.addImage({ data: pkLogo.src, x: '92%', y: '95%', w: 0.8, h: 0.5, hyperlink: { url: 'https://prokarma.com/' } });
    return slide;
  }
  public dateFormatter(date) {
    const d = new Date(date);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = monthNames[d.getMonth()];
    return `${month} ${d.getDate()}, ${d.getFullYear()}`;
  }
  public metricTrendWidgetsPdf(route, doc, projectDetails, width, height, pkLogo, base64, index, metricslength, isSupportProject, name) {
    const img2 = new Image();
    img2.src = base64;
    if (name === null || (route.includes('viewAccountDashboard') && name != null)) {
      this.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
    } else {
      this.headerPDF(doc, projectDetails, width, 'DPO', '', '', null);
    }
    const dashboardLevel = localStorage.getItem('dashboardLevel');
    if (dashboardLevel == 'account') {
      this.dashBoardHeader(doc, 10, 20, 'ACCOUNT LEVEL METRICS');
    } else if (dashboardLevel == 'program') {
      this.dashBoardHeader(doc, 10, 20, 'PROGRAM LEVEL METRICS');
    } else if (name !== null) {
      this.dashBoardHeader(doc, 10, 20, name);
    } else {
      this.dashBoardHeader(doc, 10, 20, 'METRIC TRENDS');
    }
    this.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
    if (!isSupportProject && !route.includes('viewAccountDashboard') && name === null) {
      // if (index % 2 === 0) {
      //     doc.addImage(img2, 'JPEG', 15, 25, 0, 0, '', 'FAST');
      // } else {
      //     doc.addImage(img2, 'JPEG', 15, height / 2, 0, 0, '', 'FAST');
      //     if (index !== metricslength - 1) {
      //         doc.addPage();
      //     }
      // }
      if (index === 0 || index === 12 || index === 24 || index === 36) {
        doc.addImage(img2, 'JPEG', 2, 27, 67, 0, '', 'FAST');
      } else if (index === 1 || index === 13 || index === 25 || index === 37) {
        doc.addImage(img2, 'JPEG', 71.5, 27, 67, 0, '', 'FAST');
      } else if (index === 2 || index === 14 || index === 26 || index === 38) {
        doc.addImage(img2, 'JPEG', 141, 27, 67, 0, '', 'FAST');
      } else if (index === 3 || index === 15 || index === 27 || index === 39) {
        doc.addImage(img2, 'JPEG', 2, height / 3.4, 67, 0, '', 'FAST');
      } else if (index == 4 || index === 16 || index === 28 || index === 40) {
        doc.addImage(img2, 'JPEG', 71.5, height / 3.4, 67, 0, '', 'FAST');
      } else if (index == 5 || index === 17 || index === 29 || index === 41) {
        doc.addImage(img2, 'JPEG', 141, height / 3.4, 67, 0, '', 'FAST');
      } else if (index === 6 || index === 18 || index === 30 || index === 42) {
        doc.addImage(img2, 'JPEG', 2, height / 2, 67, 0, '', 'FAST');
      } else if (index == 7 || index === 19 || index === 31 || index === 43) {
        doc.addImage(img2, 'JPEG', 71.5, height / 2, 67, 0, '', 'FAST');
      } else if (index == 8 || index === 20 || index === 32 || index === 44) {
        doc.addImage(img2, 'JPEG', 141, height / 2, 67, 0, '', 'FAST');
      } else if (index === 9 || index === 21 || index === 33 || index === 45) {
        doc.addImage(img2, 'JPEG', 2, height / 1.42, 67, 0, '', 'FAST');
      } else if (index == 10 || index === 22 || index === 34 || index === 46) {
        doc.addImage(img2, 'JPEG', 71.5, height / 1.42, 67, 0, '', 'FAST');
      } else if ((index == 11 || index === 23 || index === 35 || index === 47) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 141, height / 1.42, 67, 0, '', 'FAST');
        if (index !== metricslength - 1) {
          // this.setPageNo(doc, width, height);
          doc.addPage();
        }
      }
    } else if (name !== null && !route.includes('viewAccountDashboard') && index != metricslength) {
      if ((index === 0 || index === 12 || index === 24 || index === 36) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 2, 27, 67, 0, '', 'FAST');
      } else if ((index === 1 || index === 13 || index === 25 || index === 37) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 71.5, 27, 67, 0, '', 'FAST');
      } else if ((index === 2 || index === 14 || index === 26 || index === 38) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 141, 27, 67, 0, '', 'FAST');
      } else if ((index === 3 || index === 15 || index === 27 || index === 39) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 2, height / 3.4, 67, 0, '', 'FAST');
      } else if ((index == 4 || index === 16 || index === 28 || index === 40) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 71.5, height / 3.4, 67, 0, '', 'FAST');
      } else if ((index == 5 || index === 17 || index === 29 || index === 41) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 141, height / 3.4, 67, 0, '', 'FAST');
      } else if ((index === 6 || index === 18 || index === 30 || index === 42) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 2, height / 2, 67, 0, '', 'FAST');
      } else if ((index == 7 || index === 19 || index === 31 || index === 43) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 71.5, height / 2, 67, 0, '', 'FAST');
      } else if ((index == 8 || index === 20 || index === 32 || index === 44) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 141, height / 2, 67, 0, '', 'FAST');
      } else if ((index === 9 || index === 21 || index === 33 || index === 45) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 2, height / 1.42, 67, 0, '', 'FAST');
      } else if ((index == 10 || index === 22 || index === 34 || index === 46) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 71.5, height / 1.42, 67, 0, '', 'FAST');
      } else if ((index == 11 || index === 23 || index === 35 || index === 47) && index != metricslength - 1) {
        doc.addImage(img2, 'JPEG', 141, height / 1.42, 67, 0, '', 'FAST');
        if (index !== metricslength - 1) {
          // this.setPageNo(doc, width, height);
          doc.addPage();
        }
      } else if (index == metricslength - 1 && name == 'PRODUCTION METRIC TRENDS') {
        doc.addImage(img2, 'JPEG', 2, height / 2 - 55, 206.5, 80, '', 'FAST');
      } else if (index == metricslength - 1 && name == 'NON-PRODUCTION METRIC TRENDS') {
        doc.addImage(img2, 'JPEG', 141, height / 1.42, 67, 0, '', 'FAST');
      }
    } else if (route.includes('viewAccountDashboard')) {
      if (index % 3 == 0) {
        doc.addImage(img2, 'JPEG', 10, 25, 235, 0, '', 'FAST');
      }
      else if ((index - 1) % 3 == 0) {
        if (name != null && name?.title == 'OVERDUE SPRINT DATA UPLOAD') {
          doc.addImage(img2, 'JPEG', 10, 98, 235, 0, '', 'FAST');
        }
        else {
          doc.addImage(img2, 'JPEG', 10, 120, 235, 0, '', 'FAST');
        }
      }
      else if ((index - 2) % 3 == 0) {
        if (name != null && name?.title == 'OVERDUE SPRINT DATA UPLOAD') {
          doc.addImage(img2, 'JPEG', 10, 193, 235, 0, '', 'FAST');
        }
        else {
          doc.addImage(img2, 'JPEG', 10, 205, 235, 0, '', 'FAST');
        }
        this.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
        if (index !== metricslength) {
          doc.addPage();
        }
      }
    } else if (isSupportProject) {
      // for support projects
      if (index < 3) {
        doc.addImage(img2, 'JPEG', 5, 25, width, 0, '', 'FAST');
      } else {
        doc.addImage(img2, 'JPEG', 15, 25, 0, 0, '', 'FAST');
      }
      if (index !== metricslength - 1) {
        doc.addPage();
      }
    }
  }

  public assessmentWidgets(doc, projectDetails, width, height, pkLogo, base64, index, metricslength, isExapandedGraph) {
    const img = new Image();
    img.src = base64;
    this.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
    this.dashBoardHeader(doc, 10, 20, 'ASSESSMENTS');
    let x, imgWidth;
    if (isExapandedGraph) {
      x = 10;
      imgWidth = width - 10;
      doc.addImage(img, 'JPEG', 10, 25, width - 10, 0, '', 'FAST');
    } else {
      x = 15;
      imgWidth = 0;
    }
    if (index % 2 === 0) {
      doc.addImage(img, 'JPEG', x, 25, imgWidth, 0, '', 'FAST');
    } else {
      doc.addImage(img, 'JPEG', x, height / 2, imgWidth, 0, '', 'FAST');
      if (index !== metricslength - 1) {
        doc.addPage();
      }
    }
    this.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
  }
  public enhancedMetricTrendWidgets(doc, projectDetails, width, height, pkLogo, base64, index, metricslength) {
    const img = new Image();
    img.src = base64;
    this.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
    this.dashBoardHeader(doc, 10, 20, 'ENHANCEMENT TRENDS');
    this.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
    if (index < 2) {
      doc.addImage(img, 'JPEG', 5, 25, width - 10, 0, '', 'FAST');
    } else {
      doc.addImage(img, 'JPEG', 15, 25, 0, 0, '', 'FAST');
    }
    if (index !== metricslength - 1) {
      doc.addPage();
    }
  }
  public generateLineChart(pptx, slide, title, metrics, pkLogoImage, chartColor) {
    const LineLable = [];
    const LineValues = [];
    const lastFiveMetrics = metrics.slice(metrics.length - 5, metrics.length);
    lastFiveMetrics.map((item) => {
      LineLable.push(item.iterationName);
      if (title === 'PRODUCTIVITY') {
        LineValues.push(item.productivity);
      }
      if (title === 'DEFECT TRENDS') {
        LineValues.push(item.totalDefectsCount);
      }
      if (title === 'CYCLE TIME') {
        LineValues.push(item.cycleTime);
      }
      if (title === 'SPRINT VS UNIT TEST COVERAGE') {
        LineValues.push(item.unitTestCoverage);
      }
      if (title === 'SPRINT VS STATIC CODE ANALYSIS') {
        LineValues.push(item.staticCodeAnalysys);
      }
    });
    const LineChartData = [
      {
        name: title,
        labels: LineLable,
        values: LineValues,
      },
    ];

    const lineChartOpt = { x: 1, y: 1, w: 8, h: 4, lineSmooth: false, chartColors: [chartColor], showValue: false };
    this.pptHeader(slide);
    slide.addText(title, { x: 0, y: 0.5, color: '4092c7', fontSize: 12 });
    slide.addChart(pptx.charts.LINE, LineChartData, lineChartOpt);
    this.pptFooter(slide, pkLogoImage);
    return slide;
  }
  public dexHeader(url) {
    let dexHeader = [
      { content: 'Delivery Controls', styles: { fillColor: [199, 236, 243] } },
      { content: 'Applicability', styles: { fillColor: [199, 236, 243] } },
    ];

    if (url === '/governanceProcess') {
      dexHeader.push({ content: 'Comments', styles: { fillColor: [199, 236, 243] } });
    } else if (url === '/governanceProcess/auditform') {
      dexHeader.push({ content: 'Delivery Conformance', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'Comments', styles: { fillColor: [199, 236, 243] } });
    } else if (url === 'BvA') {
      dexHeader = [];
      dexHeader.push({ content: 'PROJECT NAME', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'PROJECT OWNER', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'INTACCT ID', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'START DATE', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'ESTIMATED END DATE', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'DURATION (WEEKS)', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'BUDGETED HOURS', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'ACTUAL HOURS', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'VARIANCE', styles: { fillColor: [199, 236, 243] } });
    } else if (url === 'Asset') {
      dexHeader = [];
      dexHeader.push({ content: 'SNO', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'ACCOUNT', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'PROGRAM', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'PROJECT', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'ASSET', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'TYPE', styles: { fillColor: [199, 236, 243] } });
      dexHeader.push({ content: 'PROVIDER', styles: { fillColor: [199, 236, 243] } });
    }
    return dexHeader;
  }
  public metricTrendWidgetsStatus(metricTrends) {
    if (metricTrends && metricTrends.metrics) {
      this.metricsToReport.summaryDetails = metricTrends.metrics.find((f) => f.m_id === 1).isEnabled;
      this.metricsToReport.velocity = metricTrends.metrics.find((f) => f.m_id === 2).isEnabled;
      this.metricsToReport.productivity = metricTrends.metrics.find((f) => f.m_id === 3).isEnabled;
      this.metricsToReport.defectTrends = metricTrends.metrics.find((f) => f.m_id === 4).isEnabled;
      this.metricsToReport.cycleTime = metricTrends.metrics.find((f) => f.m_id === 5).isEnabled;
      this.metricsToReport.unitTestCoverage = metricTrends.metrics.find((f) => f.m_id === 6).isEnabled;
      this.metricsToReport.staticCodeAnalysis = metricTrends.metrics.find((f) => f.m_id === 7).isEnabled;
      this.metricsToReport.defectsClassification = metricTrends.metrics.find((f) => f.m_id === 8).isEnabled;
    }
    return this.metricsToReport;
  }
  public addRemoveClass(option) {
    const ele = document.getElementsByClassName('filter-button-sla');
    if (ele && ele.length) {
      if (option === 'remove') {
        for (let i = 0; i < ele.length; i++) {
          ele[i].classList.remove('filter-button-sla2');
        }
      }
      if (option === 'add') {
        for (let i = 0; i < ele.length; i++) {
          ele[i].classList.add('filter-button-sla2');
        }
      }
    }
  }
  public assessmentFocusAreaQuestions(
    doc,
    width,
    height,
    base64,
    index,
    assQuesLength,
    pkLogo,
    projectDetails,
    assessmentName,
    assessmentOverAllScore
  ) {
    const img = new Image();
    img.src = base64;
    this.headerPDF(doc, projectDetails, width, 'Assessment', assessmentName, assessmentOverAllScore, null);
    this.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
    // doc.addImage(img, 'JPEG', 5, 30, width - 10, 0, '', 'FAST');
    // if (index !== assQuesLength - 1) {
    //     doc.addPage();
    // }
    if (index % 2 === 0) {
      doc.addImage(img, 'JPEG', 5, 5, width - 10, height / 2 - 10, '', 'FAST');
    } else {
      doc.addImage(img, 'JPEG', 5, height / 2 - 8, width - 10, height / 2 - 10, '', 'FAST');
      if (index !== assQuesLength - 1) {
        doc.addPage();
      }
    }
  }
  // public convertToCSV(objArray) {
  //     const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  //     let str = '';

  //     for (let i = 0; i < array.length; i++) {
  //         let line = '';
  //         for (const index in array[i]) {
  //             if (array[i].hasOwnProperty(index)) {
  //                 if (line !== '') {
  //                     line += ',';
  //                 }
  //                 line += array[i][index];
  //             }
  //         }
  //         str += line + '\r\n';
  //     }
  //     return str;
  // }
  // public exportCSVFile(headers, items, fileTitle) {
  //     if (headers) {
  //         items.unshift(headers);
  //     }
  //     // Convert Object to JSON
  //     const jsonObject = JSON.stringify(items);
  //     const csv = this.convertToCSV(jsonObject);
  //     const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
  //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //     if (navigator.msSaveBlob) { // IE 10+
  //         navigator.msSaveBlob(blob, exportedFilenmae);
  //     } else {
  //         const link = document.createElement('a');
  //         if (link.download !== undefined) {
  //             const url = URL.createObjectURL(blob);
  //             link.setAttribute('href', url);
  //             link.setAttribute('download', exportedFilenmae);
  //             link.style.visibility = 'hidden';
  //             document.body.appendChild(link);
  //             link.click();
  //             document.body.removeChild(link);
  //         }
  //     }
  // }
  // public dexExcell() {
  //     const audit = JSON.parse(localStorage.getItem('auditDexFormValues'));
  //     if (audit) {
  //         const headers = {
  //             deliveryControls: 'Delivery Controls'.replace(/,/g, ''), // remove commas to avoid errors
  //             applicability: 'Applicability',
  //             deliveryConformance: 'Delivery Conformance',
  //             comments: 'Comments'
  //         };

  //         const itemsNotFormatted = [];
  //         if (audit) {
  //             audit.map(item => {
  //                 let obj = {};
  //                 obj['deliveryControls'] = item.name;
  //                 itemsNotFormatted.push(obj);
  //                 obj = {};
  //                 item.controls.map(cnt => {
  //                     obj['deliveryControls'] = cnt.name;
  //                     obj['applicability'] = cnt.applicablity;
  //                     obj['deliveryConformance'] = cnt.conformance;
  //                     obj['comments'] = cnt.auditComment;
  //                     itemsNotFormatted.push(obj);
  //                     obj = {};
  //                 });
  //             });
  //         }
  //         let itemsFormatted = [];
  //         // format the data
  //         itemsNotFormatted.forEach((item) => {
  //             itemsFormatted.push({
  //                 deliveryControls: item.deliveryControls.replace(/,/g, ''), // remove commas to avoid errors,
  //                 applicability: item.applicability,
  //                 deliveryConformance: item.deliveryConformance,
  //                 comments: item.comments
  //             });
  //         });
  //         const fileTitle = 'Applicability';
  //         this.exportCSVFile(headers, itemsFormatted, fileTitle);
  //     }
  // }
}
