import { Component, OnInit } from '@angular/core';
import { snackBarAnimation } from '../../animations/snackbar.animation';
import { CommonService } from '../../services/common.service';

export interface SnackBarOptions {
  message: string;
  timoutInMilliSeconds: number;
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  animations: [snackBarAnimation],
})
export class SnackBarComponent implements OnInit {
  snackBarVisible: boolean;
  snackBarMessage: string;
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.commonService.snackBarState.subscribe((options: SnackBarOptions) => this.openSnackBar(options));
  }

  openSnackBar(options: SnackBarOptions): void {
    const self = this;
    this.snackBarMessage = options.message;
    this.snackBarVisible = true;
    setTimeout(() => {
      self.snackBarVisible = false;
    }, options.timoutInMilliSeconds || 3000);
  }
}
