import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';

@Injectable({
  providedIn: 'root'
})
export class AdalConfigService {
  constructor(private adalService: AdalService) {}

  ADAL_CONFIG = {
    upn: this.adalService.userInfo.profile.upn,
    name: this.adalService.userInfo.profile.name,
  };

}

