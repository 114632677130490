import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AlertPopUp } from './shared/components/alert-popup/alert-popup.model';
import { LoaderState } from './shared/models/loader.interface';
import { AnalyticsService } from './shared/services/analytics.service';
import { CommonService } from './shared/services/common.service';

const TITLE_MAP = {
  '/': 'Digital Cockpit - Home',
  '/home': 'Digital Cockpit - Home',
  '/home/dashboard': 'Digital Cockpit - Dashboard',
  '/home/bvadashboard': 'Digital Cockpit - BVA',
  '/home/pftdashboard': 'Digital Cockpit - PFT',
  '/home/assetReport': 'Digital Cockpit - Assets Report',
  '/home/gdReport': 'Digital Cockpit - Global Delivery Report',
  '/addproject': 'Digital Cockpit - Add Project',
  '/editproject': 'Digital Cockpit - Edit Project',
  '/additeration': 'Digital Cockpit - Add Sprint',
  '/edititeration': 'Digital Cockpit - Edit Spint',
  '/addincident': 'Digital Cockpit - Add ITSM',
  '/home/bvaupload': 'Digital Cockpit - Time Sheet Upload',
  '/governanceProcess/auditform': 'Digital Cockpit - Audit',
  '/governanceProcess': 'Digital Cockpit - Applicability',
  '/governanceProcess/NcManagement': 'Digital Cockpit - NC Management',
  '/governanceProcess/dexAuditReport': 'Digital Cockpit - DEx Audit Report',
  '/agileMaturity': 'Digital Cockpit - Agile Maturity',
  '/help': 'Digital Cockpit - Help',
  '/settings/roles': 'Digital Cockpit - Roles',
  '/settings/users': 'Digital Cockpit - Users',
  '/settings/accounts': 'Digital Cockpit - Accounts',
  '/settings/programs': 'Digital Cockpit - Programs',
  '/settings/manage-controls': 'Digital Cockpit - Manage Controls',
  '/settings/project-management': 'Digital Cockpit - Project Management',
  '/settings/notifications': 'Digital Cockpit - Notifications',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showLoader = false;
  alertPopConfig: AlertPopUp;
  isPopupOpen = false;

  constructor(private commonService: CommonService, private changeDetectorRef: ChangeDetectorRef, private router: Router, private title: Title,private readonly analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.commonService.loaderState.subscribe((state: LoaderState) => {
      this.showLoader = state.show;
    });
    if (environment.envName == 'prod' && environment.production) {
      this.analyticsService.init();
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (window as any).ga('set', 'page', event.urlAfterRedirects);
        (window as any).ga('send', 'pageview');
      }
    });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
    this.isPopupOpen = localStorage.getItem('expand') === 'expanded' ? true : false;
  }

  listenToAlertPopUpState(): void {
    this.commonService.alertPopUpState.subscribe((config: AlertPopUp) => {
      this.alertPopConfig = config;
    });
  }

  listenToRouterEvents(): void {
    this.router.events.subscribe((event: Event) => {
      this.title.setTitle(this.getTitle());
      this.pageNavigationLoader(event);
    });
  }

  pageNavigationLoader(event: Event): void {
    if (event instanceof NavigationStart) {
      this.showLoader = true;
    } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.showLoader = false;
    }
  }

  getTitle(): string {
    return TITLE_MAP[this.router.url] || 'Digital Cockpit ' + this.router.url;
  }

  resetAlertPop(): void {
    this.alertPopConfig = null;
  }
}
