<div class="header">
  <div class="col-md-3 headerLeftPanel">
    <div class="sideImage" (click)="pklogoClick()">
      <img style="margin-top: -5px;height:42px" src="../../../assets/images/Concentrix-catalyst_reverse.svg"
        alt="concentrix catalyst" />
    </div>
    <div class="line"></div>
    <div class="sideImage dcLogo">
      <a (click)="logoClick()"> <img class="header-image" src="../../../assets/images/header_dc-logo.png"
          alt="DC-logo" /></a>
    </div>
  </div>
  <div class="col-md-5 headerMiddlePanel" *ngIf="userMetaData">
    <div class="">
      <button class="menuBtn dropdown-toggle" type="button"
        *ngIf="!(userMetaData?.roleName == 'AMAAdmin' || userMetaData?.roleName == 'BvA User' || userMetaData?.roleName == 'PFT User')">
        <span routerLink="/" class="ptop pside" routerLinkActive="router-link-active">Home</span>
      </button>
    </div>
    <div class="dropdown">
      <button class="menuBtn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span class="ptop" routerLinkActive="router-link-active">Reports</span>
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <ul>
          <li class="first" *ngIf="userMetaData" routerLink="/home/gdReport">
            <a class="dropdown-item">Global Delivery Report</a>
          </li>
          <li class="first"
            *ngIf="userMetaData?.bvaReports && (userMetaData?.roleName == 'PFT User' || userMetaData?.roleName == 'Administrator' || userMetaData?.roleName == 'AccountAdmin' || userMetaData?.roleName == 'GlobalViewOnly')"
            routerLink="/home/pftdashboard">
            <a class="dropdown-item">Project Financial Tracker</a>
          </li>
          <li class="first" routerLink="/home/projectPerformanceReport">
            <a class="dropdown-item"
              *ngIf="!(userMetaData?.roleName == 'BvA User' || userMetaData?.roleName == 'PFT User')">Projects
              Performance Report</a>
          </li>
          <li class="first"
            *ngIf="userMetaData?.bvaReports && !(userMetaData?.roleName == 'PFT User' || userMetaData?.roleName == 'Administrator'  || userMetaData?.roleName == 'AccountAdmin' || userMetaData?.roleName == 'GlobalViewOnly') "
            routerLink="/home/bvadashboard">
            <a class="dropdown-item">BvA Report</a>
          </li>
          <li class="first" *ngIf="userMetaData" routerLink="/home/assetReport">
            <a class="dropdown-item">Assets Report</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="dropdown" *ngIf="
        userMetaData?.addProject ||
        userMetaData?.editproject ||
        userMetaData?.addIteration ||
        userMetaData?.loadITSM ||
        userMetaData?.editIteration ||
        userMetaData?.loadTimesheetData
      ">
      <button class="menuBtn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <!-- <span>
					<img class="dropDown-Image" src="../../../assets/images/headerMenu.png">
				</span> -->
        <span class="ptop" routerLinkActive="router-link-active">Projects</span>
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <ul>
          <li class="first" *ngIf="userMetaData?.addProject" routerLink="/addproject">
            <a class="dropdown-item">Add Project</a>
          </li>

          <li *ngIf="userMetaData?.editproject" routerLink="/editproject">
            <a class="dropdown-item">Edit project</a>
          </li>

          <li *ngIf="userMetaData?.addIteration" routerLink="/additeration">
            <a class="dropdown-item">Add Sprint Details</a>
          </li>

          <li *ngIf="userMetaData?.editIteration" routerLink="/edititeration">
            <a class="dropdown-item">Edit Sprint Details</a>
          </li>

          <li *ngIf="userMetaData?.loadITSM" routerLink="/addincident">
            <a class="dropdown-item">Load ITSM Data</a>
          </li>

          <li *ngIf="userMetaData?.loadTimesheetData" routerLink="/home/bvaupload">
            <a class="dropdown-item">Load Timesheet Data</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="dropdown" *ngIf="
        userMetaData?.applicablity ||
        userMetaData?.audit ||
        userMetaData?.manageAMALink ||
        userMetaData?.ncManagement ||
        userMetaData?.dexAuditReport
      ">
      <button class="menuBtn dropdown-toggle test" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span class="ptop" routerLinkActive="router-link-active">Compliance</span>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <ul>
          <li class="first" *ngIf="userMetaData?.applicablity" routerLink="/governanceProcess">
            <a class="dropdown-item">DEx Control Applicability </a>
          </li>
          <li *ngIf="userMetaData?.audit" routerLink="/governanceProcess/auditform">
            <a class="dropdown-item">DEx Compliance Audit</a>
          </li>
          <li *ngIf="userMetaData?.ncManagement" routerLink="/governanceProcess/NcManagement">
            <a class="dropdown-item">DEx Non Compliance</a>
          </li>
          <li *ngIf="userMetaData?.dexAuditReport" routerLink="/governanceProcess/dexAuditReport">
            <a class="dropdown-item">DEx Audit Report</a>
          </li>
          <li *ngIf="userMetaData?.manageAMALink" routerLink="/agileMaturity">
            <a class="dropdown-item">Agile Maturity Assessment</a>
          </li>
        </ul>
      </div>
    </div>

  </div>

  <div class="col-md-4 headerRightPanel" *ngIf="userMetaData">
    <div class="lastSection">
      <div>
        <img class="exportpdfHelp" routerLink="/settings" src="../../../assets/images/header_settings.svg"
          *ngIf="userMetaData?.settings" />
      </div>
      <div class="line2"></div>
      <div>
        <a target="_blank"
          href="https://cnxmail.sharepoint.com/sites/DeliveryExcellence2/SitePages/Digital-Cockpit-Help.aspx">
          <!-- <img class="exportpdfHelp" src="../../../assets/images/header_help.svg" *ngIf="userMetaData?.helpPage" /> -->
          <h6 class="exportpdfHelp helptext" *ngIf="userMetaData?.helpPage">Help</h6>
        </a>
      </div>
      <div class="line2"></div>
      <div>
        <div class="usercard">
          <div class="userImgCntr">
            <img src="../../../assets/images/user_img.png" alt="img" />
            <!-- <img  [src]='profilePic' alt="img" /> -->
          </div>
          <div class="userDetails" style="padding-top: 13px;" *ngIf="!userMetaData?.isSuperAdmin">
            <span class="userName userNameText">{{ userName }}</span>
            <!-- <sup class="userTitle">Project Manager</sup>   need to disaplay designation of logged in person -->
            <!-- <div class="userActions">
						<a (click)="logout();">Logout</a>
					  </div> -->
          </div>
          <div class="userDetails" *ngIf="userMetaData?.isSuperAdmin">
            <span class="userNameText">{{ userName }}</span>
            <div class="userLogout">
              <span>Switch back</span>
              <img class="switchBackUser" alt="Switch User back" title="Switch User back"
                src="../../../assets/images/switch-user-back.svg" (click)="swicthBackToAdmin()" />
            </div>
          </div>
          <div class="line2"></div>
          <div class="logout">
            <img title="Logout" alt="Logout" src="../../../assets/images/logout.svg" alt="img" (click)="logout();" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<alert-popup [alertFlag]="alertFlag" [alertType]="alertType" [alertTitle]="alertTitle" [alertSubTitle]="alertSubTitle"
  [alertErrorContent]="alertErrorContent" [isSuperAdmin]="isSuperAdmin" [userRoleName]="userMetaData.roleName"
  [closeIconStatus]="closeIconStatus" (closeError)="onErrorClose()" (noDataOkBtnFun)="noDataOkBtnFun()">
</alert-popup>