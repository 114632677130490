import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isFooterDisable;
  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      if (this.router.url.includes('projectPerformanceReport') || this.router.url.includes('viewAccountDashboard')) {
        this.isFooterDisable = false;
      } else {
        this.isFooterDisable = true;
      }
    });
  }

  ngOnInit() {}
}
