
import { NgModule } from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatIconModule } from "@angular/material/icon";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatPaginatorModule } from '@angular/material/paginator';
import { CdkTableModule } from '@angular/cdk/table';
import { MatButtonModule } from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatNativeDateModule} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    MatTooltipModule,
    MatIconModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    CdkTableModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatInputModule
  ],
  exports: [
    MatTooltipModule,
    MatIconModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    CdkTableModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatInputModule
  ],
  declarations: [
  ],
  providers: [],
})
export class MaterialModule {}
