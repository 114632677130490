import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG } from '../../../../api.config';
import { UserAccessService } from '../../../../shared/services/user-access.service';

@Injectable()
export class AssetsService {
  constructor(public http: HttpClient, private userAccessService: UserAccessService) {}
  getUserMetaData() {
    return this.userAccessService.getUserMetaData();
  }
  getAssetsList(data) {
    const url = API_CONFIG.getAssetsList;
    return this.http.post(url, data);
  }
}
