import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { UserAccessService } from './shared/services/user-access.service';
import { HttpClient } from '@angular/common/http';

export function initializeApp(adalService: AdalService, userAccessService: UserAccessService, httpClient: HttpClient): any {
  return () => {
    const config = {
      instance: 'https://adfs.concentrix.com/',
      tenant: 'adfs',
      clientId: '',
      postLogoutRedirectUri: 'https://catalyst.concentrix.com/',
      expireOffsetSeconds: -86400,
      loadFrameTimeout: 120,
      extraQueryParameter: 'scope=openid'
    };
    if (environment.envName == 'prod' && environment.production) {
      config['clientId'] = 'cabd81ce-2536-4b46-a345-f30dc4fed3c9';
    }
    else {
      config['clientId'] = '59d07477-8f9a-4024-ac8c-428ea8eedd74';
    }
    return new Promise((resolve, reject) => {
      adalService.init(config);
      adalService.handleWindowCallback();
      userAccessService.setTokenRenewalTimer();
      if (adalService?.userInfo?.authenticated) {
        let lastLoginTime = new Date();
        localStorage.setItem('loginTime', lastLoginTime.toISOString());
        userAccessService.setCustomisedName(adalService.userInfo.profile.upn);
        userAccessService
          .getUserMetaData()
          .pipe(
            mergeMap((userMetaData) => {
              if (userMetaData) {
                resolve(true);
                let userStatus = userMetaData.userStatus
                if (userStatus === 'Suspended' || userStatus === 'Terminated') {
                  return this.userStatusUpadte = userStatus
                } else {
                  this.userStatusUpadte = 'Active'

                }
                return userAccessService.getLastLoginDate({
                  emailId: adalService.userInfo.profile.upn,
                  lastLoginDate: lastLoginTime,
                  userStatus: this.userStatusUpadte
                }).pipe(
                  mergeMap((lastLoginResponse) => {
                    // Process the response if needed
                    console.log('Last login date response:', lastLoginResponse);
                    return of(true);
                  })
                );
              }
              return userAccessService.signUpUser({
                name: this.adalConfigService.ADAL_CONFIG.name,
                emailId: adalService.userInfo.profile.upn,
              });
            }),
            catchError((err) => {
              resolve(true);
              return userAccessService.signUpUser({
                name: this.adalConfigService.ADAL_CONFIG.name,
                emailId: adalService.userInfo.profile.upn,
              });
            })
          )
          .subscribe();
      } else {
        adalService.login();
        reject('Login session not found');
      }
    });
  };
}

@NgModule({
  declarations: [],
  imports: [],
})
export class AppInitializerModule {
  static forRoot(): ModuleWithProviders<AppInitializerModule> {
    return {
      ngModule: AppInitializerModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initializeApp,
          deps: [AdalService, UserAccessService],
          multi: true,
        },
      ],
    };
  }
}
