import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Observable } from 'rxjs';
import { UserMetaData } from '../shared/models/userMetaData.model';
import { UserAccessService } from '../shared/services/user-access.service';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate {
  constructor(private adalService: AdalService, private userAccessService: UserAccessService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.adalService.userInfo.authenticated) {
      this.adalService.login();
      return false;
    }
    return true;
  }
}
