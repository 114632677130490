import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserAccessService } from '../../../shared/services/user-access.service';

@Injectable({
  providedIn: 'root',
})
export class ExportPdfserviceService {
  // private contentData = new BehaviorSubject<any>('test');
  // currentMessage = this.contentData.asObservable();
  constructor(public http: HttpClient, private userAccessService: UserAccessService) {}
  currentMessage = [];
  mapData = [];
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  dashboard;
  exportPDF() {
    const data = 'pdf';
    this.invokeFirstComponentFunction.emit(data);
  }
  exportPpt() {
    const data = 'ppt';
    this.invokeFirstComponentFunction.emit(data);
  }
  changeMessage(contentData: any) {
    this.currentMessage.push(contentData);
  }
  sendMessage() {
    return this.currentMessage;
  }

  sendMappingData(a, b, c, d, e) {
    this.mapData = [];
    this.mapData.push(a);
    this.mapData.push(b);
    this.mapData.push(c);
    this.mapData.push(d);
    this.mapData.push(e);
  }
  recieveMappingData() {
    return this.mapData;
  }
  recieveDashboardData(dashboard) {
    this.dashboard = dashboard;
  }
  sendDashboardData() {
    return this.dashboard;
  }
  getUserMetaData() {
    return this.userAccessService.getUserMetaData();
  }
}
