import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import * as domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import pptxgen from "pptxgenjs";
import { DashboardService } from '../../../modules/dashboard/_services/dashboard.service';
import { ExportPdfserviceService } from '../../../modules/dashboard/_services/export-pdfservice.service';
import { SettingsService } from '../../../modules/settings/_services/settings.serive';
import { AdalConfigService } from '../../services/adal.config.service';
import { CommonService } from '../../services/common.service';
import { UserAccessService } from '../../services/user-access.service';
import { PdfPptHelper } from './pdf-ppt-helper';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public headerTitle: String = 'Page Header';
  public userName: String = '';
  public contentData = [];
  public overdueSprintWidget: any = [];
  public userMetaData;
  public isExportdisable: Boolean = true;
  public isPptExportDisable: Boolean = true;
  public alertFlag: Boolean;
  public alertType: String;
  public alertTitle: String;
  public alertSubTitle: string;
  public alertErrorContent: String;
  public dashboard;
  public metrics;
  public dexMetrics;
  public userList;
  public isSuperAdmin;
  public switchUserEmailId;
  public adminUserId;
  public bvaDropDown = "BvA Report";
  public userStatus;
  public closeIconStatus: boolean;

  public metricTrendsWidgetsStatus = {
    summaryDetails: true,
    velocity: true,
    productivity: true,
    defectTrends: true,
    unitTestCoverage: true,
    staticCodeAnalysis: true,
    defectsClassification: true,
    cycleTime: true,
  };

  constructor(
    private service: AdalService,
    private adalConfigService: AdalConfigService,
    private exportPdfService: ExportPdfserviceService,
    private commonService: CommonService,
    private router: Router,
    private userAccessService: UserAccessService,
    private settingsService: SettingsService,
    private dashboardService: DashboardService,
    private pdfPptHelper: PdfPptHelper
  ) { }

  ngOnInit() {
    // Handle callback if this is a redirect from Azure
    this.exportPdfService.getUserMetaData().subscribe(
      (response: any) => {
        this.userMetaData = response;
        let userStatus = response.userStatus
        let superAdmin = {
          isSuperAdmin: this.userMetaData.isSuperAdmin
        };
        this.isSuperAdmin = this.userMetaData.isSuperAdmin;
        this.switchUserEmailId = this.userMetaData.switchUserEmailId;
        localStorage.setItem('userMetaDataRoleName', this.userMetaData.roleName);
        localStorage.setItem('userMetaDataRoleId', this.userMetaData.roleId);
        localStorage.setItem('isSuperAdmin', JSON.stringify(superAdmin));
        localStorage.setItem('userMetaDataRoleEmailId', this.service.userInfo.profile.upn);
        let account = this.userMetaData.accounts;
        if (this.userMetaData.roleName == 'PFT User' || this.userMetaData.roleName == 'Administrator') {
          this.bvaDropDown = "PTF Report"
        }
        let projectAccess = this.checkProjectAccess(account);
        if (projectAccess != true) {
          this.alertFlag = true;
          this.alertType = 'dashboardAccessError';
          this.alertSubTitle = "No 'Active' Account/Program/Project assigned to you.";
          this.alertErrorContent = 'Please reach out to <a href=\"mailto:Ctlyst_DC_HelpDesk@concentrix.com\"> Ctlyst_DC_HelpDesk@concentrix.com </a> along with the Client/Project details.';
        }
        if (userStatus == 'Suspended' || userStatus == 'Terminated') {
          this.alertFlag = true;
          this.alertType = 'dashboardAccessError';
          this.alertSubTitle = "Your Account is SUSPENDED due to Inactivity";
          this.alertErrorContent = 'Please reach out to <a href=\"mailto:Ctlyst_DC_HelpDesk@concentrix.com\"> Ctlyst_DC_HelpDesk@concentrix.com </a> to enable your account in Digital Cockpit';
        }
      },
      (error) => {
        const noAccountMsg = "You don’t have a User Account in DC.",
          noRoleMsg = "Your user account has <b>No Role</b> assigned.";
        this.userMetaData = [];
        this.userMetaData['roleName'] = null;
        if (error['details'] == noAccountMsg || error['details'] == noRoleMsg) {
          this.alertFlag = true;
          this.alertType = 'noRoleAccessError';
          this.alertSubTitle = error['details'];
          this.alertErrorContent = 'alertErrorContent';
          this.alertErrorContent = error['message'];
          this.commonService.hideLoader();
        }
        else if (this.router.url == '/home' && error['details'] != noAccountMsg && error['details'] != noRoleMsg) {
          this.alertFlag = true;
          this.alertType = 'error';
          this.alertTitle = 'Oops!';
          this.alertErrorContent = error['message'];
        }
      }
    );
    this.router.events.subscribe(() => {
      this.isExportdisable = true;
      this.isPptExportDisable = true;
      if (
        this.router.url === '/projectPerformanceReport' ||
        this.router.url === '/governanceProcess' ||
        this.router.url === '/governanceProcess/auditform' ||
        this.router.url === '/governanceProcess/dexAuditReport'
      ) {
        this.isExportdisable = false;
      }
      if (this.router.url === '/projectPerformanceReport' || this.router.url === '/governanceProcess/dexAuditReport') {
        this.isPptExportDisable = false;
      }
    });
    if (this.service !== undefined && this.service.userInfo.authenticated) {
      if (this.isSuperAdmin && this.switchUserEmailId) {
        let switchuserName = null;
        switchuserName = this.setCustomisedName(this.switchUserEmailId);
        this.userName = switchuserName;
      }
      else {
        this.userName = this.adalConfigService.ADAL_CONFIG.name;
      }
    } else {
      this.userName = '';
    }

    if (this.exportPdfService.subsVar == undefined) {
      this.exportPdfService.subsVar = this.exportPdfService.invokeFirstComponentFunction.subscribe((name: string) => {
        if (name == 'pdf') {
          this.generatePDF();
        } else {
          this.generatePPT();
        }
      });
    }
    this.checkCloseIcon()
  }

  checkCloseIcon() {
    if (this.userMetaData.switchUserEmailId != null) {
      this.closeIconStatus = true
    } else {
      this.closeIconStatus = this.userMetaData.roleName == 'Administrator' && (this.userMetaData.userStatus != 'Terminated' && this.userMetaData.userStatus != 'Suspended') ? true : false

    }
  }
  setCustomisedName(email: string) {
    if ((email != null || email != '') && email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
      let name: string = email.split('@')[0];
      name = name.replace('.', ' ');
      name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      const familyName = name.split(' ').splice(-1);
      const givenName = name.substring(0, name.indexOf(String(familyName)));
      let emailId = givenName != null ? givenName + String(familyName).charAt(0) : String(familyName);
      return emailId;
    }
  }
  noDataOkBtnFun() {
    this.alertFlag = false;
  }
  logoClick() {
    if (!this.userMetaData?.pureAssessor) {
      this.router.navigate(['./home']);
    }
  }
  pklogoClick() {
    window.open('https://catalyst.concentrix.com/', '_blank');
  }
  generateCompilancePdfTableContents(formatedDexArray, doc, width, height) {
    if (formatedDexArray.length) {
      const year = new Date().getFullYear();
      const mm = new Date().getMonth();
      const month = mm < 10 ? '0' + mm : mm;
      const pkLogoDex = new Image();
      let projectDetails;
      let applicationType;
      domtoimage
        .toPng(document.getElementsByClassName('sideImage')[0])
        .then((headerUrl) => {
          pkLogoDex.src = headerUrl;
          let styles = {
            0: { cellWidth: 75 },
            1: { cellWidth: 35 },
            2: { cellWidth: 75 },
          };
          const firstItem = formatedDexArray[0];
          const head = [this.pdfPptHelper.dexHeader(this.router.url), Object.keys(firstItem)];
          const headStyles = {
            fillColor: [225, 228, 234],
            // margin: { top: 50 }
            textColor: 0,
            fontStyle: 'normal',
          };
          if (this.router.url === '/governanceProcess') {
            styles = styles;
            applicationType = 'Applicability';
            const appliicabiltyProgramName = localStorage.getItem('appliicabiltyProgramName');
            const appliicabiltyProjectName = localStorage.getItem('appliicabiltyProjectName');
            projectDetails = `${localStorage.getItem(
              'appliicabiltyAccountName'
            )} / ${appliicabiltyProgramName} / ${appliicabiltyProjectName}`;
            this.pdfPptHelper.headerPDF(doc, projectDetails, width, applicationType, '', '', null);
            doc.autoTable({
              headStyles,
              columnStyles: {
                0: { cellWidth: 75 },
                1: { cellWidth: 35 },
                2: { cellWidth: 75 },
              },
              startY: 20,
              theme: 'grid',
              head,
              styles: {
                overflow: 'linebreak',
                cellWidth: 'wrap',
                minCellHeight: 1,
              },
              rowPageBreak: 'avoid',
              showHead: 'firstPage',
              body: Object.values(firstItem)[0],
            });
            this.pdfPptHelper.footerPDF(doc, pkLogoDex, width, height, 'catalyst_dc_helpdesk@concentrix.com');
          } else if (this.router.url === '/governanceProcess/auditform') {
            styles[0] = { cellWidth: 90 };
            styles[1] = { cellWidth: 35 };
            styles[2] = { cellWidth: 35 };
            styles[3] = { cellWidth: 90 };

            applicationType = 'Audit';
            const auditProjectName = localStorage.getItem('auditProjectName');
            projectDetails = `${localStorage.getItem('auditAccountName')}_${localStorage.getItem('auditProgramName')}_${auditProjectName}`;
            this.pdfPptHelper.headerPDF(doc, projectDetails, width, applicationType, '', '', null);
            doc.autoTable({
              headStyles,
              columnStyles: styles,
              startY: 20,
              theme: 'grid',
              head,
              styles: {
                overflow: 'linebreak',
                cellWidth: 'wrap',
                minCellHeight: 1,
              },
              rowPageBreak: 'avoid',
              showHead: 'firstPage',
              body: Object.values(firstItem)[0],
            });
            this.pdfPptHelper.footerPDF(doc, pkLogoDex, width, height, 'catalyst_dc_helpdesk@concentrix.com');
          }

          formatedDexArray.slice(1).map((item: any) => {
            for (const key in item) {
              if (item.hasOwnProperty(key)) {
                this.pdfPptHelper.headerPDF(doc, projectDetails, width, applicationType, '', '', null);
                doc.autoTable({
                  columnStyles: styles,
                  headStyles,
                  theme: 'grid',
                  head: this.createColumnforPdf(key),
                  body: item[key],
                  showHead: 'firstPage',
                  rowPageBreak: 'avoid',
                  styles: {
                    overflow: 'linebreak',
                    cellWidth: 'wrap',
                    minCellHeight: 1,
                  }
                });
                this.pdfPptHelper.footerPDF(doc, pkLogoDex, width, height, 'catalyst_dc_helpdesk@concentrix.com');
                return doc;
              }
            }
          });
          this.commonService.hideLoader();
          if (applicationType === 'Applicability') {
            doc.save(
              'DEx ' +
              `${applicationType}-${year}-${month}-${localStorage.getItem('appliicabiltyAccountName')}-${localStorage.getItem(
                'appliicabiltyProjectName'
              )}.pdf`
            );
          } else if (applicationType === 'Audit') {
            const monthNames = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ];
            const m = monthNames.indexOf(localStorage.getItem('auditMonth')) + 1;
            const mm = m < 10 ? '0' + m : m;
            doc.save(
              'DEx ' +
              `${applicationType}-${localStorage.getItem('auditYear')}-${mm}-${localStorage.getItem(
                'auditAccountName'
              )}-${localStorage.getItem('auditProjectName')}.pdf`
            );
          }
        })
        .catch((error) => {
          this.commonService.hideLoader();
          console.error('oops, something went wrong! with pkLogoDex', error);
        });
    }
  }
  createColumnforPdf(key) {
    if (this.router.url === '/governanceProcess') {
      return [[key, '', '']];
    }
    else if (this.router.url === '/governanceProcess/auditform') {
      return [[key, '', '', '']];
    }
  }
  createImage(domNode, section, width?, height?) {
    const options = {
      quality: 1,
      width,
      height,
    };
    const prom = domtoimage
      .toPng(domNode, options)
      .then((url) => [url, section])
      .catch((error) => {
        this.commonService.hideLoader();
        console.error(`oops, something went wrong! with ${section}`, error);
      });
    return prom;
  }
  compilanceErrorMessage() {
    this.alertFlag = true;
    this.alertType = 'noData';
    this.alertTitle = '';
    this.alertErrorContent = 'Please select Account, Project and Program';
    this.commonService.hideLoader();
  }
  functionlityInProgressErrorMsg() {
    this.alertFlag = true;
    this.alertType = 'noData';
    this.alertTitle = '';
    this.alertErrorContent = "Functionality is in-progress. Could n't export";
    this.commonService.hideLoader();
  }
  accordionsPromiseList(type, isSupportProject) {
    const agileUberView = document.getElementsByTagName('app-uberview');
    const dexAuditUberView = document.getElementsByClassName('uberview-audit');
    const asmUberView = document.getElementsByTagName('app-uberview-asm');
    const uberview = agileUberView.length ? agileUberView : asmUberView;
    const metricTrends = document.getElementsByClassName('metric-trends-cntr')[0];
    const accountMetricTrends = document.getElementsByClassName('account-level-dashboard-section')[0];
    const programMetricTrends = document.getElementsByClassName('program-level-dashboard-section')[0];
    const benefits = document.getElementsByTagName('benefits-container');
    const assesments = document.getElementsByTagName('dashboard-assessments-view')[0];
    const enhancedMetricTrends = document.getElementsByTagName('enhancement-metrictrends')[0];
    const accordionStatus = this.pdfPptHelper.accordionOpenCollapseStatus();
    const agileMaturitLevel = document.getElementsByClassName('agileMaturitLevel');
    const agileMaturityByFocusArea = document.getElementsByClassName('agileMaturityByFocusArea');
    const focusAreaComplianceLevel = document.getElementsByClassName('focusAreaComplianceLevel');
    var pdfWidgetCount: number = 0;
    const dashboardLevel = localStorage.getItem('dashboardLevel');
    if (accordionStatus && Object.keys(accordionStatus).length) {
      if (
        accordionStatus.isUberViewExpanded ||
        accordionStatus.isMetricTrendsExpanded ||
        accordionStatus.isBenefitsExpanded ||
        accordionStatus.isAssessmentExpanded ||
        accordionStatus.isEnhancementTrendsExpanded ||
        dashboardLevel == 'account' ||
        dashboardLevel == 'program' ||
        dashboardLevel == 'dexAudit'
      ) {
        const DashBoardArray = [];
        const DashBoardAssessmentArray = [];
        const DashBoardenhancedMetricTrendsArray = [];

        const promises = [this.createImage(document.getElementsByClassName('sideImage')[0], 'pklogo')];

        if (uberview && uberview.length && accordionStatus.isUberViewExpanded) {
          const data = uberview[0].parentElement;
          DashBoardArray.push(data);
        }
        if (dexAuditUberView && dexAuditUberView.length) {
          DashBoardArray.push(dexAuditUberView[0]);
        }
        if (accordionStatus.isMetricTrendsExpanded && type === 'PDF') {
          if (accordionStatus.isMetricTrendsExpanded && metricTrends && metricTrends.children && metricTrends.children.length) {
            for (let i = 0; i < metricTrends.children.length; i++) {
              DashBoardArray.push(metricTrends.children[i].children[0]);
            }
          }
        }
        if (dashboardLevel == 'account') {
          if (accountMetricTrends && accountMetricTrends.children && accountMetricTrends.children.length) {
            for (let i = 0; i < accountMetricTrends.children.length; i++) {
              DashBoardArray.push(accountMetricTrends.children[i].children[0]);
            }
          }
        }
        if (dashboardLevel == 'program') {
          if (programMetricTrends && programMetricTrends.children && programMetricTrends.children.length) {
            for (let i = 0; i < programMetricTrends.children.length; i++) {
              DashBoardArray.push(programMetricTrends.children[i].children[0]);
            }
          }
        }
        if (benefits && benefits.length && accordionStatus.isBenefitsExpanded) {
          DashBoardArray.push(benefits[0].parentElement);
        }
        if (accordionStatus.isAssessmentExpanded && type === 'PDF') {
          if (assesments && assesments.children && assesments.children[0].children) {
            const assmt = assesments.children[0].children;
            for (let i = 0; i < assmt.length; i++) {
              if (assmt[i].firstElementChild) {
                DashBoardAssessmentArray.push(assmt[i].firstElementChild);
                // if (assmt[i].firstElementChild.innerHTML.includes('TECHNICAL') ||
                //   assmt[i].firstElementChild.innerHTML.includes('SKILL GAP')) {
                //   DashBoardAssessmentArray.push(assmt[i].firstElementChild);
                // }
              }
            }
          }
        } else if (accordionStatus.isAssessmentExpanded && type === 'PPT' && agileMaturitLevel && agileMaturitLevel.length) {
          DashBoardArray.push(agileMaturitLevel[0].parentElement);
        }
        if (accordionStatus.isAssessmentExpanded && type === 'PPT' && agileMaturityByFocusArea && agileMaturityByFocusArea.length) {
          DashBoardArray.push(agileMaturityByFocusArea[0].parentElement);
        }
        if (type === 'PPT' && focusAreaComplianceLevel && focusAreaComplianceLevel.length) {
          DashBoardArray.push(focusAreaComplianceLevel[0].parentElement);
        }
        if (accordionStatus.isEnhancementTrendsExpanded && type === 'PDF' && isSupportProject) {
          if (enhancedMetricTrends && enhancedMetricTrends.firstElementChild && enhancedMetricTrends.firstElementChild.children) {
            const enhTrends = enhancedMetricTrends.firstElementChild.children;
            for (let i = 0; i < enhTrends.length; i++) {
              if (enhTrends[i].firstElementChild) {
                DashBoardenhancedMetricTrendsArray.push(enhTrends[i].firstElementChild);
              }
            }
          }
        }
        if (DashBoardArray && DashBoardArray.length) {
          DashBoardArray.map((item, i) => {
            if (item && item.innerHTML.includes('app-uberview')) {
              promises.push(this.createImage(item, 'app-uberview'));
            }
            if (item && item.innerHTML.includes('dexDashboard')) {
              promises.push(this.createImage(item, 'audit-uberview'));
            }
            if (item && item.innerHTML.includes('benefits-container')) {
              promises.push(this.createImage(item, 'benefits-container'));
            }
            // if (item && item.innerHTML.includes('Agile Maturity Level')) {
            //   promises.push(this.createPromise(item, 'agile-maturity-level'));
            // }
            if (item) {
              if (item.innerHTML.includes('agileMaturitLevel')) {
                promises.push(this.createImage(item, 'expanded-assessments'));
              } else if (item.innerHTML.includes('agileMaturityByFocusArea')) {
                promises.push(this.createImage(item, 'agileMaturityByFocusArea'));
              } else if (item.innerHTML.includes('focusAreaComplianceLevel')) {
                promises.push(this.createImage(item, 'focusAreaComplianceLevel'));
              }
            }
            if (
              item &&
              item.parentElement &&
              item.parentElement.parentElement &&
              item.parentElement.parentElement.className &&
              item.parentElement.parentElement.className === 'metric-trends-cntr'
            ) {
              this.pdfPptHelper.addRemoveClass('remove');
              promises.push(this.createImage(item, 'metric-trends'));
            }
            if (
              item &&
              item.parentElement &&
              item.parentElement.parentElement &&
              item.parentElement.parentElement.className &&
              item.parentElement.parentElement.className.includes('account-level-dashboard-section')
            ) {
              this.pdfPptHelper.addRemoveClass('remove');
              var extendedWdgt: any = [];
              let boxTitle = ['OVERALL PROJECT HEALTH', 'DEPLOYED vs DELIVERED', 'COMMITTED vs DELIVERED', 'OVERDUE SPRINT DATA UPLOAD'];
              if (boxTitle.includes(item.getElementsByClassName("box-title")[0].innerHTML)) {
                var itemContent: any = item.getElementsByClassName("box-content")[0];
                let itemdup: any = itemContent;
                let imgWidth = window.innerWidth + window.innerWidth / 4.5,
                  imgHeight = window.innerHeight;
                if (itemContent.children[0].childElementCount >= 3) {
                  for (let i = 2; i < itemContent.children[0].childElementCount; i++) {
                    extendedWdgt.push(itemdup.children[0].children[i]);
                    itemdup.children[0].children[i].className = 'hideExtraWidget';
                  }
                  if (item.getElementsByClassName("box-title")[0].innerHTML == 'OVERDUE SPRINT DATA UPLOAD') {
                    promises.push(
                      this.createImage(item, 'account-metric-trends', imgWidth, imgHeight - 100)
                    );
                    pdfWidgetCount++;
                    this.overdueSprintWidget = { pdfWidgetCount: pdfWidgetCount, title: 'OVERDUE SPRINT DATA UPLOAD' };
                    for (let i = 0; i < extendedWdgt.length; i++) {
                      promises.push(
                        this.createImage(extendedWdgt[i], 'account-metric-trends', imgWidth, imgHeight + 80)
                      );
                    }
                  }
                  else {
                    promises.push(
                      this.createImage(item, 'account-metric-trends', imgWidth, imgHeight - 100)
                    );
                    pdfWidgetCount++;
                    for (let i = 0; i < extendedWdgt.length; i++) {
                      promises.push(
                        this.createImage(extendedWdgt[i], 'account-metric-trends', imgWidth, imgHeight + 150)
                      );
                      pdfWidgetCount++;
                    }
                  }
                }
                else {
                  promises.push(
                    this.createImage(item, 'account-metric-trends', imgWidth, imgHeight + 150)
                  );
                  pdfWidgetCount++;
                  this.overdueSprintWidget = { pdfWidgetCount: pdfWidgetCount, title: 'OVERDUE SPRINT DATA UPLOAD' };
                }
              }
              else {
                promises.push(
                  this.createImage(item, 'account-metric-trends', window.innerWidth + window.innerWidth / 4.5, window.innerHeight + 150)
                );
                pdfWidgetCount++;
              }
            }
            if (
              item &&
              item.parentElement &&
              item.parentElement.parentElement &&
              item.parentElement.parentElement.className &&
              item.parentElement.parentElement.className === 'program-level-dashboard-section'
            ) {
              this.pdfPptHelper.addRemoveClass('remove');
              promises.push(this.createImage(item, 'program-metric-trends'));
            }
          });
        }
        if (DashBoardAssessmentArray && DashBoardAssessmentArray.length) {
          DashBoardAssessmentArray.map((item) => {
            // if (item && (item.innerHTML.includes('TECHNICAL') || item.innerHTML.includes('SKILL GAP'))) {
            //   promises.push(this.createPromise(item, 'assessments'));
            // }
            if (item) {
              if (item.innerHTML.includes('AGILE MATURITY LEVEL')) {
                promises.push(this.createImage(item, 'expanded-assessments'));
              } else {
                promises.push(this.createImage(item, 'assessments'));
              }
            }
          });
        }
        if (DashBoardenhancedMetricTrendsArray && DashBoardenhancedMetricTrendsArray.length) {
          DashBoardenhancedMetricTrendsArray.map((item) => {
            promises.push(this.createImage(item, 'enhancedMetricTrends'));
          });
        }
        return promises;
      } else {
        this.alertErrorContent = 'Please expand Uber View / Metric Trends / PROOF OF VALUES (POVs) / Assessments';
        if (type === 'PDF' && !isSupportProject) {
          this.alertErrorContent = 'Please expand Uber View / Metric Trends / PROOF OF VALUES (POVs) / Assessments';
        } else {
          this.alertErrorContent = 'Please expand Uber View / Metric Trends / PROOF OF VALUES (POVs) / Enhancement Trends';
        }
        this.alertFlag = true;
        this.alertType = 'noData';
        this.alertTitle = '';
        this.commonService.hideLoader();
      }
    }
  }
  generatePDF() {
    this.commonService.showLoader();
    const year = new Date().getFullYear();
    const mm = new Date().getMonth();
    const month = mm < 10 ? '0' + mm : mm;
    let doc = new jsPDF('p', 'mm', 'a4', true);
    const isSupportProject = localStorage.getItem('isSupportProject') === 'false' ? false : true;
    const dashboardLevel = localStorage.getItem('dashboardLevel');
    if (isSupportProject) {
      doc = new jsPDF('l', 'mm', 'a4', true);
    }
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    if (this.router.url.includes('/home/projectPerformanceReport') || this.router.url.includes('viewAccountDashboard')) {
      if (dashboardLevel == 'account' || this.router.url.includes('viewAccountDashboard')) {
        const pkLogo = new Image();
        const metricTrendsBase64Array = [];
        const promises = this.accordionsPromiseList('PDF', isSupportProject);

        const projectDetails = `${localStorage.getItem('dashboardAccount')}`;

        Promise.all(promises).then((listOfPromises) => {
          listOfPromises.map((item) => {
            if (item[1] === 'pklogo') {
              pkLogo.src = item[0];
            }

            if (item[1] === 'account-metric-trends') {
              metricTrendsBase64Array.push(item[0]);
            }
          });
          this.pdfPptHelper.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
          if (metricTrendsBase64Array.length) {
            this.pdfPptHelper.addRemoveClass('add');
            metricTrendsBase64Array.map((metric, index) => {
              let overdueSprint: any = this.isOverdueSprintWidget(index);
              if (index != metricTrendsBase64Array.length) {
                this.pdfPptHelper.metricTrendWidgetsPdf(
                  this.router.url,
                  doc,
                  projectDetails,
                  width,
                  height,
                  pkLogo,
                  metric,
                  index,
                  metricTrendsBase64Array.length - 1,
                  isSupportProject,
                  overdueSprint
                );
              }
            });
          }
          doc.save(`Account Performance Report-${year}-${month}-${localStorage.getItem('dashboardAccount')}.pdf`);

          this.commonService.hideLoader();
          if (this.router.url.includes('viewAccountDashboard')) {
            setTimeout(() => {
              window.close();
            }, 1000);
          }
        });
      } else if (dashboardLevel == 'program') {
        const img1 = new Image();
        const img3 = new Image();
        const pkLogo = new Image();
        const metricTrendsBase64Array = [];
        const promises = this.accordionsPromiseList('PDF', isSupportProject);

        const dashboardProgram = localStorage.getItem('dashboardProgram');
        const projectDetails = `${localStorage.getItem('dashboardAccount')} / ${dashboardProgram}`;
        Promise.all(promises).then((listOfPromises) => {
          listOfPromises.map((item) => {
            if (item[1] === 'pklogo') {
              pkLogo.src = item[0];
            }

            if (item[1] === 'program-metric-trends') {
              metricTrendsBase64Array.push(item[0]);
            }
          });
          this.pdfPptHelper.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
          if (metricTrendsBase64Array.length) {
            this.pdfPptHelper.addRemoveClass('add');
            metricTrendsBase64Array.map((metric, index) => {
              if (index != metricTrendsBase64Array.length - 1) {
                this.pdfPptHelper.metricTrendWidgetsPdf(
                  this.router.url,
                  doc,
                  projectDetails,
                  width,
                  height,
                  pkLogo,
                  metric,
                  index,
                  metricTrendsBase64Array.length - 1,
                  isSupportProject,
                  null
                );
              }
            });
          }
          doc.save(
            `Program Performance Report-${year}-${month}-${localStorage.getItem('dashboardAccount')}-${localStorage.getItem('dashboardProgram')}.pdf`
          );
          this.commonService.hideLoader();
        });
      } else {
        const accordionStatus = this.pdfPptHelper.accordionOpenCollapseStatus();
        if (accordionStatus && Object.keys(accordionStatus).length) {
          if (
            accordionStatus.isUberViewExpanded ||
            accordionStatus.isMetricTrendsExpanded ||
            accordionStatus.isBenefitsExpanded ||
            accordionStatus.isAssessmentExpanded ||
            accordionStatus.isEnhancementTrendsExpanded
          ) {
            const promises = this.accordionsPromiseList('PDF', isSupportProject);
            const img1 = new Image();
            const img3 = new Image();
            const pkLogo = new Image();
            const dashboardProgram = localStorage.getItem('dashboardProgram');
            const dashboardProject = localStorage.getItem('dashboardProject');
            const projectDetails = `${localStorage.getItem('dashboardAccount')} / ${dashboardProgram} / ${dashboardProject}`;
            const metricTrendsBase64Array = [];
            const assesmentsBase64Array = [];
            const enhancedMetricTrendsBase64Array = [];
            Promise.all(promises).then((listOfPromises) => {
              listOfPromises.map((item) => {
                if (item[1] === 'pklogo') {
                  pkLogo.src = item[0];
                }
                if (item[1] === 'app-uberview') {
                  img1.src = item[0];
                }
                if (item[1] === 'metric-trends') {
                  metricTrendsBase64Array.push(item[0]);
                }
                if (item[1] === 'benefits-container') {
                  img3.src = item[0];
                }
                if (item[1] === 'assessments') {
                  assesmentsBase64Array.push({ base64: item[0], isExapandedGraph: false });
                }
                if (item[1] === 'expanded-assessments') {
                  assesmentsBase64Array.push({ base64: item[0], isExapandedGraph: true });
                }
                if (item[1] === 'enhancedMetricTrends') {
                  enhancedMetricTrendsBase64Array.push(item[0]);
                }
              });
              if (
                accordionStatus.isUberViewExpanded ||
                accordionStatus.isMetricTrendsExpanded ||
                accordionStatus.isBenefitsExpanded ||
                accordionStatus.isAssessmentExpanded ||
                accordionStatus.isEnhancementTrendsExpanded
              ) {
                if (accordionStatus.isUberViewExpanded) {
                  this.pdfPptHelper.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
                  this.pdfPptHelper.dashBoardHeader(doc, 10, 25, 'UBER VIEW');
                  if (isSupportProject) {
                    doc.addImage(img1, 'JPEG', 10, 32, width - 20, height - 50, '', 'FAST');
                  } else if (!isSupportProject) {
                    doc.addImage(img1, 'JPEG', 10, 32, width - 20, 0, '', 'FAST');
                  }
                  this.pdfPptHelper.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
                  if (
                    accordionStatus.isMetricTrendsExpanded ||
                    accordionStatus.isBenefitsExpanded ||
                    (accordionStatus.isAssessmentExpanded && assesmentsBase64Array.length) ||
                    (accordionStatus.isEnhancementTrendsExpanded && enhancedMetricTrendsBase64Array.length)
                  ) {
                    doc.addPage();
                  }
                }
                if (accordionStatus.isMetricTrendsExpanded && metricTrendsBase64Array.length) {
                  this.pdfPptHelper.addRemoveClass('add');
                  metricTrendsBase64Array.map((metric, index) => {
                    this.pdfPptHelper.metricTrendWidgetsPdf(
                      this.router.url,
                      doc,
                      projectDetails,
                      width,
                      height,
                      pkLogo,
                      metric,
                      index,
                      metricTrendsBase64Array.length,
                      isSupportProject,
                      null
                    );
                  });
                  if (
                    accordionStatus.isBenefitsExpanded ||
                    (accordionStatus.isAssessmentExpanded && assesmentsBase64Array.length) ||
                    (accordionStatus.isEnhancementTrendsExpanded && enhancedMetricTrendsBase64Array.length)
                  ) {
                    doc.addPage();
                  }
                }
                if (accordionStatus.isBenefitsExpanded) {
                  this.pdfPptHelper.headerPDF(doc, projectDetails, width, 'Performance Report', '', '', null);
                  this.pdfPptHelper.dashBoardHeader(doc, 10, 25, 'PROOF OF VALUES (POVs)');
                  doc.addImage(img3, 'JPEG', 0, 28, width, 0, '', 'FAST');
                  this.pdfPptHelper.footerPDF(doc, pkLogo, width, height, 'Catalyst_dc_helpdesk@concentrix.com');
                  if (
                    (accordionStatus.isAssessmentExpanded && assesmentsBase64Array.length) ||
                    (accordionStatus.isEnhancementTrendsExpanded && enhancedMetricTrendsBase64Array.length)
                  ) {
                    doc.addPage();
                  }
                }
                if (accordionStatus.isAssessmentExpanded && assesmentsBase64Array.length) {
                  assesmentsBase64Array.map((metric, index) => {
                    this.pdfPptHelper.assessmentWidgets(
                      doc,
                      projectDetails,
                      width,
                      height,
                      pkLogo,
                      metric.base64,
                      index,
                      assesmentsBase64Array.length,
                      metric.isExapandedGraph
                    );
                  });
                }
                if (accordionStatus.isEnhancementTrendsExpanded && enhancedMetricTrendsBase64Array.length) {
                  enhancedMetricTrendsBase64Array.map((enhMetric, index) => {
                    this.pdfPptHelper.enhancedMetricTrendWidgets(
                      doc,
                      projectDetails,
                      width,
                      height,
                      pkLogo,
                      enhMetric,
                      index,
                      enhancedMetricTrendsBase64Array.length
                    );
                  });
                }

                doc.save(
                  `Project Performance Report-${year}-${month}-${localStorage.getItem('dashboardAccount')}-${localStorage.getItem(
                    'dashboardProject'
                  )}.pdf`
                );
                this.commonService.hideLoader();
              } else {
                this.alertFlag = true;
                this.alertType = 'noData';
                this.alertTitle = '';
                this.alertErrorContent = 'Please expand Uber View / Metric Trends / PROOF OF VALUES (POVs) / Assessments';
                if (isSupportProject) {
                  this.alertErrorContent = 'Please expand Uber View / Metric Trends / PROOF OF VALUES (POVs) / Enhancement Trends';
                }
                this.commonService.hideLoader();
              }
            });
          } else {
            this.alertFlag = true;
            this.alertType = 'noData';
            this.alertTitle = 'Oops';
            if (!isSupportProject) {
              this.alertErrorContent = 'There is no data to export in Uber View/Metric Trends/PROOF OF VALUES (POVs)/Assessments.';
            } else {
              this.alertErrorContent = 'There is no data to export in Uber View/Metric Trends/PROOF OF VALUES (POVs)/Enhancement Trends.';
            }
            this.commonService.hideLoader();
          }
        }
      }
    } else if (this.router.url === '/governanceProcess') {
      const dexValues = [];
      const dexFormValues = JSON.parse(localStorage.getItem('dexFormValues'));

      if (dexFormValues && dexFormValues.length) {
        dexFormValues.map((item: any) => {
          const obj = {};
          const control = [];

          item.controls.map((cnt) => {
            control.push([cnt.name, cnt.applicablity, cnt.applicablityComment]);
          });
          obj[item.name] = control;
          dexValues.push(obj);
        });
        this.generateCompilancePdfTableContents(dexValues, doc, width, height);
      } else {
        this.compilanceErrorMessage();
      }
    } else if (this.router.url === '/governanceProcess/auditform') {
      const auditDexValues = [];
      const auditDexFormValues = JSON.parse(localStorage.getItem('auditDexFormValues'));

      if (auditDexFormValues && auditDexFormValues.length) {
        auditDexFormValues.map((item: any) => {
          const obj = {};
          const control = [];
          item.controls.map((cnt) => {
            control.push([cnt.name, cnt.applicablity, cnt.conformance, cnt.auditComment]);
          });
          obj[item.name] = control;
          auditDexValues.push(obj);
        });
        this.generateCompilancePdfTableContents(auditDexValues, doc, width, height);
      } else {
        this.compilanceErrorMessage();
      }
    } else if (this.router.url === '/governanceProcess/dexAuditReport') {
      this.functionlityInProgressErrorMsg();
    }
  }
  isOverdueSprintWidget(index) {
    if (this.overdueSprintWidget != null && index >= this.overdueSprintWidget?.pdfWidgetCount) {
      return this.overdueSprintWidget;
    }
    return null;
  }

  logout() {
    const data = {
      ipaddr: this.service.userInfo.profile.ipaddr,
      name: this.adalConfigService.ADAL_CONFIG.name,
      emailId: this.service.userInfo.profile.upn,
    };
    this.userAccessService.logOut(data).subscribe((response) => { });
    sessionStorage.clear();
    this.service.logOut();
  }

  checkProjectAccess(account) {
    for (let i = 0; i < account.length; i++) {
      if (account[i].programs.length > 0) {
        for (let j = 0; j < account[i].programs.length; j++) {
          if (account[i].programs[j].projects.length > 0) {
            for (let z = 0; z < account[i].programs[j].projects.length; z++) {
              if (account[i].programs[j].projects[z].projectAccess == true) {
                return account[i].programs[j].projects[z].projectAccess;
              }
            }
          }
        }
      }
    }
    return false;
  }

  swicthBackToAdmin() {
    localStorage.setItem('gdrDropDown', JSON.stringify([]));
    let adminUserEmailId = localStorage.getItem('userMetaDataRoleEmailId'),
      reqstInfo = 43;
    this.settingsService.getUser().subscribe((response: any): any => {
      this.userList = response.filter((i) => {
        return i.isEnabled == true;
      });
      this.adminUserId = this.userList.find((e) => {
        return e.emailId == adminUserEmailId;
      });
    });

    this.settingsService.getAccess(reqstInfo).subscribe(
      (response: any): any => {
        const accounts = response.accounts;
        for (let i = 0; i < accounts.length; i++) {
          accounts[i].accountAccess = true;
        }
        this.userList.find((f) => f.id === this.adminUserId.id).accounts = accounts;
        const UserWsrPreferredColumns = JSON.parse(localStorage.getItem('oldUserWsrPreferredColumns'))
        const UserBvaPortfolioPreferredColumns = JSON.parse(localStorage.getItem('oldUserBvaPortfolioPreferredColumns'))

        let switchUserReq = null;
        switchUserReq = cloneDeep(this.userList.find((f) => f.id === this.adminUserId.id));
        switchUserReq.wsrPreferredColumns = UserWsrPreferredColumns
        switchUserReq.bvaPortfolioPreferredColumns = UserBvaPortfolioPreferredColumns
        if (switchUserReq) {
          if (switchUserReq['switchUserEnable']) {
            delete switchUserReq['switchUserEnable'];
          }
          if (switchUserReq['checked']) {
            delete switchUserReq['checked'];
          }
          switchUserReq['roleId'] = 43;
          switchUserReq['roleName'] = "Administrator";
          switchUserReq['userStatus'] = 'Active'
        }
        this.settingsService.updateAccess(switchUserReq).subscribe(
          (response: any): any => {
            // this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //   return false;
            // };
            let addRoleButton = JSON.parse(localStorage.getItem('addRoleButtonDisable'));
            if (addRoleButton['isDisable']) {
              let disableRole = { isDisable: false };
              localStorage.setItem('addRoleButtonDisable', JSON.stringify(disableRole));
            }
            this.router.navigate(['/home']).then(() => {
              window.location.reload();
            });
          },
          (error) => {
            this.alertFlag = true;
            this.alertType = 'error';
            this.alertTitle = 'Oops!';
            this.alertSubTitle = 'Something went wrong';
            this.alertErrorContent = error['message'];
          }
        );
      },
      (error) => {
        this.alertFlag = true;
        this.alertType = 'error';
        this.alertTitle = 'Oops!';
        this.alertSubTitle = 'Something went wrong';
        this.alertErrorContent = error['message'];
      }
    );
  }

  onErrorClose() {
    this.alertFlag = false;
  }

  generatePPT() {
    if (this.router.url === '/projectPerformanceReport') {
      const dashboardLevel = localStorage.getItem('dashboardLevel');
      if (dashboardLevel == 'account' || dashboardLevel == 'program') {
        this.functionlityInProgressErrorMsg();
      }
      if (dashboardLevel != 'account' && dashboardLevel != 'program') {
        const isSupportProject = localStorage.getItem('isSupportProject') === 'false' ? false : true;
        if (!isSupportProject) {
          this.commonService.showLoader();
          this.dashboard = this.exportPdfService.sendDashboardData();
          if (this.dashboard && this.dashboard.metricsToReport) {
            const metricTrends: any = this.dashboard.metricsToReport.find((f: any) => f.h_id === 3);
            this.metricTrendsWidgetsStatus = this.pdfPptHelper.metricTrendWidgetsStatus(metricTrends);
          }
          if (this.dashboard && this.dashboard.projectId && this.dashboard.sprintId) {
            this.dashboardService.getMetricTrends(this.dashboard.projectId, this.dashboard.sprintId).subscribe((res: any) => {
              this.metrics = res;
              //  this.generateDashboardCharts(this.metrics, isSupportProject);
            }),
              setTimeout(() => { }, 0);
          } else {
            this.alertFlag = true;
            this.alertType = 'noData';
            this.alertTitle = 'Oops';
            this.alertErrorContent = 'There is no data to export in Uber View/Metric Trends/PROOF OF VALUES (POVs).';
            this.commonService.hideLoader();
          }
        } else {
          this.functionlityInProgressErrorMsg();
        }
      }
    } else if (this.router.url === '/governanceProcess/dexAuditReport') {
      this.commonService.showLoader();
      this.dashboardService.getAllDexAccountsmetrics().subscribe(
        (response: any) => {
          this.dexMetrics = response;
          // this.generateDexAuditReport(this.dexMetrics)
        },
        (error) => {
          this.functionlityInProgressErrorMsg();
        }
      );
    } else {
      this.functionlityInProgressErrorMsg();
    }
  }
}
