import { environment } from '../environments/environment';

export const API_CONFIG = {
  baseUrl: environment.HOME_URL,
  uberDetails: '/projects/PROJECT_ID/sprint/SPRINT_ID/overall-health-metrics',
  additionalInfo: '/projects/additional-info/PROJECT_ID/SPRINT_ID',
  uberDetailsAsm: '/asm/PROJECT_ID/uberview',
  metadata: '/projects/metadata',
  metricsToReport: '/projects/project/PROJECT_ID/metricsToReport',
  projects: '/projects/program',
  sprints: '/sprints/project/PROJECT_ID',
  downloadTemplate: '/sprints/PROJECT_ID/mapping',
  fileUpload: '/sprints/upload/project/PROJECT_ID/sprint',
  addProject: '/projects',
  benefits: '/projects/PROJECT_ID/sprint/SPRINT_ID/benefits-realized',
  projectData: '/projects/PROJECT_ID',
  improvements: '/projects/PROJECT_ID/improvements',
  metricTrends: '/projects/PROJECT_ID/sprint/SPRINT_ID/metricTrends',
  asmMetricTrends: '/asm/PROJECT_ID/incidentData',
  slaInfo: '/asm/PROJECT_ID/SlaInformation',
  getProjectName: '/projects/program?name=',
  addOtherType: '/projects/other-category/CATEGORY_TYPE/CATEGORY_VALUE',
  getImprovements: '/projects/PROJECT_ID/improvementData',
  getRetroData: '/projects/PROJECT_ID/retrospective',
  getMapping: '/projects/PROJECT_ID/mappingRequired',
  addImprovements: '/projects/PROJECT_ID/improvement/',
  deleteImprovement: '/projects/PROJECT_ID/improvement/',
  updateImprovement: '/projects/PROJECT_ID/improvement/',
  addSprintRetroData: '/projects/PROJECT_ID/retrospective/',
  deleteSprintRetroData: '/projects/PROJECT_ID/retrospective/',
  updateSprintRetroData: '/projects/PROJECT_ID/retrospective/',
  getiterationData: '/sprints/SPRINT_ID/iterationData',
  addIteration: '/sprints/',
  agileMaturities: '/sprints/agileMaturities',
  assessments: '/sprints/SPRINT_ID/assessments',
  agileAssessments: '/projects/assessments/PROJECT_ID',
  data: '/projects/data/project/PROJECT_ID/sprint/SPRINT_ID',
  addAsmCommentDetails: '/asm/ASM_ID/comment/',
  addCommentDetails: '/sprints/SPRINT_ID/comment/',
  getAsmMetricComments: '/asm/ASM_ID/comments',
  getMetricComments: '/sprints/SPRINT_ID/comments',
  getAllMetricComments: '/sprints/project/PROJECT_ID/comments',
  accountMetrics: '/account/ACCOUNT_ID/metricsToReport',
  accountHelpData: '/metadata/help-data/DASHBOARD',
  getAccountComments: '/account/ACCOUNT_ID/comments',
  addAccountComments: '/account/ACCOUNT_ID/comment/',
  addProgramComment: '/account/program/PROGRAM_ID/comment/',
  programMetrics: '/account/ACCOUNT_ID/program/PROGRAM_ID/metricsToReport',
  getProgramComments: '/account/program/PROGRAM_ID/comments',
  getProjectdetailsByName: '/projects/names/PROJECT_NAME',
  help: '/projects/helpData',
  asmFileupload: '/asm/upload/project/PROJECT_ID/asm',
  addIncident: '/asm/',
  role: '/metadata/login',
  signup: '/users/login/',
  logOut: '/users/logout/UNIQUE_NAME/',
  addAccount: '/metadata/account/',
  updateAccount: '/metadata/account/',
  getAccount: '/metadata/accounts',
  deleteAccount: '/metadata/account/',
  addProgram: '/metadata/program/',
  getProgram: '/metadata/programs',
  updateProgram: '/metadata/program/',
  deleteProgram: '/metadata/program/',
  deleteProject: '/metadata/project/',
  addCapability: '/metadata/capability/',
  addFocusArea: '/metadata/account/',
  updateFocusArea: '/metadata/account/',
  getFocusArea: '/metadata/accounts',
  deleteFocusArea: '/metadata/account/',
  addRole: '/roles/',
  getRoles: '/roles/',
  updateRoles: '/roles/update/',
  deleteRole: '/roles/update/ROLE_ID/',
  addUser: '/users/',
  getUser: '/users/alluser-basic-info',
  getAccess: '/users/accountList/ROLE_ID',
  editAccess: '/users/accountList/ROLE_ID/EMAIL_ID',
  updateDexMetadata: '/dex/metadata/update',
  deleteDexMetadata: '/dex/metadata/update/FOCUS_ID/CONTROL_ID',
  updateUser: '/users/update',
  updateLastLoginDate: '/users/updateLoginDate',
  switchUser: '/users/switch-user',
  usersMetadata: '/users/userInfo/UNIQUE_NAME',
  getAssetsMetadata: '/metadata/account-assets/ACCOUNT_ID',
  addAsset: '/metadata/add/account-asset/ACCOUNT_ID',
  getAssetsList: '/projects/asset-report',
  wsRecordList: '/global-delivery-report/weekly-status-record',
  //autoDuplicate: '/global-delivery-report/weekly-status-record/autoDuplicate',
  wsRecordLatestData: '/global-delivery-report/weekly-status-record/PROJECT_ID',
  gdrMetaData: '/global-delivery-report/metadata',
  gdReportList: '/global-delivery-report',
  wsrSpecificRecord: '/global-delivery-report/weekly-status-record/grid/ID',
  gdReportSelectiveWidget: '/global-delivery-report/selected-widget/WIDGET_NAME',
  createWSRecord: '/global-delivery-report/weekly-status-record/create',
  updateWSRData: '/global-delivery-report/weekly-status-record/update',
  bvaUseMetaData: '/bva/bva-account-projects/UNIQUE_NAME',
  usersMetadataInUsersPage: '/users/fetch-user-info/UNIQUE_NAME',
  getProjectDexInfo: '/dex/PROJECT_ID',
  getDexInfo: '/dex/metadata',
  getDexHistoryInfo: '/audit/history/PROJECT_ID',
  getAuditList: '/audit/project/PROJECT_ID',
  addaplicability: '/dex/PROJECT_ID/PROJECT_NAME',
  updateAudit: '/audit/update/',
  updateApplicability: '/dex/update/PROJECT_ID/PROJECT_NAME',
  addAudit: '/audit/',
  loadPreviousAuditData: '/audit/load-previous-audit-info/PROJECT_ID',
  getAuditDexInfo: '/audit/PROJECT_ID',
  getProjectssByID: '/projects/program/programId',
  getAgileMaturityQuestions: '/ama/metadata',
  checkCurrentAssesment: '/ama/PROJECT_ID/YEAR/MONTH',
  createAssesment: '/ama',
  getAgileaturityList: '/ama/userAssessments',
  viewAssesment: '/ama/ASSESMENT_ID',
  editAssement: '/ama/update/',
  deleteAssement: '/ama/delete/',
  getLatestReport: '/sprints/reports/getProjectsListWithLatestIterationCreatedate',
  getNCList: '/ncmanagement/usernclist',
  getNC: '/ncmanagement/fetch-nc/NC_ID',
  ncUpdate: '/ncmanagement/update/',
  calculateScore: '/audit/calculate/indexscore/PROJECT_ID',
  getAllDexAccountsmetrics: '/reports/get-all-dex-accounts-metrics',
  getDexDrillDown: '/reports/get-account-dex-drilldown/PROJECT_IDS',
  getProjectList: '/projects/projectManagementReport/CONDITION',
  setFavAccount: '/users/update-favorite-dashboard/EMAIL/TYPE/ACCOUNT_ID',
  setFavProgram: '/users/update-favorite-dashboard/EMAIL/TYPE/ACCOUNT_ID/PROGRAM_ID',
  setFavProject: '/users/update-favorite-dashboard/EMAIL/TYPE/ACCOUNT_ID/PROGRAM_ID/PROJECT_ID',

  dpoDataExist: '/dpo/data-exist',
  dpoDataUpload: '/dpo/upload-data',
  dpoUberDetails: '/dpo/helath-metrics/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  dpoDataFilterList: '/dpo/fetch-uploaded-info/PROJECT_ID',
  dpoProdMetricTrend: '/dpo/prod-metric-trends/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  dpoNonProdMetricTrend: '/dpo/non-prod-metric-trends/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  dpoProdMetricTrendsComments: '/dpo/fetch-comments/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  dpoProdSaveComment: '/dpo/add-Comment/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  getAdditionalInfo: '/dpo/fetch-additional-info/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  addAdditionalInfo: '/dpo/add-additional-info/UPLOAD_MONTH/UPLOAD_YEAR/PROJECT_ID',
  dpoDownloadFile: '/dpo/download-data/FROM_YEAR/TO_YEAR/MONTH_FROM/MONTH_TO/PROJECT_ID/TYPE',
  getDevopsNameslist: '/dpo/fetch-all-devops-team-names',
  updateDevOpsNameList: '/dpo/update/devops-team-names',

  bvaUpload: '/bva/upload-timesheet-data',
  bvaProjectPlanUpload: '/bva/upload-project-plan/PROJECT_ID',
  portfolioReport: '/bva/account-wise/portfolio-report/UNIQUE_NAME',
  portfolioDashbrdReport: '/bva/account-wise/portfolio-dashboard-report/UNIQUE_NAME',
  projectBvaReport: '/bva/project-budgeted-report/PROJECT_ID',
  getActualBvAData: '/bva/project-budgeted-actual-report/PROJECT_ID',
  getTrendsData: '/bva/trends/PROJECT_ID',
  manageBvANote: '/bva/add-update-note/INTTACTID/BUDGETEDEFFORTS/ACTUALEFFORTS',
  getBvANotes: '/bva/notes/PROJECT_ID',
  getDefultColumnList: '/bva/portfilio-preferred-columns/UNIQUE_NAME',
  getGdrDefultColumnList: '/global-delivery-report/preferred-columns/UNIQUE_NAME',
  savePrfferedColumnList: '/bva/save-preferred-columns/UNIQUE_NAME',
  saveGdrPrfferedColumnList: '/global-delivery-report/save-preferred-columns/UNIQUE_NAME',
  shareBvA: '/bva/share-report-link',
  bvaForecast: '/bva/save/project-forecast',
  updateProjectName: '/projects/update/name/PROJECT_ID/PROJECT_NAME/IS_NOTIFY',
  getNotifications: '/notification/fetch-all',
  updateNotification: '/notification/update',
  addDExComments: '/reports/update/comment',
  getDexComments: '/reports/get-all-comments',
  getSprintDates: '/sprints/PROJECT_ID/getSprintDates',
  getAllSprintBugClassifications: '/sprints/ProjectId/getAllSprintBugClassifications',
  getWsrByDateRange: '/global-delivery-report/weekly-status-record/byDateRange',
  gdrPmsOrDms: '/global-delivery-report/pmsOrDms',
  gdrCpsOrAms: '/global-delivery-report/cpsOrAms',
  getSprntDetails: '/sprints/project/PROJECT_ID',
  addExtension: '/bva/bvaNewProject/addExtension',
  projectStatusChange: '/projects/projectClose',
  userStatusChange: '/users/updateUserStatus',
  amaProjects: '/projects/amaOnly',
  createWsrRisk: '/risks/risk',
  getWsrRiskList: '/risks/riskByWsr',
  getPreviousWeekWSRAndProject: '/global-delivery-report/weekly-status-record/previousWeekRecord',

  getRadarGraph: '/audit/graph/PROJECT_ID',
  getAccessFilterData: '/users/accountList/filters',
  getRegionCountryList: '/metadata/regionWithCountries'
};
