<div class="base_container" [class.no-scroll]="isPopupOpen">
  <app-header></app-header>
  <div class="routeroutlet"><router-outlet></router-outlet></div>
  <app-footer></app-footer>
</div>
<app-snack-bar></app-snack-bar>
<alert-popup
  [alertFlag]="alertPopConfig?.alertFlag"
  [alertType]="alertPopConfig?.alertType"
  [alertTitle]="alertPopConfig?.alertTitle"
  [alertSubTitle]="alertPopConfig?.alertSubTitle"
  [alertErrorContent]="alertPopConfig?.alertErrorContent"
  (noDataOkBtnFun)="resetAlertPop()"
>
</alert-popup>
<div class="overlay" *ngIf="showLoader">
  <app-circle-spinner></app-circle-spinner>
</div>
