import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';
import { DragulaModule } from 'ng2-dragula';
import { NvD3Module } from 'ng2-nvd3';
import { AsmExpandableBoxComponent } from '../modules/dashboard/asmcomponents/asm-expandable-box/asm-expandable-box.component';
import { ExpandableBoxComponent } from '../modules/dashboard/components/expandable-box/expandable-box.component';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { AdherenceGroupBarComponent } from './components/charts/adherence-group-bar/adherence-group-bar.component';
import { AsmBarChartComponent } from './components/charts/asm-bar-chart/asm-bar-chart.component';
import { AsmLineChartComponent } from './components/charts/asm-line-chart/asm-line-chart.component';
import { AsmPieChartComponent } from './components/charts/asm-pie-chart/asm-pie-chart.component';
import { BarLineChartComponent } from './components/charts/bar-line-chart/bar-line-chart.component';
import { BubblechartComponent } from './components/charts/bubblechart/bubblechart.component';
import { DefectpiechartComponent } from './components/charts/defectpiechart/defectpiechart.component';
import { DexCoverageDonutChartComponent } from './components/charts/dex-coverage-donut-chart/dex-coverage-donut-chart.component';
import { EnhancementLinechartComponent } from './components/charts/enhancement-linechart/enhancement-linechart.component';
import { GroupBarChartComponent } from './components/charts/groupBarChart/group.bar.chart.component';
import { HistoricalBarchartComponent } from './components/charts/historical-barchart/historical-barchart.component';
import { LineChartComponent } from './components/charts/lineChart/line.chart.component';
import { LineplusbarchartComponent } from './components/charts/lineplusbarchart/lineplusbarchart.component';
import { MultiBarHorizontalChartComponent } from './components/charts/multi-bar-horizontal-chart/multi-bar-horizontal-chart.component';
import { MultiBarComponent } from './components/charts/multi-bar/multi-bar.component';
import { MultiLineBarChartComponent } from './components/charts/multi-line-bar-chart/multi-line-bar-chart.component';
import { MultilinechartComponent } from './components/charts/multilinechart/multilinechart.component';
import { MutiRadarChartComponent } from './components/charts/muti-radar-chart/muti-radar-chart.component';
import { OmBarChartComponent } from './components/charts/om-bar-chart/om-bar-chart.component';
import { PieChartComponent } from './components/charts/pieChart/pieChart.component';
import { PolarChartComponent } from './components/charts/polar-chart/polar-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { StackedareachartComponent } from './components/charts/stackedareachart/stackedareachart.component';
import { StepLineChartComponent } from './components/charts/step-line-chart/step-line-chart.component';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { CustomAssetsComponent } from './components/custom-assets/custom-assets.component';
import { CustomDatagridComponent } from './components/custom-datagrid/custom-datagrid.component';
import { CustomDetailgridComponent } from './components/custom-detailgrid/custom-detailgrid.component';
import { CustomDivisionsComponent } from './components/custom-divisions/custom-divisions.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { DexdceligibleboxComponent } from './components/dexdceligiblebox/dexdceligiblebox.component';
import { FieldMappingComponent } from './components/field-mapping/field-mapping.component';
import { PdfPptHelper } from './components/header/pdf-ppt-helper';
import { HelpPageSupportComponent } from './components/help-page-support/help-page-support.component';
import { HelpPageComponent } from './components/help-page/help-page.component';
// import { RadialbarchartComponent } from './components/charts/radialbarchart/radialbarchart.component';
import { InfopopupComponent } from './components/infopopup/infopopup.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NumericSliderComponent } from './components/numeric-slider/numeric-slider.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { HelpPageService } from './services/help-page.service';
import { ResetService } from './services/reset.service';
import { ExportToExcel } from './_helper/exportToExcel.helper';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { CircleSpinnerComponent } from './components/circle-spinner/circle-spinner.component';
import { LimitDigitDecimalDirective } from './directives/limit-digit-decimal.directive';
import { CustomMinValidatorDirective } from './directives/custom-min-validator.directive';
import { CustomMaxValidatorDirective } from './directives/custom-max-validator.directive';
import { NegativeDecoratorDirective } from './directives/negative-decorator.directive';
import { AgGridDatepickerComponent } from './components/ag-grid-datepicker/ag-grid-datepicker.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MaterialModule } from './material.module';
import { LimitTwoDigitDecimalDirective } from './directives/limit-two-digit-decimal.directive';
import { SettingsService } from '../modules/settings/_services/settings.serive';
import { RoundOffDecimalDirective } from './directives/round-off-decimal.directive';
import { CurrencyCodeDirective } from './directives/currency-code.directive';
import { CommonMethod } from './_helper/commonMethod.helper';
import { GdrHorizontalBarChartComponent } from './components/charts/gdr-horizonal-bar-chart/gdr-horizonal-bar-chart.component';
import { ChartjsPieChartComponent } from './components/charts/chartjs-pie-chart/chartjs-pie-chart.component';
import { BarChartComponent } from './components/charts/barChart/bar.chart.component';
import { TimeStampDirective } from './directives/time-stamp.directive';
import { GdrBarChartComponent } from './components/charts/gdr-bar-chart/gdr-bar-chart.component';
import { ChartjsStackedBarChartComponent } from './components/charts/chartjs-stacked-bar-chart/chartjs-stacked-bar-chart.component';
import { ChartjsGroupBarChartComponent } from './components/charts/chartjs-group-bar-chart/chartjs-group-bar-chart.component';
import { ChartjsLineChartComponent } from './components/charts/chartjs-line-chart/chartjs-line-chart.component';
import { ChartjsBarChartComponent } from './components/charts/chartsjs-bar-chart/chartjs-bar-chart.component';
import { LimitDigitsDirective } from './directives/limit-digits.directive';
import { NumberToCurrencyFormatDirective } from './directives/number-to-currency-format.drirective';
import { OrderByPipe } from './pipes/orderBy.pipe';

const routes: Routes = [
  {
    path: 'help',
    component: HelpPageComponent,
  },
  {
    path: 'help/support',
    component: HelpPageSupportComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    MaterialModule,
    RouterModule.forChild(routes),
    DragulaModule.forRoot(),
    ChartsModule,
    NvD3Module,
    NgxChartsModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    ExpandableBoxComponent,
    AsmExpandableBoxComponent,
    CustomSelectComponent,
    CustomDivisionsComponent,
    CustomDetailgridComponent,
    NumericSliderComponent,
    GroupBarChartComponent,
    BarChartComponent,
    GdrHorizontalBarChartComponent,
    GdrBarChartComponent,
    LineChartComponent,
    CustomDatagridComponent,
    AlertPopupComponent,
    MessagesComponent,
    CommentBoxComponent,
    PieChartComponent,
    RouterModule,
    FilterPipe,
    LinkifyPipe,
    AutofocusDirective,
    AsmBarChartComponent,
    OmBarChartComponent,
    AsmLineChartComponent,
    EnhancementLinechartComponent,
    AdherenceGroupBarComponent,
    AsmPieChartComponent,
    FieldMappingComponent,
    RadarChartComponent,
    PolarChartComponent,
    BarLineChartComponent,
    MutiRadarChartComponent,
    HistoricalBarchartComponent,
    MultilinechartComponent,
    MultiBarComponent,
    MultiBarHorizontalChartComponent,
    DefectpiechartComponent,
    DexCoverageDonutChartComponent,
    StepLineChartComponent,
    MultiLineBarChartComponent,
    BubblechartComponent,
    // RadialbarchartComponent,
    InfopopupComponent,
    LineplusbarchartComponent,
    DexdceligibleboxComponent,
    StackedareachartComponent,
    CustomAssetsComponent,
    SnackBarComponent,
    InputTrimDirective,
    CircleSpinnerComponent,
    LimitDigitsDirective,
    LimitDigitDecimalDirective,
    LimitTwoDigitDecimalDirective,
    RoundOffDecimalDirective,
    TimeStampDirective,
    CurrencyCodeDirective,
    NumberToCurrencyFormatDirective,
    CustomMaxValidatorDirective,
    CustomMinValidatorDirective,
    NegativeDecoratorDirective,
    AgGridDatepickerComponent,
    MaterialModule,
    ChartjsPieChartComponent,
    ChartjsStackedBarChartComponent,
    ChartjsGroupBarChartComponent,
    ChartjsLineChartComponent,
    ChartjsBarChartComponent,
    OrderByPipe
  ],
  declarations: [
    ExpandableBoxComponent,
    AsmExpandableBoxComponent,
    CustomSelectComponent,
    BubblechartComponent,
    CustomDivisionsComponent,
    CustomDetailgridComponent,
    NumericSliderComponent,
    GroupBarChartComponent,
    BarChartComponent,
    GdrHorizontalBarChartComponent,
    GdrBarChartComponent,
    LineChartComponent,
    CustomDatagridComponent,
    AlertPopupComponent,
    MessagesComponent,
    CommentBoxComponent,
    PieChartComponent,
    HelpPageComponent,
    FilterPipe,
    LinkifyPipe,
    AutofocusDirective,
    AsmBarChartComponent,
    OmBarChartComponent,
    AsmLineChartComponent,
    HelpPageSupportComponent,
    EnhancementLinechartComponent,
    AdherenceGroupBarComponent,
    AsmPieChartComponent,
    FieldMappingComponent,
    RadarChartComponent,
    PolarChartComponent,
    BarLineChartComponent,
    MutiRadarChartComponent,
    HistoricalBarchartComponent,
    MultilinechartComponent,
    MultiBarComponent,
    MultiBarHorizontalChartComponent,
    DefectpiechartComponent,
    DexCoverageDonutChartComponent,
    StepLineChartComponent,
    MultiLineBarChartComponent,
    //  RadialbarchartComponent,
    InfopopupComponent,
    LineplusbarchartComponent,
    DexdceligibleboxComponent,
    StackedareachartComponent,
    CustomAssetsComponent,
    SnackBarComponent,
    InputTrimDirective,
    CircleSpinnerComponent,
    LimitDigitsDirective,
    LimitDigitDecimalDirective,
    LimitTwoDigitDecimalDirective,
    RoundOffDecimalDirective,
    TimeStampDirective,
    CurrencyCodeDirective,
    NumberToCurrencyFormatDirective, 
    CustomMinValidatorDirective,
    CustomMaxValidatorDirective,
    NegativeDecoratorDirective,
    AgGridDatepickerComponent,
    ChartjsPieChartComponent,
    ChartjsStackedBarChartComponent,
    ChartjsGroupBarChartComponent,
    ChartjsLineChartComponent,
    ChartjsBarChartComponent,
    OrderByPipe
  ],
  providers: [ResetService, HelpPageService, PdfPptHelper, ExportToExcel, SettingsService, CommonMethod],
})
export class SharedModule { }
