import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserMetaData } from '../shared/models/userMetaData.model';
import { UserAccessService } from '../shared/services/user-access.service';

@Injectable({
  providedIn: 'root',
})
export class GovernanceProcessGuard implements CanActivate {
  constructor(private userAccessService: UserAccessService, private router: Router) {}
  readonly accessMap = {
    '/governanceProcess': 'applicablity',
    '/governanceProcess/auditform': 'audit',
    '/governanceProcess/NcManagement': 'ncManagement',
    '/governanceProcess/dexAuditReport': 'dexAuditReport',
  };
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      this.userAccessService.getUserMetaData().subscribe(
        (userMetaData: UserMetaData) => {
          const key = state.url.split('?')[0].split('/').splice(0, 3).join('/');
          resolve(userMetaData[this.accessMap[key]] || false);
          userMetaData[this.accessMap[key]] ? null : this.router.navigateByUrl('/home');
        },
        (err) => {}
      );
    });
  }
}
