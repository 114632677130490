import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_CONFIG } from '../../../api.config';
import { UserAccessService } from '../../../shared/services/user-access.service';
import { AcceptedStoryCount, AgileMaturity, DeploymentStoryCount, HealthMetricsModel } from '../_models/health-metrics.model';
import { Improvements } from '../_models/improvements.model';

@Injectable()
export class DashboardService {
  constructor(public http: HttpClient, private userAccessService: UserAccessService) { }

  share(data) {
    const url = API_CONFIG.shareBvA;
    return this.http.post(url, data);
  }
  getFavoriteItem(emailId) {
    const url = API_CONFIG.usersMetadataInUsersPage.replace('UNIQUE_NAME', `${emailId}`);
    return this.http.get(url);
  }
  getUserMetaData() {
    return this.userAccessService.getUserMetaData();
  }
  getProjectById(id) {
    const url = API_CONFIG.getProjectssByID.replace('programId', `${id}`);
    return this.http.get(url);
  }
  getProjectList(programName: string, accountName: string) {
    const url = API_CONFIG.projects;
    return this.http.get(url, { params: { name: programName, account: accountName } }).pipe(
      map((projects: any[]) => {
        return projects.map((p) => {
          return { id: p.id, name: p.name };
        });
      })
    );
  }

  getSprintList(projectId: number) {
    const url = API_CONFIG.sprints.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getAdditionalData(projectId: number, sprintId: number) {
    const url = API_CONFIG.additionalInfo.replace('PROJECT_ID', `${projectId}`).replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }
  getUberDetails(projectId: number, sprintId: number) {
    // construct url
    const url = API_CONFIG.uberDetails.replace('PROJECT_ID', `${projectId}`).replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url).pipe(
      map((res: HealthMetricsModel) => {
        return new HealthMetricsModel(
          res.totalNoOfSprints,
          // new AcceptedVarience(res.acceptedVarience.target, res.acceptedVarience.fulfilled),
          new AcceptedStoryCount(res.acceptedStoryCount.target, res.acceptedStoryCount.fulfilled),
          //  new DeploymentVarience(res.deploymentVarience.target, res.deploymentVarience.fulfilled),
          new DeploymentStoryCount(res.deploymentStoryCount.target, res.deploymentStoryCount.fulfilled),
          //  res.defects,
          res.prodDefects,
          res.defectsPerFeature,
          res.productivity,
          new AgileMaturity(res.agileMaturity.target, res.agileMaturity.fulfilled),
          res.agileMaturityAssessment,
          res.helpContent,
          res.ncMetrics,
          res.helpData,
          res.dexScore,
          res.avgVelocityPersonPerSprint,
          res.avgVelocityTeamPerDay,
          res.lastAuditDate,
          res.increseInproductivity
        );
      })
    ) as Observable<HealthMetricsModel>;
  }

  getBenifits(projectId: number, sprintId: number) {
    const url = API_CONFIG.benefits.replace('PROJECT_ID', `${projectId}`).replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }

  getImprovements(projectId: number) {
    // construct url
    const url = API_CONFIG.getImprovements.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url) as Observable<Improvements[]>;
  }

  getMetricTrends(projectId: number, sprintId: number) {
    const url = API_CONFIG.metricTrends.replace('PROJECT_ID', `${projectId}`).replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }

  getAssesmentQuestions(sprintId: number) {
    const url = API_CONFIG.assessments.replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }
  getAgileAssesment(id: number) {
    const url = API_CONFIG.agileAssessments.replace('PROJECT_ID', `${id}`);
    return this.http.get(url);
  }
  getIterationData(sprintId: number) {
    // construct url
    const url = API_CONFIG.getiterationData.replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }
  getIterationProjectData(projectId: number, sprintId: number) {
    // construct url
    const url = API_CONFIG.data.replace('PROJECT_ID', `${projectId}`).replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }

  getMetricsToReport(projectId: number) {
    // construct url
    const url = API_CONFIG.metricsToReport.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getProjectData(projectId: string) {
    const url = API_CONFIG.projectData.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getMetricData(projectId: number) {
    const url = API_CONFIG.asmMetricTrends.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  addComment(projectId: number, sprintId: number, gridName: string, comment: string) {
    // construct url
    const url = API_CONFIG.addCommentDetails.replace('SPRINT_ID', `${sprintId}`);
    const params = {
      widgetName: gridName,
      comment,
    };
    return this.http.put(url, params);
  }
  getProgram() {
    const url = API_CONFIG.getProgram;
    return this.http.get(url);
  }

  getComments(sprintId: number) {
    const url = API_CONFIG.getMetricComments.replace('SPRINT_ID', `${sprintId}`);
    return this.http.get(url);
  }
  getAllComments(projectId: number) {
    const url = API_CONFIG.getAllMetricComments.replace('PROJECT_ID', `${projectId}`);
    return this.http.get(url);
  }
  getAccountMetrics(accountId) {
    const url = API_CONFIG.accountMetrics.replace('ACCOUNT_ID', `${accountId}`);
    return this.http.get(url);
  }
  getHelpData(item) {
    const url = API_CONFIG.accountHelpData.replace('DASHBOARD', `${item}`);
    return this.http.get(url);
  }
  getProgramMetrics(accountId, programId) {
    let url = API_CONFIG.programMetrics.replace('ACCOUNT_ID', `${accountId}`);
    url = url.replace('PROGRAM_ID', `${programId}`);
    return this.http.get(url);
  }
  getAccountComments(accountId) {
    const url = API_CONFIG.getAccountComments.replace('ACCOUNT_ID', `${accountId}`);
    return this.http.get(url);
  }

  getProgramComments(programId) {
    const url = API_CONFIG.getProgramComments.replace('PROGRAM_ID', `${programId}`);
    return this.http.get(url);
  }

  addAccountComment(accountId: number, gridName: string, comment: string) {
    // construct url
    const url = API_CONFIG.addAccountComments.replace('ACCOUNT_ID', `${accountId}`);
    const params = {
      widgetName: gridName,
      comment,
    };
    return this.http.put(url, params);
  }
  addProgramComment(programId: number, gridName: string, comment: string) {
    // construct url
    const url = API_CONFIG.addProgramComment.replace('PROGRAM_ID', `${programId}`);
    const params = {
      widgetName: gridName,
      comment,
    };
    return this.http.put(url, params);
  }
  getAllDexAccountsmetrics() {
    const url = API_CONFIG.getAllDexAccountsmetrics;
    return this.http.get(url);
  }
  getProjectDetailsByName(name) {
    const url = API_CONFIG.getProjectdetailsByName.replace('PROJECT_NAME', `${name}`);
    return this.http.get(url);
  }
  setFavAccount(emailId, type, id) {
    const url = API_CONFIG.setFavAccount.replace('EMAIL', `${emailId}`).replace('TYPE', `${type}`).replace('ACCOUNT_ID', `${id}`);
    return this.http.post(url, null);
  }
  setFavProgram(emailId, type, id, programId) {
    const url = API_CONFIG.setFavProgram
      .replace('EMAIL', `${emailId}`)
      .replace('TYPE', `${type}`)
      .replace('ACCOUNT_ID', `${id}`)
      .replace('PROGRAM_ID', `${programId}`);
    return this.http.post(url, null);
  }
  setFavProject(emailId, type, id, programId, projectId) {
    const url = API_CONFIG.setFavProject
      .replace('EMAIL', `${emailId}`)
      .replace('TYPE', `${type}`)
      .replace('ACCOUNT_ID', `${id}`)
      .replace('PROGRAM_ID', `${programId}`)
      .replace('PROJECT_ID', `${projectId}`);
    return this.http.post(url, null);
  }
}
