import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[type=text]',
})
export class InputTrimDirective {
  @Input() inputTrim: boolean = true;
  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    this.writeValue(value.trim());
  }

  @HostListener('keyup', ['$event.target.value'])
  onKeyUp(value: string): void {
    if (value.trim() === '' || !value) {
      this?.ngControl?.control.patchValue('');
      this?.ngControl?.control.updateValueAndValidity({ emitEvent: true });
    }
  }

  constructor(private ngControl?: NgControl) {}

  writeValue(value: any): void {
    this?.ngControl?.control.patchValue(value);
    this?.ngControl?.control.updateValueAndValidity();
  }
}
