import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Injectable } from '@angular/core';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Injectable()
export class ExportToExcel {
  exportDataToExcel(dataToExport, fielName) {
    const data = dataToExport;
    const keyNames = Object.keys(dataToExport[0]);
    const headerLabelsList = [];
    for (let i = 0; i < keyNames.length; i++) {
      headerLabelsList.push(this.sentenceCase(keyNames[i]));
    }
    if (data && data.length) {
      this.downloadFile(data, fielName, headerLabelsList);
    }
  }
  sentenceCase(str) {
    if (str === null || str === '') {
      return false;
    } else {
      str = str.toString();
    }

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }


  exportTableAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveTableAsExcelFile(excelBuffer, excelFileName);
  }

  saveTableAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  downloadFile(data: any, fielName, headerLabelsList) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const headerLabels = headerLabelsList;
    let csv;
    if (data.length) {
      const header = Object.keys(data[0]);
      csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    }
    if (headerLabels.length) {
      csv.unshift(headerLabels.join(','));
    }
    let csvArray = csv.join('\r\n');

    // **Ensure UTF-8 encoding by prepending BOM (Byte Order Mark)**
    const utf8BOM = '\uFEFF'; // This ensures Excel interprets it as UTF-8
    csvArray = utf8BOM + csvArray;

    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8;' });

    // const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, fielName + '.csv');
  }
  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel, headers) {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    let CSV = 'sep=,' + '\r\n\n';

    // This condition will generate the Label/Header
    if (ShowLabel) {
      let row = '';

      // This loop will extract the label from 1st index of on array
      for (const index in headers) {
        // Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);

      // append Label row with line break
      CSV += row + '\r\n';
    }

    // 1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
      let row = '';

      // 2nd loop will extract each column and convert it in string comma-seprated
      for (const index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      // add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert('Invalid data');
      return;
    }

    // Generate a file name
    // this will remove the blank-spaces from the title and replace it with an underscore
    let fileName = ReportTitle.replace(/ /g, '_');

    // Initialize file format you want csv or xls
    const uri = 'data:text/xls;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    // this trick will generate a temp <a /> tag
    const link = document.createElement('a');
    link.href = uri;

    // set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + '.xls';

    // this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  public exportAsExcelFile(json: any[], excelFileName: string, headerColumns): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    let asciStart = 65;
    // upto Z column
    headerColumns.forEach((header, i) => {
      worksheet[String.fromCharCode(asciStart) + 1].v = header;
      asciStart++;
    });

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
