import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AdalGuard, AdalInterceptor, AdalService } from 'adal-angular4';
import { AgGridModule } from 'ag-grid-angular';
import { AppInitializerModule } from './app-initializer.module';
import { AppComponent } from './app.component';
import { RouteModule } from './app.routing';
import { HttpsRequestInterceptor } from './http-interceptor/http.interceptor.module';
import { AssetsService } from './modules/dashboard/asset-report/_services/assetsService';
import { BvaDataService } from './modules/dashboard/bvaDashboard/_services/bva-data.service';
import { DashboardService } from './modules/dashboard/_services/dashboard.service';
import { DpoDashboardServce } from './modules/dashboard/_services/dpoDashboard.service';
import { ExportPdfserviceService } from './modules/dashboard/_services/export-pdfservice.service';
import { CustomReuseStrategy } from './reuse-strategy';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { CommonService } from './shared/services/common.service';
import { UtilService } from './shared/services/util.service';
import { SharedModule } from './shared/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GdrDataServiceService } from './modules/dashboard/gd-report/_services/gdr-data-service.service';

@NgModule({
  exports: [],
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    FormsModule,
    RouteModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AgGridModule,
    SharedModule,NgxChartsModule,
    AppInitializerModule.forRoot(),
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    Title,
    AdalService,
    AdalGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    UtilService,
    AssetsService,
    CommonService,
    ExportPdfserviceService,
    DashboardService,
    DpoDashboardServce,
    BvaDataService,
    GdrDataServiceService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
